<template>
  <section class="roboverse-first-section">
    <video
      class="audio-container"
      autoplay
      muted
      playsinline
      x-webkit-playsinline
      x5-playsinline
      x5-video-player-type="h5"
      x5-video-player-fullscreen
      loop
      src="http://ad4-static.lotuscars.com.cn/webapp/pc/video/verse/roboverse.mp4"
    ></video>

    <div class="icon">
      <SlideIcon />
    </div>
  </section>
</template>

<script>
import SlideIcon from "../../../components/SlideIcon.vue";
export default {
  name: "RoboverseFirstSection",
  components: {
    SlideIcon,
  },
};
</script>

<style lang="less" scoped>
.roboverse-first-section {
  position: relative;
  height: calc(100vw / 1280 * 720);

  .audio-container {
    width: 100%;
    height: 100%;
  }
}
</style>
