<template>
  <section class="introduce-section">
    <h2
      class="decorator"
      data-aos="fade-up"
      data-aos-offset="120"
      data-aos-delay="100"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      INTRODUCE
    </h2>
    <h1
      class="headline"
      data-aos="fade-up"
      data-aos-offset="120"
      data-aos-delay="200"
      data-aos-duration="1200"
      data-aos-once="true"
    >
      {{ $t("galaxy.section2.title") }}
    </h1>
    <div
      class="summary"
      data-aos="fade-up"
      data-aos-offset="120"
      data-aos-delay="200"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <div v-if="locale === 'cn'">
        <p>SaaS工具链方案</p>
        <p class="summary-title">
          提供智驾数据采集、合规、标注、训练、仿真、管理及监控等全流程服务
        </p>
      </div>
      <p class="summary-en" v-else>
        Intelligent driving data collection, compliance, annotation, training,
        simulation, management and monitoring and other services throughout the
        process are available
      </p>
    </div>
    <div
      class="content"
      data-aos="fade-up"
      data-aos-offset="0"
      data-aos-delay="100"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <p>{{ $t("galaxy.section2.content1") }}</p>
      <p class="mrg-top">{{ $t("galaxy.section2.content2") }}</p>
      <p class="mrg-top">{{ $t("galaxy.section2.content3") }}</p>
      <p class="mrg-top">{{ $t("galaxy.section2.content4") }}</p>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "OverviewSection",

  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/common.less";
@import "~@/assets/css/font.css";
.introduce-section {
  @mrg-left: 2.68rem;
  background-color: #000;
  height: 7.2rem;
  p {
    margin: 0;
    padding: 0;
  }

  .decorator {
    margin: 0 0 -0.15rem 1.51rem;
    padding: 1.24rem 0 0 0;
    font-family: "HarmonyOS Sans SC Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 0.5rem;
    line-height: 0.48rem;
    letter-spacing: 0.01em;
    background: linear-gradient(
      98.62deg,
      #364242 3.92%,
      #2b2f37 48.34%,
      #21262b 96.58%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  .headline {
    margin: 0 0 0 @mrg-left;
    padding: 0;
    font-family: "HarmonyOS Sans SC Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 0.28rem;
    line-height: 0.19rem;
    letter-spacing: 0.01em;
    color: @primary-color;
  }

  .summary {
    margin: 0.39rem 0 0.31rem @mrg-left;
    font-family: "HarmonyOS Sans SC";
    font-style: normal;
    font-weight: 400;
    font-size: 0.18rem;
    line-height: 0.28rem;
    letter-spacing: 0.01em;
    color: #ffffff;
  }

  .summary-en {
    font-family: "HarmonyOS Sans SC";
    font-style: normal;
    font-weight: 400;
    font-size: 0.18rem;
    line-height: 0.28rem;
    letter-spacing: 0.01em;
    color: #ffffff;
    width: 6.5rem;
  }

  .content {
    margin-left: @mrg-left;
    font-family: "HarmonyOS Sans SC";
    font-style: normal;
    font-weight: 400;
    font-size: 0.14rem;
    line-height: 0.22rem;
    letter-spacing: 0.03em;
    color: #646368;
    width: 6.5rem;

    .mrg-top {
      margin-top: 0.2rem;
    }
  }
}

@media only screen and (max-width: 750px) {
  .introduce-section {
    @mrg-left: 0.8rem;
    background-color: #000;
    height: 12rem;
    p {
      margin: 0;
      padding: 0;
    }

    .decorator {
      margin: 0 0 -0.15rem 0.67rem;
      padding: 1.24rem 0 0 0;
      font-family: "HarmonyOS Sans SC Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 0.5rem;
      line-height: 0.48rem;
      letter-spacing: 0.01em;
      background: linear-gradient(
        98.62deg,
        #364242 3.92%,
        #2b2f37 48.34%,
        #21262b 96.58%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }

    .headline {
      margin: 0 0 0 @mrg-left;
      line-height: 0.28rem;
      width: 3rem;
    }

    .summary {
      margin: 0.39rem 0 0.52rem @mrg-left;
      font-family: "HarmonyOS Sans SC";
      font-style: normal;
      font-weight: 400;
      font-size: 0.24rem;
      line-height: 0.28rem;
      letter-spacing: 0.01em;
      color: #ffffff;
    }

    .summary-title {
      margin-top: 0.22rem;
      line-height: 0.35rem;
    }
    .summary-en {
      width: 5.98rem;
    }

    .content {
      width: 5.97rem;
      margin-left: @mrg-left;
      font-size: 0.2rem;
      line-height: 0.26rem;
      color: #646368;

      .mrg-top {
        margin-top: 0.3rem;
      }
    }
  }
}
</style>
