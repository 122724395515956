<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { getRemByWidth } from "@/utils/rem";
import { mapMutations } from "vuex";
export default {
  name: "App",

  mounted() {
    if (this.isMobile()) {
      getRemByWidth(750, 100);
      this.setIsMobile(true);
    } else {
      getRemByWidth(1280, 100);
      this.setIsMobile(false);
    }
    const _this = this;
    window.onresize = function () {
      const realW = document.documentElement.clientWidth;
      if (realW <= 750) {
        _this.setIsMobile(true);
        getRemByWidth(750, 100);
      } else {
        _this.setIsMobile(false);
        getRemByWidth(1280, 100);
      }
    };
  },

  methods: {
    ...mapMutations("app", {
      setIsMobile: "SET_ISMOBILE",
    }),
    isMobile() {
      var userAgentInfo = navigator.userAgent;
      var Agents = new Array(
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPod"
      );
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return !flag;
    },
    getRemW(width) {
      getRemByWidth(width, 100);
    },
  },
};
</script>

<style lang="less">
@import "./assets/css/normalize.css";
</style>
