<template>
  <div class="layout-page">
    <Header />
    <router-view />
    <Footer />
    <copy-right />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import CopyRight from "@/components/CopyRight.vue";

export default {
  name: "Layout",
  components: {
    Header,
    Footer,
    CopyRight,
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/header.less";
.layout-page {
  background-color: #000;
  padding-top: @header-height;
}

@media only screen and (max-width: 750px) {
  .layout-page {
    padding-top: @mobile-header-height;
  }
}
</style>
