<template>
  <div class="news-index">
    <div
      class="img"
      @click="open('https://mp.weixin.qq.com/s/kKAdKjisplMvUd4kM6adfg')"
    >
      <img
        src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/news/news_index_left.png"
        alt=""
      />
      <div class="img-content">
        {{ $t("news.newsIndexLeftHead1") }}<br />{{
          $t("news.newsIndexLeftHead2")
        }}
      </div>
    </div>
    <div class="trending">
      <div class="head-line">
        <p class="linear-text">trending</p>
        <i></i>
      </div>
      <div
        class="trend-content"
        @click="
          open(
            'https://mp.weixin.qq.com/s?__biz=MzkyNDQyNTE3MQ==&mid=2247509939&idx=1&sn=2d0b86adb9e05a8054b2936a78e6bb1e&source=41#wechat_redirect'
          )
        "
      >
        <img
          class="poster"
          src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/news/news_index_content1.png"
          alt=""
        />
        <div class="trend-content-head linear-text">LOTUS & BIT</div>
        <div :class="['trend-content-text', { en: locale === 'en' }]">
          <span
            >{{ $t("news.newsIndexBIT1") }}<br />{{
              $t("news.newsIndexBIT2")
            }}</span
          >
        </div>
        <img
          class="arrow"
          src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/news/arrow_tr.png"
        />
      </div>
      <div
        class="trend-content"
        @click="
          open(
            'https://mp.weixin.qq.com/s?__biz=MzA5OTYwMjgyMQ==&mid=2649758216&idx=2&sn=282792d44be2e23d289fe07351a7e2ac&chksm=88fb62e2bf8cebf48db36b111fff84f46ab39e4b5e79942dde0da1dcdf22bf4a2ef5afdfcb69&scene=27'
          )
        "
      >
        <img
          class="poster"
          src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/news/news_index_content2.png"
          alt=""
        />
        <div class="trend-content-head linear-text">
          LOTUS & Tongji<br />University
        </div>
        <div :class="['trend-content-text', { en: locale === 'en' }]">
          <span
            >{{ $t("news.newsIndexTJ1") }}<br />{{
              $t("news.newsIndexTJ2")
            }}</span
          >
        </div>
        <img
          class="arrow"
          src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/news/arrow_tr.png"
        />
      </div>
      <div
        class="trend-content"
        @click="open('https://mp.weixin.qq.com/s/cw-KYhLkQKpM8E9DgTN4IQ')"
      >
        <img
          class="poster"
          src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/news/news_index_content3.png"
          alt=""
        />
        <div class="trend-content-head linear-text">LOTUS & BIT</div>
        <div :class="['trend-content-text', { en: locale === 'en' }]">
          <span
            >{{ $t("news.newsIndexBITTJ1") }}<br />{{
              $t("news.newsIndexBITTJ2")
            }}</span
          >
        </div>
        <img
          class="arrow"
          src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/news/arrow_tr.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "NewsIndex",
  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },
  methods: {
    open(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/image.less";
.news-index {
  background-image: url("@{newsIndexBg}");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: calc(100vh - 0.5rem);
  min-height: 7.12rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 13% 0 13%;
  font-family: "HarmonyOS Sans SC";
  font-weight: 400;
  color: #fff;
  .linear-text {
    background: linear-gradient(269.44deg, #00ff00 -4%, #00ffc2 102.4%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  .img {
    width: 6.63rem;
    border-radius: 0.15rem;
    font-size: 0;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    img {
      width: 100%;
    }
    .img-content {
      width: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      font-size: 0.18rem;
      line-height: 0.22rem;
      font-weight: 700;
      letter-spacing: 0.01rem;
      position: absolute;
      bottom: 0;
      padding: 0.23rem 0.14rem;
    }
  }

  .trending {
    .head-line {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0.4rem;
      justify-content: space-between;
      p {
        font-family: "HarmonyOS Sans SC Black";
        font-weight: 700;
        font-size: 0.15rem;
        background-color: #fff;
        text-transform: uppercase;
        margin-left: 0.1rem;
        margin-right: 0.3rem;
      }
      i {
        display: block;
        width: 1.91rem;
        height: 0.03rem;
        background: linear-gradient(269.44deg, #00ff00 -4%, #00ffc2 102.4%);
      }
    }
    .trend-content {
      width: 3.7rem;
      height: 1.15rem;
      border-radius: 0.08rem;
      background-color: rgb(57, 60, 62);
      overflow: hidden;
      border-radius: 0.08rem;
      margin-bottom: 0.4rem;
      position: relative;
      cursor: pointer;
      .poster {
        width: 1.2rem;
      }
      &:hover .arrow {
        scale: 1.3;
      }
      .trend-content-head {
        position: absolute;
        font-size: 0.15rem;
        top: 0.17rem;
        left: 1.34rem;
        font-family: "HarmonyOS Sans SC Black";
        font-weight: 700;
        line-height: 0.22rem;
      }
      .trend-content-text {
        position: absolute;
        top: 0.72rem;
        left: 1.34rem;
        line-height: 0.15rem;
        font-size: 0.12rem;
        span {
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }

        &.en {
          font-size: 0.09rem;
          top: 0.66rem;
          padding-right: 0.05rem;
        }
      }
      .arrow {
        position: absolute;
        top: 0.1rem;
        right: 0.08rem;
        transition: all 0.1s;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .news-index {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1.9rem 0.74rem 0.94rem;
    justify-content: flex-start;
    box-sizing: border-box;
    background-image: url("@{mbNewsIndexBg}");
    .img {
      width: 100%;
      margin-bottom: 0.84rem;
      .img-content {
        font-size: 0.2rem;
      }
    }
    .trending {
      width: 100%;

      .head-line {
        position: absolute;
        top: 1.1rem;
        .linear-text {
          font-size: 0.22rem;
        }
      }

      .trend-content {
        width: 100%;
        margin-bottom: 0.75rem;
        .poster {
          width: 20%;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
        .trend-content-text {
          top: 0.62rem;
          font-size: 0.2rem;
          line-height: 1;
        }
        .left-img {
          height: 100%;
          margin-right: 0.5rem;
        }
        br {
          display: none;
        }
      }
    }
  }
}
</style>
