var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"structure-section"},[_c('div',{staticClass:"data-collection",on:{"mouseenter":function($event){return _vm.mouseEnter('collection')}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowCollection),expression:"isShowCollection"}],staticClass:"wrapper right",class:_vm.isShowCollection ? 'zoomin' : ''},[(_vm.locale === 'cn')?_c('structure-card',{attrs:{"title-cn":"采集","title-en":"Collection","content":['车队管理', '人员管理', '路线管理'],"order":"01"}}):_vm._e(),(_vm.locale === 'en')?_c('structure-card-en',{attrs:{"title":"Collection","content":[
          'Fleet Management',
          'Crew Management',
          'Routes Management',
        ],"order":"01"}}):_vm._e()],1)]),_c('div',{staticClass:"data-compliance",on:{"mouseenter":function($event){return _vm.mouseEnter('compliance')}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowCompliance),expression:"isShowCompliance"}],staticClass:"wrapper right",class:_vm.isShowCompliance ? 'zoomin' : ''},[(_vm.locale === 'cn')?_c('structure-card',{attrs:{"title-cn":"合规","title-en":"Compliance","content":['采集管理', '数据解析', '数据挖掘'],"order":"02"}}):_vm._e(),(_vm.locale === 'en')?_c('structure-card-en',{attrs:{"title":"Compliance","content":['Collection Management', 'Data Analysis', 'Data Mining'],"order":"02"}}):_vm._e()],1)]),_c('div',{staticClass:"data-annotation",on:{"mouseenter":function($event){return _vm.mouseEnter('annotation')}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowAnnotation),expression:"isShowAnnotation"}],staticClass:"wrapper right",class:_vm.isShowAnnotation ? 'zoomin' : ''},[(_vm.locale === 'cn')?_c('structure-card',{attrs:{"title-cn":"标注","title-en":"Annotation","content":['2D3D联合标注', '数据可视化', '灵活模板', '标注工具'],"order":"03"}}):_vm._e(),(_vm.locale === 'en')?_c('structure-card-en',{attrs:{"title":"Annotation","content":[
          '2D 3D Joint Labelling',
          'Data Visualization',
          'Flexible Template',
          'Labelling Tools',
        ],"order":"03"}}):_vm._e()],1)]),_c('div',{staticClass:"data-training",on:{"mouseenter":function($event){return _vm.mouseEnter('training')}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowTraining),expression:"isShowTraining"}],staticClass:"wrapper right",class:_vm.isShowTraining ? 'zoomin' : ''},[(_vm.locale === 'cn')?_c('structure-card',{attrs:{"title-cn":"训练","title-en":"Training","content":['模型部署', '模型管理', '弹性计算', '闭环训练'],"order":"04"}}):_vm._e(),(_vm.locale === 'en')?_c('structure-card-en',{attrs:{"title":"Training","content":[
          'Model Deployment',
          'Model Management',
          'Elastic Computing',
          'Closed-Loop Training',
        ],"order":"04"}}):_vm._e()],1)]),_c('div',{staticClass:"data-simulation",on:{"mouseenter":function($event){return _vm.mouseEnter('simulation')}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowSimulation),expression:"isShowSimulation"}],staticClass:"wrapper left",class:_vm.isShowSimulation ? 'zoomin' : ''},[(_vm.locale === 'cn')?_c('structure-card',{attrs:{"title-cn":"仿真","title-en":"Simulation","content":['多模块仿真', '评价体系', '并发调整', '任务管理'],"order":"05"}}):_vm._e(),(_vm.locale === 'en')?_c('structure-card-en',{attrs:{"title":"Simulation","content":[
          'Multi-module Simulation',
          'Evaluation System',
          'Concurrent Scheduling',
          'Task Management',
        ],"order":"05"}}):_vm._e()],1)]),_c('div',{staticClass:"data-monitor",on:{"mouseenter":function($event){return _vm.mouseEnter('monitor')}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowMonitor),expression:"isShowMonitor"}],staticClass:"wrapper left",class:_vm.isShowMonitor ? 'zoomin' : ''},[(_vm.locale === 'cn')?_c('structure-card',{attrs:{"title-cn":"监控","title-en":"Monitor","content":['数据回放', '数据大屏', '实时监控'],"order":"06"}}):_vm._e(),(_vm.locale === 'en')?_c('structure-card-en',{attrs:{"title":"Monitor","content":['Data Reply', 'Data Visualization', 'Live Monitoring'],"order":"06"}}):_vm._e()],1)]),_c('div',{staticClass:"data-manage",on:{"mouseenter":function($event){return _vm.mouseEnter('manage')}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowManage),expression:"isShowManage"}],staticClass:"wrapper left",class:_vm.isShowManage ? 'zoomin' : ''},[(_vm.locale === 'cn')?_c('structure-card',{attrs:{"title-cn":"管理","title-en":"Manage","content":['集中存储', '灵活调度', '安全保障'],"order":"07"}}):_vm._e(),(_vm.locale === 'en')?_c('structure-card-en',{attrs:{"title":"Manage","content":[
          'Centralized storage',
          'Flexible scheduling',
          'Safety and security',
        ],"order":"07"}}):_vm._e()],1)]),_c('div',{staticClass:"tip",attrs:{"data-aos":"zoom-in","data-aos-offset":"0","data-aos-delay":"100","data-aos-duration":"800","data-aos-once":"true"}},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t("galaxy.section3.centerTitle")))]),_c('div',{staticClass:"triangle"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }