<template>
  <div class="schema-section">
    <section
      class="top-header"
      data-aos="slide-up"
      data-aos-offset="120"
      data-aos-delay="100"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <p
        :class="{ 'top-header-en': locale === 'en' }"
        :style="{ letterSpacing: locale === 'en' ? '0em' : '' }"
      >
        {{ $t("soul.schemaTitle") }}
      </p>
    </section>
    <section class="table-container">
      <div
        class="table-top"
        data-aos="fade-left"
        data-aos-offset="120"
        data-aos-delay="100"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        <section class="table-top-left">
          <p class="table-top-header left">Lotus Intelligent Driving</p>
          <div class="table-card">
            <div class="filter-bg"></div>
            <div class="table-card-inner left">
              <p class="head">
                {{ $t("soul.schemaHead1") }}
              </p>
              <p class="content">{{ $t("soul.schemaSubHead1") }}</p>
            </div>
            <div class="table-card-inner center">
              <p class="head">
                {{ $t("soul.schemaHead2") }}
              </p>
              <p class="content">{{ $t("soul.schemaSubHead2") }}</p>
            </div>
            <div class="table-card-inner right">
              <p class="head">{{ $t("soul.schemaHead3") }}</p>
              <p class="content">{{ $t("soul.schemaSubHead3") }}</p>
            </div>
          </div>
        </section>
        <section class="table-top-right">
          <p class="table-top-header">ADAS/PAS</p>
          <div
            :style="{
              background: locale === 'en' ? 'rgba(245, 245, 245, 0.29)' : '',
              borderRadius: locale === 'en' ? '0.08rem' : '',
              backdropFilter: locale === 'en' ? 'blur(0.05rem)' : '',
              justifyContent: locale === 'en' ? 'center' : '',
            }"
            :class="{ 'table-card': true, en: locale === 'en' }"
          >
            <div
              :class="{
                'table-card-inner': true,
                left: true,
                en: locale === 'en',
              }"
            >
              <div v-show="locale === 'cn'" class="filter-bg"></div>
              <p :class="{ en: locale === 'en', head: true }">
                {{ $t("soul.schemaHead4") }}
              </p>
              <p class="content">{{ $t("soul.schemaSubHead4") }}</p>
            </div>
            <div
              :class="{
                'table-card-inner': true,
                left: true,
                en: locale === 'en',
              }"
            >
              <div v-show="locale === 'cn'" class="filter-bg"></div>
              <p :class="{ en: locale === 'en', head: true }">
                {{ $t("soul.schemaHead5") }}
              </p>
              <p class="content">{{ $t("soul.schemaSubHead5") }}</p>
            </div>
            <div
              :class="{
                'table-card-inner': true,
                left: true,
                en: locale === 'en',
              }"
            >
              <div v-show="locale === 'cn'" class="filter-bg"></div>
              <p :class="{ en: locale === 'en', head: true }">
                {{ $t("soul.schemaHead6") }}
              </p>
              <p class="content">{{ $t("soul.schemaSubHead6") }}</p>
            </div>
          </div>
        </section>
      </div>
      <div
        class="table-bottom"
        data-aos="fade-right"
        data-aos-offset="120"
        data-aos-delay="100"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        <div :class="{ 'table-bottom-left': true, en: locale === 'en' }">
          <p class="font-top">COM</p>
          <p class="font-bottom">DDS on MCU</p>
        </div>
        <div class="table-bottom-right">
          <div class="filter-bg"></div>
          <div class="table-bottom-right-inner section1">
            <div class="table-bottom-right-inner-part">
              <p class="head">SYSTEM</p>
              <p class="content">
                Process Management&nbsp;&nbsp;State Management<br />
                Deterministic Execution
              </p>
            </div>
            <div class="table-bottom-right-inner-part">
              <p class="head">COM</p>
              <p class="content">
                DDS–DCPS IPC /X<br />
                DDS-X Types DDS- RPC Qos
              </p>
            </div>
          </div>
          <div class="table-bottom-right-inner section2">
            <div class="table-bottom-right-inner-part">
              <p class="head">SECURITY</p>
              <p class="content">
                Access Control&nbsp;&nbsp;Cryptography<br />
                Authentication
              </p>
            </div>
            <div class="table-bottom-right-inner-part">
              <p class="head">MONITOR</p>
              <p class="content">
                Process Status Monitor&nbsp;&nbsp;Resource Monitor<br />
                Performance Monitor
              </p>
            </div>
            <div class="table-bottom-right-inner-part">
              <p class="head">OTA/Programming</p>
            </div>
          </div>
          <div class="table-bottom-right-inner section3">
            <div class="table-bottom-right-inner-part">
              <p class="head">DEBUG</p>
              <p class="content">
                LOG(DLT)&nbsp;&nbsp;CALIBRATION<br />
                DATA Visualization<br />
                DATA Recorder<br />
                DATA Playback
              </p>
            </div>
            <div class="table-bottom-right-inner-part">
              <p class="head">TOOLS</p>
              <p class="content">
                Configuration Tool<br />
                Configuration GUI
              </p>
            </div>
          </div>
          <div class="table-bottom-right-inner section4">
            <div class="table-bottom-right-inner-part">
              <p class="head">AUTOMOTIVE</p>
              <p class="content">
                Diagnostic&nbsp;&nbsp;Network Management<br />
                Storage Management
              </p>
            </div>
            <div class="table-bottom-right-inner-part">
              <p class="head">AUX</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      class="bottom-text"
      data-aos="fade-down"
      data-aos-offset="120"
      data-aos-delay="100"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <p>ROBO SOUL</p>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SchemaSection",
  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/common.less";
@import "~@/assets/css/image.less";
.schema-section {
  background-image: url("@{schemaModuleBg}");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: hidden;
  height: calc(100vh - 0.5rem);
  min-height: 7.12rem;
  width: 100vw;

  p {
    margin: 0;
    padding: 0;
    font-family: "HarmonyOS Sans SC";
    font-style: normal;
    z-index: 2;
  }

  .top-header {
    text-align: center;
    font-weight: 400;
    font-size: 0.28rem;
    color: @primary-color;
    margin-top: 0.79rem;
    letter-spacing: 0.5em;
  }

  .table-container {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    .filter-bg {
      background: rgba(245, 245, 245, 0.29);
      backdrop-filter: blur(0.05rem);
      border-radius: 0.08rem;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .table-top {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      .table-top-left {
        flex-basis: 4.36rem;
        .table-card {
          justify-content: space-evenly;
        }
      }
      .table-top-right {
        flex-basis: 4.738rem;
        position: relative;
        overflow: hidden;
        .table-card {
          justify-content: space-between;
          &.en {
            background: rgba(245, 245, 245, 0.29);
            border-radius: 0.08rem;
            backdrop-filter: blur(0.05rem);
            justify-content: center;
          }
        }
      }
      .table-top-header {
        font-family: "HarmonyOS Sans SC Thin";
        width: fit-content;
        margin: auto;
        height: 0.38rem;
        line-height: 0.38rem;
        font-weight: 400;
        font-size: 0.32rem;
        text-transform: uppercase;
        background: linear-gradient(
          269.44deg,
          @primary-color -4%,
          #00ffc2 102.4%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-align: center;
        &.left {
          font-size: 0.28rem;
        }
      }
      .table-card {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 0.92rem;
        margin-top: 0.26rem;
        position: relative;
        p {
          font-weight: 700;
          font-size: 0.1rem;
          line-height: 0.15rem;
          letter-spacing: 0.05em;
          color: rgba(194, 194, 194, 1);
        }
        .head {
          font-weight: 700;
          font-size: 0.16rem;
          letter-spacing: 0.05em;
          line-height: 0.2rem;
          color: @primary-color;
        }
        .table-card-inner {
          height: 100%;
          flex-basis: 1.52rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          white-space: nowrap;

          &.en {
            flex-basis: 0;
            margin-right: 0.2rem;
            &:last-of-type {
              margin-right: 0;
            }
          }
          .content {
            font-size: 0.12rem;
            transform: scale(0.67);
            width: 150%;
            text-align: center;
            transform-origin: top center;
          }
        }
      }
      .table-top-left {
        margin-right: 0.413rem;
      }
    }
    .table-bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 9.511rem;
      height: 1.72rem;
      margin-top: 0.39rem;
      .table-bottom-left {
        flex-basis: 1.48rem;
        height: 100%;
        background: rgba(#58ff58, 0.6);
        backdrop-filter: blur(0.05rem);
        border-radius: 0.08rem;
        box-sizing: border-box;
        // padding-left: 0.2302rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 0.2302rem;
        position: relative;
        p {
          mix-blend-mode: hard-light;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          font-weight: 900;
          letter-spacing: 0.05em;
        }
        .font-top {
          font-size: 0.2rem;
        }
        .font-bottom {
          font-size: 0.14rem;
        }
      }
      .table-bottom-right {
        flex-basis: 7.95rem;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: relative;
        overflow: hidden;
        .table-bottom-right-inner {
          flex: 1;
          height: 100%;
          box-sizing: border-box;
          padding: 0.14rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: space-between;
          p {
            font-size: 0.12rem;
            width: 150%;
            letter-spacing: 0.05em;
            color: #c2c2c2;
          }
          .head {
            font-weight: 700;
            font-size: 0.16rem;
            line-height: 100%;
            letter-spacing: 0.05em;
            color: @primary-color;
            margin-bottom: 0.0517rem;
          }
          .table-bottom-right-inner-part {
            z-index: 2;
          }
          .content {
            font-size: 0.12rem;
            line-height: 1.2;
            transform: scale(0.7);
            transform-origin: top left;
          }
        }
      }
    }
  }

  .bottom-text {
    font-family: "HarmonyOS Sans SC";
    font-style: normal;
    font-weight: 900;
    font-size: 0.42rem;
    letter-spacing: 0.05em;
    text-shadow: 0 0.04rem 0.04rem rgba(0, 0, 0, 0.25),
      0 0.12rem 12rem rgba(0, 0, 0, 0.75);
    padding-top: 0.5rem;
    display: flex;
    justify-content: center;
    p {
      font-family: "HarmonyOS Sans SC Black";
      width: fit-content;
      background: linear-gradient(
        269.44deg,
        @primary-color -4%,
        #00ffc2 102.4%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }
}

@media only screen and (max-width: 750px) {
  .schema-section {
    background-image: url("@{mobileSchemaModuleBg}");
    height: auto;
    .top-header {
      font-size: 0.28rem;
      margin-top: 1.4rem;
      p.top-header-en {
        font-size: 0.2rem;
      }
    }
    .table-container {
      margin-top: 0.95rem;
      .table-top {
        flex-direction: column;
        .table-top-left {
          width: 6.08rem;
          flex-basis: auto;
          margin-bottom: 0.61rem;
          margin-right: 0;
        }
        .table-top-right {
          width: 6.08rem;
          flex-basis: auto;
          margin-bottom: 0.72rem;
          .table-card {
            &.en {
              justify-content: space-between !important;
            }
          }
        }
        .table-card {
          .head {
            margin-bottom: 0.1rem;
            &.en {
              font-size: 0.16rem;
              color: #c2c2c2;
            }
          }
          .table-card-inner {
            flex-basis: 1.95rem;

            &.en {
              flex-basis: 0;
              margin-right: 0.05rem;
              &:last-of-type {
                margin-right: 0;
              }
            }
            .content {
              // font-size: 0.08rem;
              // transform: none;
              width: 1.95rem;
              text-align: center;
            }
          }
        }
      }
      .table-bottom {
        flex-direction: column;
        align-items: flex-start;
        width: 6.08rem;
        margin-top: 0;
        height: auto;
        .table-bottom-left {
          flex-basis: auto;
          margin-bottom: 0.28rem;
          background: transparent;
          backdrop-filter: none;
          &::after {
            content: "";
            display: block;
            width: 0.37rem;
            height: 0.08rem;
            background-color: #58ff58;
            position: absolute;
            top: 0.25rem;
            left: 1.45rem;
          }
        }
        .table-bottom-right {
          flex-basis: 3.1434rem;
          width: 6.08rem;
          flex-direction: row;
          box-sizing: border-box;
          // padding: 0.15rem;
          .table-bottom-right-inner {
            padding: 0.2rem;
            height: 3.14rem;
            word-wrap: break-word;
            p {
              font-size: 0.1rem;
              line-height: 1.15;
            }
            .head {
              margin-bottom: 0.1rem;
              line-height: 1.15;
            }
            .content {
              font-size: 0.12rem;
              transform: scale(0.67);
              width: 150%;
            }
            &.section1 {
              width: 1.4rem;
            }
            &.section2,
            &.section4 {
              width: 1.6rem;
              padding-left: 0;
              padding-right: 0;
            }
            &.section3 {
              width: 1.48rem;
            }
          }
        }
      }
    }
    .bottom-text {
      font-size: 0.42rem;
      padding-top: 1rem;
      margin-bottom: 1.64rem;
    }
  }
}
</style>
