export default {
  section2: {
    card1: {
      title1: "Overview of",
      title2: "Roboverse",
      content:
        "Roboverse is a large-scale path planning dataset. Novel and challenging path planning benchmark tests are developed through simulation. The task we are interested in is to optimize path planning which can improve completion efficiency. Existing planning and control algorithms lack a scientific and systematic evaluation system, therefore Roboverse has been committed to developing a standardized planning algorithm evaluation system, aiming to evaluate the performance of the planning algorithm.",
    },

    card2: {
      title1: "Roboverse",
      title2: "Dataset",
      content:
        "Roboverse dataset includes 2W scenarios in 4 cities (Suzhou, Shanghai, Hangzhou and Wuhan) in China. There is a significant difference in the road traffic patterns of Suzhou, Shanghai, Hangzhou and Wuhan, and the dataset covers three major scenarios: urban, highway, and parking. Roboverse dataset incorporates high-quality data from highly configurable sensors in the industry, and it is sourced from multiple origins including geography and condition.",
    },
  },

  section4: {
    title1: "What can Roboverse offer to participants?",
    list: [
      {
        icon: "http://ad4-static.lotuscars.com.cn/webapp/pc/image/verse/rv_pv_icon1.png",
        title: "Common Interface Format",
        content:
          "Roboverse provides a planner format that supports a common interface for participants.",
      },
      {
        icon: "http://ad4-static.lotuscars.com.cn/webapp/pc/image/verse/rv_pv_icon2.png",
        title: "Simulation Engine",
        content:
          "A lightweight closed-loop simulation engine that transforms from perfect control to vehicle aerodynamics.",
      },
      {
        icon: "http://ad4-static.lotuscars.com.cn/webapp/pc/image/verse/rv_pv_icon3.png",
        title: "Evaluation Systems and Criteria",
        content:
          "Perfect and reasonable evaluation systems are developed for all-round evaluation on regulation algorithms from perspectives of safety, intelligence, comfort and traffic rules. More systematic evaluation criteria are put forward to define the best regulation algorithms using parameters such as pass rate.",
      },
      {
        icon: "http://ad4-static.lotuscars.com.cn/webapp/pc/image/verse/rv_pv_icon4.png",
        title: "Lviz Playback System",
        content:
          "The self-developed Lviz playback system can restore the simulation results, greatly improving the efficiency of online playback and shortening the file upload and download time.",
      },
    ],
  },

  section5: {
    title: "DATA COLLECTION",
    list: [
      {
        icon: "http://ad4-static.lotuscars.com.cn/webapp/pc/image/verse/rv_dc_icon1.png",
        title: "Collection Method",
        content:
          "All data reflects real-world scenarios collected in manual driving to obtain ground reality suitable for imitation learning.",
        no: "NO.1",
        enTitle1: "COLLECTION",
        enTitle2: "METHOD",
      },
      {
        icon: "http://ad4-static.lotuscars.com.cn/webapp/pc/image/verse/rv_dc_icon2.png",
        title: "Sensor Configuration",
        content:
          "4x Lidar(10Hz) <br />1x 4D Radar (50Hz)<br />7x 8M Camera (30Hz)<br />4x 2M Camera (30Hz)",
        no: "NO.2",
        enTitle1: "SENSOR",
        enTitle2: "CONFIGURATION",
      },
      {
        icon: "http://ad4-static.lotuscars.com.cn/webapp/pc/image/verse/rv_dc_icon3.png",
        title: "Map Information",
        content:
          "2D HD maps are manually annotated in details with semantic categories including roads, sidewalks, crosswalks, lanes, and traffic lights. A common map interface is provided.",
        no: "NO.3",
        enTitle1: "MAP",
        enTitle2: "INFORMATION",
      },
    ],
  },

  section6: {
    decoration: "DIVERSITY",
    title: "GEOGRAPHIC DIVERSITY",
    list: [
      {
        enTitle: "HANGZHOU",
        cnTitle: "杭州",
      },
      {
        enTitle: "SHANGHAI",
        cnTitle: "上海",
      },
      {
        enTitle: "WUHAN",
        cnTitle: "武汉",
      },
      {
        enTitle: "SUZHOU",
        cnTitle: "苏州",
      },
    ],
  },

  section8: {
    title: "COVERING MORE SCENARIOS",
    desc: "To cover as many scenarios as possible to demonstrate the real algorithm ability of planners, we annotate the scenarios people are interested in and edge scenarios in dataset using scenario mining algorithm and establish a large-scale simulation scenario library.",
  },

  section9: {
    title: "Data Annotation Platform ",
    title1: "Point cloud fusion",
    content1:
      "Point cloud fusion annotation can help in training of autonomous driving models. It can simultaneously annotate multi-sensor fusion data and be applied to the training of autonomous driving scenarios.",
    title2: "3D point cloud",
    content2:
      "3D point cloud annotation can help in training of autonomous driving models. Based on our experience in autonomous driving annotation, it is possible to frame 3D obstacles and semantically segment radar maps, thereby making vehicles better perceive road surfaces.",
    title3: "Semantic segmentation",
    content3:
      "Based on the shape annotation of the areas, this feature can segment and annotate attributes of complex irregular pictures, and it is mainly used in scenario segmentation, autonomous driving road segmentation, etc.",
    title4: "Image classification",
    content4:
      "This feature can be used to classify millions of images and classify images by attributes, which is helpful for training of image recognition models.",
  },

  section10: {
    title: "PLANNING FRAMEWORK",
  },

  section11: {
    title: "EVENT TOOLS",
    learnMore: "Learn More",
  },

  section12: {
    title: "Evaluation System",
    list: [
      {
        no: "01",
        feature: "Safety",
        desc: "Measure the collision risk of the algorithms and calculate whether there is a risk of collision with other vehicles or agents, or whether the vehicle is driving in areas as indicated in the map, etc.",
      },
      {
        no: "02",
        feature: "Intelligence",
        desc: "Measure the intelligence of the algorithms, for example, whether there are anti-intelligent planning strategies such as stopping for a long time without any reason, not starting at a green light or changing lanes continuously.",
      },
      {
        no: "03",
        feature: "Traffic Rules",
        desc: "Measure the compliance of the algorithms, for example, whether there are violations of regulations such as running a red light, running in solid lines, and going in a direction not allowed by traffic regulations.",
      },
      {
        no: "04",
        feature: "Comfort",
        desc: "Measure the comfort of the algorithms, for example, whether there are planning strategies that cause physical discomfort like sudden braking, emergency stop, and sharp turning.",
      },
    ],
  },
};
