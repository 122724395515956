<template>
  <svg
    class="value-svg"
    :width="w"
    :height="h"
    :view-box="vb"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
  >
    <path
      class="value-path"
      d="M0 149.5C23.5 173.5 29.5 180 60 206.5C87.5 228.167 164.805 299 245 162C280.905 100.662 321.862 51.7825 365 29.5C428.409 -3.25333 492 6.00012 544.5 6.00011"
      stroke="#00FF00"
      :stroke-width="stkW"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
import { getRootFontSize } from "@/utils/rem";
export default {
  name: "ValueSvg",

  props: {
    width: Number,
    height: Number,
    strokeWidth: Number,
  },

  data() {
    const rootFootsize = getRootFontSize(1280, 100);
    return {
      rootFootsize,
    };
  },

  computed: {
    w() {
      return (this.width / 100) * this.rootFootsize;
    },
    h() {
      return (this.height / 100) * this.rootFootsize;
    },
    vb() {
      return `0 0 ${this.w} ${this.h}`;
    },
    stkW() {
      return (this.strokeWidth / 100) * this.rootFootsize;
    },
  },

  mounted() {
    const path = document.querySelector(".value-path");
    const pathLength = path.getTotalLength();
    path.style.strokeDasharray = pathLength;
    path.style.strokeDashoffset = pathLength;
    this.setObserver();
    window.addEventListener("resize", this.calcValueSvgSize);
  },

  methods: {
    calcValueSvgSize() {
      this.rootFootsize = getRootFontSize(1260, 100);
    },
    setObserver() {
      var observer = new IntersectionObserver((entries) => {
        let entry = entries[0];
        if (entry.isIntersecting) {
          const path = document.querySelector(".value-path");
          const pathLength = path.getTotalLength();
          setTimeout(() => {
            path.style.transition = "stroke-dashoffset 1.5s linear";
            path.style.strokeDashoffset = pathLength * 2;
          }, 200);
        }
      });
      observer.observe(document.querySelector(".value-svg"));
    },
  },
};
</script>
