const state = () => ({
  isMobile: false,
  locale: "cn",
  localeList: [
    { label: "中文", lang: "cn" },
    { label: "English", lang: "en" },
  ],
});

const mutations = {
  SET_LOCALE(state, locale) {
    state.locale = locale;
  },
  SET_ISMOBILE(state, isMobile) {
    state.isMobile = isMobile;
  },
};

const getters = {
  localeOptions(state) {
    return state.localeList.map((item) => {
      const { label, lang } = item;
      const selected = item.lang === state.locale ? "selected" : "";
      return {
        label,
        value: lang,
        selected,
      };
    });
  },
};

export default { state, mutations, getters };
