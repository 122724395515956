<template>
  <div class="robo-matrix-cockpit-page">
    <div class="headline">
      <h2
        class="decorator"
        data-aos="fade-up-left"
        data-aos-offset="120"
        data-aos-delay="100"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        COCKPIT
      </h2>
      <h1
        class="title"
        data-aos="fade-up"
        data-aos-offset="120"
        data-aos-delay="200"
        data-aos-duration="1200"
        data-aos-once="true"
      >
        {{ $t("matrix.section3.title") }}
      </h1>
    </div>
    <div
      class="cockpit-wrapper"
      data-aos="fade-left"
      data-aos-offset="120"
      data-aos-delay="100"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <div class="bg1">
        <div class="cockpit-pic"></div>
        <!-- <img class="cockpit_img1" src="~@/assets/img/cockpit_1.png" /> -->
      </div>
      <img
        class="cockpit_img2"
        src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/matrix/cross.png"
      />
      <div class="bg2">
        <img
          class="cockpit_img3"
          src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/matrix/cockpit_2.png"
        />
      </div>
    </div>
    <div
      class="bottom"
      data-aos="fade-right"
      data-aos-offset="120"
      data-aos-delay="100"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <!-- <div class="triangle"></div>
      <div class="blur"></div> -->

      <div class="triangle-blur"></div>
      <p class="text">CODE DIFFERENT</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CockpitSection",
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/common.less";
@import "~@/assets/css/font.css";
@import "~@/assets/css/image.less";
.robo-matrix-cockpit-page {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: calc(100vh - 0.5rem);
  min-height: 7.2rem;
  background-image: url("@{pcCockpitBg}");
  position: relative;
  .headline {
    margin-left: 1.33rem;
    padding-top: 0.9rem;
    .decorator {
      margin: 0 0 -0.26rem 0;
      padding: 0;
      font-family: "HarmonyOS Sans SC Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 0.5rem;
      line-height: 0.48rem;
      letter-spacing: 0.01em;
      background: linear-gradient(
        98.62deg,
        #364242 3.92%,
        #2b2f37 48.34%,
        #21262b 96.58%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    .title {
      margin: 0 0 0 0.73rem;
      padding: 0;
      font-family: "HarmonyOS Sans SC Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 0.28rem;
      line-height: 0.33rem;
      letter-spacing: 0.01em;
      color: @primary-color;
    }
  }

  .cockpit-wrapper {
    margin: 0.15rem 0 0 2.9rem;
    font-size: 0;
    .bg1 {
      display: inline-block;
      width: 5.24rem;
      height: 4.45rem;
      background: linear-gradient(177.16deg, #646464 -28%, #000000 93.85%);

      .cockpit-pic {
        width: 100%;
        height: 100%;
        background-image: url("@{pcCockpitImg1}");
        background-repeat: no-repeat;
        background-size: 5.45rem 4.3rem;
        background-position: 0 0.5rem;
      }
    }
    .cockpit_img1 {
      margin-top: 0.5rem;
      width: 5.45rem;
      height: 4.3rem;
    }

    .cockpit_img2 {
      margin: 0.74rem 0 0 0.15rem;
      width: 0.72rem;
      height: 0.72rem;
      vertical-align: top;
    }

    .bg2 {
      margin-top: 1.32rem;
      display: inline-block;
      width: 3.18rem;
      height: 2.93rem;
      background: linear-gradient(
        180deg,
        rgba(119, 119, 119, 0.07) 2.39%,
        #000000 100%
      );
      vertical-align: top;
      transition: transform 500ms ease;
      &:hover {
        transform: scale(1.1);
      }
    }
    .cockpit_img3 {
      cursor: pointer;
      width: 3.18rem;
      height: 2.93rem;
    }
  }

  .bottom {
    margin-left: 1.35rem;
    margin-top: -0.3rem;
    // .blur {
    //   width: 0.76rem;
    //   height: 0.63rem;
    //   transform: scale(1.2);
    //   background-image: url("~@/assets/img/triangle_blur.png");
    //   background-size: 100% 100%;
    //   background-repeat: no-repeat;
    //   margin: -0.54rem 0 0 0;
    //   position: relative;
    //   right: 0.18rem;
    // }
    // .triangle {
    //   width: 0.76rem;
    //   height: 0.63rem;
    //   background-image: url("~@/assets/img/triangle_yellow.png");
    //   background-size: 100% 100%;
    //   background-repeat: no-repeat;
    // }

    .triangle-blur {
      width: 1.04rem;
      height: 0.71rem;
      background-image: url("@{triangleBlurImg}");
      background-size: 100% 100%;
    }

    .text {
      margin: 0;
      padding: 0;
      font-family: "HarmonyOS Sans SC Thin";
      font-style: normal;
      font-weight: 400;
      font-size: 0.32rem;
      line-height: 0.8rem;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      background: linear-gradient(269.44deg, #00ff00 -4%, #00ffc2 102.4%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
}

@media screen and (max-width: 750px) {
  .robo-matrix-cockpit-page {
    height: 13.34rem;
    width: 100vw;
    overflow: hidden;
    background-image: url("@{mbCockpitBg}");

    .headline {
      padding: 0;
      margin-top: 1.63rem;
      margin-left: 0.31rem;
    }

    .cockpit-wrapper {
      margin: 0;
      position: relative;
      .bg1 {
        margin: 0.74rem calc(50% - 2.74rem) 0;
        width: 5.48rem;
        height: 4.44rem;
        .cockpit-pic {
          background-size: 5.48rem 4.44rem;
        }
      }

      .cockpit_img2 {
        margin: 0;
        position: absolute;
        top: 0;
        right: 0.3531rem;
        width: 0.65rem;
        height: 0.65rem;
      }

      .bg2 {
        margin-top: 0.46rem;
        margin-left: 2.49rem;
        width: 4.13rem;
        height: 3.79rem;
        background: linear-gradient(180deg, #454645 2.39%, #000000 100%);
        img {
          width: 100%;
          height: 100%;
        }
      }
      .cockpit_img3 {
        cursor: pointer;
        width: 3.18rem;
        height: 2.93rem;
      }
    }

    .bottom {
      margin: 0;
      position: absolute;
      left: 0.49rem;
      bottom: 3.3rem;
      // .triangle {
      //   margin-left: 0.34rem;
      // }
      .text {
        width: 2rem;
        margin-top: 0.3rem;
        font-size: 0.32rem;
        line-height: 0.34rem;
      }
    }
  }
}
</style>
