import { render, staticRenderFns } from "./CockpitSection.vue?vue&type=template&id=9b58ecfe&scoped=true&"
import script from "./CockpitSection.vue?vue&type=script&lang=js&"
export * from "./CockpitSection.vue?vue&type=script&lang=js&"
import style0 from "./CockpitSection.vue?vue&type=style&index=0&id=9b58ecfe&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b58ecfe",
  null
  
)

export default component.exports