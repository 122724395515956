export function getRemByWidth(design_w, unit) {
  var html = document.getElementsByTagName("html")[0];
  var real_w = document.documentElement.clientWidth;
  real_w > design_w && (real_w = design_w);
  html.style.fontSize = (real_w / design_w) * unit + "px";
}

export function getRemByHeight(design_h, unit) {
  var html = document.getElementsByTagName("html")[0];
  var real_h = document.documentElement.clientHeight;
  real_h > design_h && (real_h = design_h);
  html.style.fontSize = (real_h / design_h) * unit + "px";
}

export function getRootFontSize(design_w, unit) {
  var real_w = document.documentElement.clientWidth;
  real_w > design_w && (real_w = design_w);
  return (real_w / design_w) * unit;
}
