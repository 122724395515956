export default {
  section1: {
    subtitle: "INTELLIGENT DRIVING SAAS TOOL CHAIN",
  },
  section2: {
    title: "Intelligent Driving Cloud Data Factory",
    content1:
      "Cars have gradually transitioned from mechanization to electrification and intelligence. As a new species of automobile, the R&D, testing, and commercialization of intelligent driving vehicles will generate massive amounts of data. Therefore, an intelligent driving tool chain system that can handle massive amounts of data is particularly important. ",
    content2:
      'How to carry out efficient data management and data operation has become an urgent problem for intelligent driving companies to solve. To further improve the computing power and digital operation capabilities of intelligent driving, it is necessary to "go to the cloud". ',
    content3:
      '"Go to the cloud" can not only make up for the gap in the ability of many intelligent driving companies to store and process data, but also empower all aspects of data collection, data compliance, data management, model training, and data simulation.',
    content4:
      "The emergence of ROBO Galaxy conforms to the development needs of the intelligent driving industry.",
  },

  section3: {
    centerTitle: "Unified data management",
  },

  section4: {
    title: "DATA MANAGEMENT",
    subtitle: "Visible, controllable and more efficient data management",
    title1: "Data Visualization, Multi-type Total Data Playback",
    content1:
      "On Lviz platform, total data playback, free display and switching of multi-type data, independent display of a variety of charts and data streams are available, visualizing the whole process from perception to planning and control.",
    title2: "Terminal Synchronization, Free to Build Information Screen",
    content2:
      " With the provision of customized BI reports and the ability to freely build large visual screens, the real-time fleet information, mileage coverage in the country, etc. can be displayed as needed.",
  },

  section5: {
    title: "DATA COLLECTION",
    subtitle: "High security, high performance and high reliability",
    title1: "High Performance",
    subtitle1:
      "Petabyte-Scale Data Storage Millions of Concurrent Requests Elastic Expansion",
    content1:
      "Large-scale, high-precision, multi-dimensional, and high-quality data storage is supported, and the capacity can be unlimitedly expanded to respond to millions of concurrent requests.",
    title2: "High Security",
    subtitle2:
      "Masking Compliance Secure Isolation of Confidential Server Room Privacy Security",
    content2:
      "Private information can be masked fully or partially, and the public cloud, private cloud and confidential server room can work together to ensure data security.",
    title3: "High Reliability",
    subtitle3:
      "Two Data Centers (DCs) Deployment In One City For Disaster Recovery (DR) Load Balance Automatic Failover",
    content3:
      "Two DCs are interoperable to guarantee secure data backup, that's, in the event of failure or accident in one DC, the other DC can operate normally to ensure data reliability effectively.",
  },

  section6: {
    title: "DATA ANNOTATION",
    subtitle:
      "Intelligent, efficient and automatic annotation to assist manual annotation",
    title1:
      "Accurate Identification and Automatic Annotation of Multi-type Obstacle Available",
    subtitle1:
      "Multi-institution Isolation ｜Efficient Concurrency ｜High Compatibilit",
    content:
      "Automatic annotation of data types such as images and point clouds, as well as manual annotations including combined annotation, semantic segmentation, and polygon annotation, etc. are supported to realize closed-loop data annotation.",
  },

  section7: {
    title: "DATA TRANING",
    subtitle: "Rapid deployment, one-stop model training service",
    title1:
      "In the Argoverse data set, three indicators and two items ranked first",
    content1:
      "By training on this platform, the AI driving prediction algorithm won the internationally renowned algorithm competition Argoverse. The deviation of the predicted trajectory from the true trajectory decrease from 1.96M to 1.55M.",
    title2: "Unified maintenance of multi-type information",
    content2:
      "Dynamic allocation of computing resources is performed according to model training needs to accelerate model derivation, and post-training model version, training algorithm, training data and machine resources, etc. are maintained uniformly.",
  },

  section8: {
    title: "DATA SIMULATION",
    subtitle:
      "Worldsim × Efficient and parallel Worldsim + Logsim, a combination of virtuality and reality",
    title1:
      "WorldSim is a kind of full virtual simulation featuring massive test scenarios and generalization to effectively expand test coverage",
    content1:
      "100+ VTD cloud concurrency in more than 30,000 km traveling distance per day is available;Cloud simulation practice of truly mass-produced E2E algorithms;Regulation algorithm is incorporated into open DRIVE virtual map to make it unbound from the HD map used in vehicle.",
    title2:
      "Logsim full-link data simulation and multi-dimensional result evaluation, supporting regression test of all drive test data",
    content2:
      "Multi-module simulation models are provided, including perception, positioning, prediction, regulation, risk, etc.; thousand-level concurrent tasks can be extended to ten thousand-level; problem identification is precise and linked with the road acquisition test database.",
  },

  section9: {
    title:
      "Lotus Robotics with Alibaba Cloud to build a cloud data factory for intelligent driving",
    content:
      "Focusing on the data closed loop, giving priority to building ROBO Galaxy, committing to building a full data chain intelligent driving cloud service product system, and helping the industry create new productivity!",
  },
};
