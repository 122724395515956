export default {
  newsIndexLeftHead1: "最贵103万！李书福旗下豪华智能天花板开",
  newsIndexLeftHead2: "卖：4激光雷达、34个传感器",
  newsIndexBIT1: "路特斯冠名赞助北京理工大学",
  newsIndexBIT2: "无人驾驶方程式车队",
  newsIndexTJ1: "携手同济路特斯翼驰车队，路特",
  newsIndexTJ2: "斯打造产学研协同创新新标杆",
  newsIndexBITTJ1: "路特斯携手北理与同济，助力赛",
  newsIndexBITTJ2: "车梦腾飞",
  newsVideo1:
    "2022 阿里云合作伙伴大会，产业创新系列重磅发布，路特斯集团CEO冯擎峰演讲",
  newsVideo2:
    "MEET 2023 智能未来大会，路特斯机器人李博：智能车是机器人的第一形态",
  newsVideo3: "云咖来了｜路特斯机器人登陆亚马逊云科技",
};
