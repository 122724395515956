<template>
  <div class="roboverse-ninth-section">
    <div class="bg">
      <div ref="card2" @mouseover="cardRotate" class="rotate-container">
        <div :class="'card front ' + locale">
          <div class="cn-title">
            {{ $t("verse.section9.title") }}
          </div>
          <div class="en-title" v-if="locale === 'cn'">DATA <br />LABELING</div>
          <div :class="'feature ' + locale">
            <div class="title">
              <img
                class="icon"
                src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/verse/dap_icon1.png"
              />
              <span class="txt">{{ $t("verse.section9.title1") }}</span>
            </div>
            <div :class="'content ' + locale">
              {{ $t("verse.section9.content1") }}
            </div>
          </div>

          <div class="feature">
            <div class="title">
              <img
                class="icon"
                src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/verse/dap_icon2.png"
              />
              <span class="txt">{{ $t("verse.section9.title2") }}</span>
            </div>
            <div :class="'content ' + locale">
              {{ $t("verse.section9.content2") }}
            </div>
          </div>
        </div>

        <div :class="'card back ' + locale">
          <div class="feature">
            <div class="title">
              <img
                class="icon"
                src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/verse/dap_icon3.png"
              />
              <span class="txt">{{ $t("verse.section9.title3") }}</span>
            </div>
            <div :class="'content ' + locale">
              {{ $t("verse.section9.content3") }}
            </div>
          </div>

          <div class="feature">
            <div class="title">
              <img
                class="icon"
                src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/verse/dap_icon4.png"
              />
              <span class="txt">{{ $t("verse.section9.title4") }}</span>
            </div>
            <div :class="'content ' + locale">
              {{ $t("verse.section9.content4") }}
            </div>
          </div>

          <div :class="'cn-title back ' + locale">
            {{ $t("verse.section9.title") }}
          </div>
          <div class="en-title" style="margin-bottom: 0" v-if="locale === 'cn'">
            DATA <br />LABELING
          </div>
        </div>
      </div>

      <div class="text" v-if="locale === 'cn'">
        >. <br />
        自研自动标注平台全面覆盖图像视频，激光雷达3D点云、文本数据标注需求
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "RoboVerseNinthSection",

  data() {
    return {
      rotateAngle: 0,
      rotateFlag: true,
    };
  },

  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },

  methods: {
    cardRotate() {
      if (this.rotateFlag) {
        this.rotateFlag = false;
        this.rotateAngle = (this.rotateAngle + 180) % 360;
        this.$refs.card2.style.setProperty(
          "--rotateAngle",
          this.rotateAngle + "deg"
        );
        setTimeout(() => {
          this.rotateFlag = true;
        }, 1000);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/image.less";
@import "~@/assets/css/font.css";
.roboverse-ninth-section {
  position: relative;
  height: 7.1rem;
  padding-top: 1.22rem;
  .bg {
    width: 9.66rem;
    height: 5.5rem;
    background-image: url("@{ninthBg}");
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    margin: 0 auto;
  }

  .rotate-container {
    position: absolute;
    top: 1rem;
    right: 0.5rem;
    width: 3.45rem;
    height: 5.03rem;
    perspective: 500px;
    transform-style: preserve-3d;
    transition: all 1s linear;
    transform: rotateY(var(--rotateAngle));
  }

  .card {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.16);
    backdrop-filter: blur(10px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 0.33rem;
    box-sizing: border-box;
    padding: 0.9rem 0.2rem 0 0.3rem;
    cursor: pointer;
    &.en {
      padding-top: 0.4rem;
    }

    .cn-title {
      font-family: "HarmonyOS Sans SC";
      font-style: normal;
      font-weight: 700;
      font-size: 0.3rem;
      // line-height: 0.19rem;
      letter-spacing: 0.01em;
      background: linear-gradient(269.44deg, #00ff00 -4%, #00ffc2 102.4%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      &.back {
        margin-top: 0.5rem;

        &.en {
          margin-top: 0.8rem;
        }
      }
    }

    .en-title {
      font-family: "HarmonyOS Sans SC";
      font-style: normal;
      font-weight: 700;
      font-size: 0.3rem;
      line-height: 0.32rem;
      letter-spacing: 0.01em;
      color: #ffffff;
      margin: 0.2rem 0 0.45rem 0;
    }

    .feature {
      &.en {
        margin-top: 0.31rem;
      }
      .title {
        font-size: 0;
        margin-bottom: 0.09rem;
        .icon {
          width: 0.22rem;
          height: 0.14rem;
          margin-right: 0.09rem;
        }

        .txt {
          font-family: "HarmonyOS Sans SC";
          font-style: normal;
          font-weight: 700;
          font-size: 0.14rem;
          line-height: 0.22rem;
          letter-spacing: 0.03em;
          color: #f5f5f5;
        }
      }

      .content {
        font-family: "HarmonyOS Sans SC Thin";
        font-style: normal;
        font-weight: 400;
        font-size: 0.12rem;
        line-height: 0.2rem;
        letter-spacing: 0.03em;
        color: #e4e4e4;
        margin-bottom: 0.2rem;
      }
    }

    &.front {
      backface-visibility: hidden;
    }

    &.back {
      backface-visibility: hidden;
      transform: rotateY(180deg);
    }
  }

  .text {
    position: absolute;
    left: 0;
    bottom: -0.6rem;
    font-family: "HarmonyOS Sans SC";
    font-style: normal;
    font-weight: 700;
    font-size: 0.12rem;
    letter-spacing: 0.08em;
    color: #00ff00;
  }
}
</style>
