<template>
  <div :class="'feature-card ' + locale">
    <div class="headline">
      <span class="blur"></span>
      <template v-if="locale === 'cn'">
        <span class="order-num cn">{{ orderNum }}</span>
        <div class="title">
          <h1 class="cn">{{ titleCn }}</h1>
          <h1 class="en">{{ titleEn }}</h1>
        </div>
      </template>
      <div class="title en-locale" v-if="locale === 'en'">
        <span class="order-num en-local">{{ orderNum }}</span>
        <span class="en-only">{{ titleEn }}</span>
      </div>
    </div>
    <p :class="'content ' + locale">{{ content }}</p>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "FeatureCard",
  props: {
    orderNum: String,
    titleCn: String,
    titleEn: String,
    content: String,
  },
  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/common.less";
@import "~@/assets/css/font.css";
@import "~@/assets/css/image.less";
.feature-card {
  width: 2.72rem;
  height: 3.6rem;
  padding: 0.26rem 0 0 0.1rem;
  border-radius: 0.08rem;
  box-sizing: border-box;
  cursor: pointer;
  transition: transform 300ms;
  background-image: url("@{featureCardBg}");
  background-size: contain;
  background-repeat: no-repeat;

  &:hover {
    transform: scale(1.1);
    // box-shadow: 0 0 0.05rem rgba(245, 245, 245, 0.8);
  }
  .headline {
    display: flex;
    align-items: center;
    span.blur {
      display: inline-block;
      width: 0.56rem;
      height: 0.56rem;
      border-radius: 50%;
      background-image: url("@{featureBlurImg}");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    span.order-num {
      font-family: "HarmonyOS Sans SC Bold";
      font-style: normal;
      font-weight: 900;
      font-size: 0.36rem;
      line-height: 0.28rem;
      color: @primary-color;
      &.cn {
        margin: 0 0.08rem 0 -0.3rem;
      }
      &.en-local {
        margin: 0rem 0.05rem 0 0;
      }
    }

    .title {
      display: inline-block;
      &.en-locale {
        font-size: 0;
        margin: 0 0.05rem 0 -0.3rem;
        height: 0.28rem;

        .en-only {
          font-family: "HarmonyOS Sans SC";
          font-style: normal;
          font-weight: 700;
          font-size: 0.2rem;
          line-height: 0.28rem;
          letter-spacing: 0.01em;
          color: @primary-color;
        }
      }
      .cn {
        font-family: "HarmonyOS Sans SC Bold";
        font-style: normal;
        font-weight: 700;
        font-size: 0.2rem;
        line-height: 0.28rem;
        letter-spacing: 0.01em;
        color: @primary-color;
      }

      .en {
        font-family: "HarmonyOS Sans SC Thin";
        font-style: normal;
        font-weight: 400;
        font-size: 0.1rem;
        line-height: 0.12rem;
        letter-spacing: 0.01em;
        color: @primary-color;
      }

      h1 {
        padding: 0;
        margin: 0;
      }
    }
  }

  .content {
    margin: 0.33rem 0 0 0;
    padding: 0 0.35rem;
    font-family: "HarmonyOS Sans SC Light";
    font-style: normal;
    font-weight: 400;
    font-size: 0.14rem;
    line-height: 0.22rem;
    letter-spacing: 0.065em;
    color: #9393a0;

    &.en {
      font-family: "HarmonyOS Sans SC Thin";
      margin: 0.2rem 0 0 0;
      padding: 0 0.2rem;
      line-height: 0.16rem;
    }
  }
}

@media only screen and (max-width: 750px) {
  .feature-card {
    width: 5.33rem;
    height: 2.22rem;
    padding: 0.2rem 0.3rem;
    box-sizing: content-box;
    border-radius: 0.08rem;
    background-image: url("@{mbFeatureCardBg}");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    &.en {
      height: 2.7rem;
    }

    .headline {
      span.order-num {
        font-family: "HarmonyOS Sans SC Bold";
        font-style: normal;
        font-weight: 900;
        font-size: 0.36rem;
        line-height: 0.28rem;
        color: @primary-color;
        margin: 0 0.05rem 0 -0.3rem;
      }

      .title {
        display: inline-block;

        .cn {
          font-family: "HarmonyOS Sans SC Bold";
          font-style: normal;
          font-weight: 700;
          font-size: 0.24rem;
          line-height: 0.28rem;
          letter-spacing: 0.01em;
          color: @primary-color;
          margin-bottom: 2px;
        }

        .en {
          font-family: "HarmonyOS Sans SC Thin";
          font-style: normal;
          font-weight: 400;
          font-size: 0.14rem;
          line-height: 0.16rem;
          letter-spacing: 0.01em;
          color: @primary-color;
        }

        h1 {
          padding: 0;
          margin: 0;
        }
      }
    }

    .content {
      margin: 0.2rem 0 0 0;
      font-weight: 400;
      font-size: 0.18rem;
      line-height: 0.3rem;

      &.en {
        font-family: "HarmonyOS Sans SC Thin";
        font-size: 0.14rem;
        line-height: 0.21rem;
        letter-spacing: -0.01em;
      }
    }
  }
}
</style>
