<template>
  <section class="structure-section">
    <div class="data-collection" @mouseenter="mouseEnter('collection')">
      <div
        class="wrapper right"
        :class="isShowCollection ? 'zoomin' : ''"
        v-show="isShowCollection"
      >
        <structure-card
          v-if="locale === 'cn'"
          title-cn="采集"
          title-en="Collection"
          :content="['车队管理', '人员管理', '路线管理']"
          order="01"
        />
        <structure-card-en
          v-if="locale === 'en'"
          title="Collection"
          :content="[
            'Fleet Management',
            'Crew Management',
            'Routes Management',
          ]"
          order="01"
        />
      </div>
    </div>
    <div class="data-compliance" @mouseenter="mouseEnter('compliance')">
      <div
        class="wrapper right"
        :class="isShowCompliance ? 'zoomin' : ''"
        v-show="isShowCompliance"
      >
        <structure-card
          v-if="locale === 'cn'"
          title-cn="合规"
          title-en="Compliance"
          :content="['采集管理', '数据解析', '数据挖掘']"
          order="02"
        />
        <structure-card-en
          v-if="locale === 'en'"
          title="Compliance"
          :content="['Collection Management', 'Data Analysis', 'Data Mining']"
          order="02"
        />
      </div>
    </div>
    <div class="data-annotation" @mouseenter="mouseEnter('annotation')">
      <div
        class="wrapper right"
        :class="isShowAnnotation ? 'zoomin' : ''"
        v-show="isShowAnnotation"
      >
        <structure-card
          v-if="locale === 'cn'"
          title-cn="标注"
          title-en="Annotation"
          :content="['2D3D联合标注', '数据可视化', '灵活模板', '标注工具']"
          order="03"
        />
        <structure-card-en
          v-if="locale === 'en'"
          title="Annotation"
          :content="[
            '2D 3D Joint Labelling',
            'Data Visualization',
            'Flexible Template',
            'Labelling Tools',
          ]"
          order="03"
        />
      </div>
    </div>
    <div class="data-training" @mouseenter="mouseEnter('training')">
      <div
        class="wrapper right"
        :class="isShowTraining ? 'zoomin' : ''"
        v-show="isShowTraining"
      >
        <structure-card
          v-if="locale === 'cn'"
          title-cn="训练"
          title-en="Training"
          :content="['模型部署', '模型管理', '弹性计算', '闭环训练']"
          order="04"
        />
        <structure-card-en
          v-if="locale === 'en'"
          title="Training"
          :content="[
            'Model Deployment',
            'Model Management',
            'Elastic Computing',
            'Closed-Loop Training',
          ]"
          order="04"
        />
      </div>
    </div>
    <div class="data-simulation" @mouseenter="mouseEnter('simulation')">
      <div
        class="wrapper left"
        :class="isShowSimulation ? 'zoomin' : ''"
        v-show="isShowSimulation"
      >
        <structure-card
          v-if="locale === 'cn'"
          title-cn="仿真"
          title-en="Simulation"
          :content="['多模块仿真', '评价体系', '并发调整', '任务管理']"
          order="05"
        />
        <structure-card-en
          v-if="locale === 'en'"
          title="Simulation"
          :content="[
            'Multi-module Simulation',
            'Evaluation System',
            'Concurrent Scheduling',
            'Task Management',
          ]"
          order="05"
        />
      </div>
    </div>
    <div class="data-monitor" @mouseenter="mouseEnter('monitor')">
      <div
        class="wrapper left"
        :class="isShowMonitor ? 'zoomin' : ''"
        v-show="isShowMonitor"
      >
        <structure-card
          v-if="locale === 'cn'"
          title-cn="监控"
          title-en="Monitor"
          :content="['数据回放', '数据大屏', '实时监控']"
          order="06"
        />
        <structure-card-en
          v-if="locale === 'en'"
          title="Monitor"
          :content="['Data Reply', 'Data Visualization', 'Live Monitoring']"
          order="06"
        />
      </div>
    </div>
    <div class="data-manage" @mouseenter="mouseEnter('manage')">
      <div
        class="wrapper left"
        :class="isShowManage ? 'zoomin' : ''"
        v-show="isShowManage"
      >
        <structure-card
          v-if="locale === 'cn'"
          title-cn="管理"
          title-en="Manage"
          :content="['集中存储', '灵活调度', '安全保障']"
          order="07"
        />
        <structure-card-en
          v-if="locale === 'en'"
          title="Manage"
          :content="[
            'Centralized storage',
            'Flexible scheduling',
            'Safety and security',
          ]"
          order="07"
        />
      </div>
    </div>
    <div
      class="tip"
      data-aos="zoom-in"
      data-aos-offset="0"
      data-aos-delay="100"
      data-aos-duration="800"
      data-aos-once="true"
    >
      <div class="text">{{ $t("galaxy.section3.centerTitle") }}</div>
      <div class="triangle"></div>
    </div>
  </section>
</template>

<script>
import StructureCard from "./StructureCard.vue";
import StructureCardEn from "./StructureCardEn.vue";
import { mapState } from "vuex";
import "animate.css";
export default {
  name: "StructureSection",
  components: {
    StructureCard,
    StructureCardEn,
  },

  data() {
    return {
      isShowCollection: true,
      isShowCompliance: false,
      isShowAnnotation: false,
      isShowTraining: false,
      isShowSimulation: false,
      isShowMonitor: false,
      isShowManage: false,
    };
  },

  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },

  methods: {
    mouseEnter(type) {
      if (type === "collection") {
        this.isShowCollection = true;
        this.isShowCompliance = false;
        this.isShowAnnotation = false;
        this.isShowTraining = false;
        this.isShowSimulation = false;
        this.isShowMonitor = false;
        this.isShowManage = false;
      } else if (type === "compliance") {
        this.isShowCollection = false;
        this.isShowCompliance = true;
        this.isShowAnnotation = false;
        this.isShowTraining = false;
        this.isShowSimulation = false;
        this.isShowMonitor = false;
        this.isShowManage = false;
      } else if (type === "annotation") {
        this.isShowCollection = false;
        this.isShowCompliance = false;
        this.isShowAnnotation = true;
        this.isShowTraining = false;
        this.isShowSimulation = false;
        this.isShowMonitor = false;
        this.isShowManage = false;
      } else if (type === "training") {
        this.isShowCollection = false;
        this.isShowCompliance = false;
        this.isShowAnnotation = false;
        this.isShowTraining = true;
        this.isShowSimulation = false;
        this.isShowMonitor = false;
        this.isShowManage = false;
      } else if (type === "simulation") {
        this.isShowCollection = false;
        this.isShowCompliance = false;
        this.isShowAnnotation = false;
        this.isShowTraining = false;
        this.isShowSimulation = true;
        this.isShowMonitor = false;
        this.isShowManage = false;
      } else if (type === "monitor") {
        this.isShowCollection = false;
        this.isShowCompliance = false;
        this.isShowAnnotation = false;
        this.isShowTraining = false;
        this.isShowSimulation = false;
        this.isShowMonitor = true;
        this.isShowManage = false;
      } else if (type === "manage") {
        this.isShowCollection = false;
        this.isShowCompliance = false;
        this.isShowAnnotation = false;
        this.isShowTraining = false;
        this.isShowSimulation = false;
        this.isShowMonitor = false;
        this.isShowManage = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/common.less";
@import "~@/assets/css/image.less";
@design_width: 1280;
.structure-section {
  position: relative;
  background-image: url("@{structureBg}");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: calc(100vw * 720 / @design_width);
  min-height: 7.2rem;

  div[class^="data-"] {
    cursor: pointer;
  }

  .wrapper {
    position: absolute;

    &.left {
      right: 50%;
      bottom: 50%;
    }

    &.right {
      left: 50%;
      bottom: 50%;
    }
  }

  .zoomin {
    animation: zoomIn;
    animation-duration: 500ms;
  }

  .data-collection {
    position: absolute;
    top: calc(100vw * 140 / @design_width);
    left: calc(100vw * 525 / @design_width);
    width: calc(100vw * 101 / @design_width);
    height: calc(100vw * 114 / @design_width);
  }

  .data-compliance {
    position: absolute;
    top: calc(100vw * 165 / @design_width);
    right: calc(100vw * 410 / @design_width);
    width: calc(100vw * 135 / @design_width);
    height: calc(100vw * 115 / @design_width);
  }

  .data-annotation {
    position: absolute;
    top: calc(100vw * 249 / @design_width);
    right: calc(100vw * 270 / @design_width);
    width: calc(100vw * 135 / @design_width);
    height: calc(100vw * 123 / @design_width);
  }
  .data-training {
    position: absolute;
    bottom: calc(100vw * 175 / @design_width);
    right: calc(100vw * 340 / @design_width);
    width: calc(100vw * 145 / @design_width);
    height: calc(100vw * 150 / @design_width);
  }
  .data-simulation {
    position: absolute;
    bottom: calc(100vw * 160 / @design_width);
    left: calc(100vw * 376 / @design_width);
    width: calc(100vw * 160 / @design_width);
    height: calc(100vw * 158 / @design_width);
  }

  .data-monitor {
    position: absolute;
    bottom: calc(100vw * 300 / @design_width);
    left: calc(100vw * 250 / @design_width);
    width: calc(100vw * 130 / @design_width);
    height: calc(100vw * 124 / @design_width);
  }
  .data-manage {
    position: absolute;
    top: calc(100vw * 185 / @design_width);
    left: calc(100vw * 310 / @design_width);
    width: calc(100vw * 140 / @design_width);
    height: calc(100vw * 120 / @design_width);
  }

  .tip {
    position: absolute;
    top: 38%;
    left: 55%;
    height: 0.48rem;
    line-height: 0.48rem;
    background: linear-gradient(
      -92deg,
      #364242 3.92%,
      #2b2f37 48.34%,
      #21262b 96.58%
    );
    opacity: 0.95;
    backdrop-filter: blur(0.04rem);
    // transform: matrix(-1, 0, 0, 1, 0, 0);
    border-top-left-radius: 0.06rem;
    border-top-right-radius: 0.06em;
    border-bottom-right-radius: 0.06rem;

    .text {
      font-family: "HarmonyOS Sans SC";
      font-style: normal;
      font-weight: 400;
      font-size: 0.12rem;
      letter-spacing: 0.01em;
      color: @primary-color;
      text-align: center;
      padding: 0 0.2rem;
    }

    .triangle {
      width: 0;
      height: 0;
      border-top: solid 0.06rem #21262b;
      border-left: solid 0.06rem #21262b;
      border-right: solid 0.06rem transparent;
      border-bottom: solid 0.06rem transparent;
    }
  }
}
</style>
