export default {
  homeTitle: "Intelligent Vehicle is the Stage-1 Robotics",
  robotics: "LOTUS ROBOTICS",
  moduleContent: {
    0: {
      text1: 'In the era with the pursuit of "higher, faster and stronger"',
      text2: "Lotus is an excellent practice platform for intelligent driving.",
    },
    1: {
      text1: "We deal with tomorrow's problems with tomorrow's tools,",
      text2: "not today's tools.",
    },
    2: {
      text1:
        "Core of intelligent driving operations - enhancement and compensation.",
      text2: "",
    },
    3: {
      text1: 'Redefine the intelligent driving grading with "MPI" and "CMI";',
      text2: "Empower the industry and cultivate talents.",
    },
  },
  knowMore: "Learn More",
  joinTitle: "Join LOTUS ROBOTICS",
  joinContent: "COMING TOGETHER TO BUILD A BETTER US",
  joinButton: "View Open Positions",
  mediaContact: "Media Relations",
  comContact: "Business Relations",
  email: "E-mail",
  global: "globalization",
};
