<template>
  <div class="data-collection-section">
    <div class="headline">
      <h1
        class="title"
        data-aos="fade-up"
        data-aos-offset="0"
        data-aos-delay="100"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        {{ $t("galaxy.section5.title") }}
      </h1>
      <h2
        class="sub-title"
        data-aos="fade-up"
        data-aos-offset="0"
        data-aos-delay="200"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        {{ $t("galaxy.section5.subtitle") }}
      </h2>
    </div>
    <div
      class="data-collection-swiper"
      data-aos="fade-right"
      data-aos-offset="0"
      data-aos-delay="300"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <div class="swiper-wrapper">
        <div class="block swiper-slide">
          <div class="content slider1">
            <div class="title-wrapper">
              <div class="flag"></div>
              <div class="main-title">{{ $t("galaxy.section5.title1") }}</div>
            </div>
            <div>
              <div :class="'title ' + locale">
                {{ $t("galaxy.section5.subtitle1") }}
              </div>
              <div :class="'text ' + locale">
                {{ $t("galaxy.section5.content1") }}
              </div>
            </div>
          </div>
          <div class="pic-wrapper">
            <div class="pic1">
              <div class="rhombus1"></div>
              <div class="rhombus2"></div>
            </div>
          </div>
        </div>
        <div class="block swiper-slide">
          <div class="content">
            <div class="title-wrapper">
              <div class="flag"></div>
              <div class="main-title">{{ $t("galaxy.section5.title2") }}</div>
            </div>
            <div>
              <div :class="'title ' + locale">
                {{ $t("galaxy.section5.subtitle2") }}
              </div>
              <div :class="'text ' + locale">
                {{ $t("galaxy.section5.content2") }}
              </div>
            </div>
          </div>
          <div class="pic2">
            <div class="rhombus1"></div>
            <div class="rhombus2"></div>
          </div>
        </div>
        <div class="block swiper-slide">
          <div class="content">
            <div class="title-wrapper">
              <div class="flag"></div>
              <div class="main-title">{{ $t("galaxy.section5.title3") }}</div>
            </div>
            <div>
              <div :class="'title ' + locale">
                {{ $t("galaxy.section5.subtitle3") }}
              </div>
              <div :class="'text ' + locale">
                {{ $t("galaxy.section5.content3") }}
              </div>
            </div>
          </div>
          <div class="pic3">
            <div class="rhombus1"></div>
            <div class="rhombus2"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.css";
import { mapState } from "vuex";
export default {
  name: "DataCollectionSection",
  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },

  mounted() {
    new Swiper(".data-collection-swiper", {
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
      },
    });
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/galaxy.less";
@import "~@/assets/css/common.less";
@import "~@/assets/css/image.less";
.data-collection-section {
  height: 7.2rem;
  background-color: #131313;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;

  .block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .pic1 {
      position: relative;
      width: 3.65rem;
      height: 4.15rem;
      background-image: url("@{dataCollectionImg1}");
      background-repeat: no-repeat;
      background-size: contain;
    }

    .pic2 {
      position: relative;
      width: 3.65rem;
      height: 4.15rem;
      background-image: url("@{dataCollectionImg2}");
      background-repeat: no-repeat;
      background-size: contain;
    }
    .pic3 {
      position: relative;
      width: 3.65rem;
      height: 4.15rem;
      background-image: url("@{dataCollectionImg3}");
      background-repeat: no-repeat;
      background-size: contain;
    }

    .rhombus1 {
      position: absolute;
      width: 4.77rem;
      height: 2.26rem;
      background-image: url("@{dataCollectionRhombus1}");
      background-repeat: no-repeat;
      background-size: contain;
      bottom: -0.15rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }
    .rhombus2 {
      position: absolute;
      width: 6.07rem;
      height: 2.98rem;
      background-image: url("@{dataCollectionRhombus1}");
      background-repeat: no-repeat;
      background-size: contain;
      bottom: -0.55rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }

    .content {
      margin-right: 1.12rem;
      font-size: 0;
      .title-wrapper {
        display: flex;
        margin-bottom: 0.05rem;
      }
      .flag {
        width: 0.3rem;
        height: 0.3rem;
        background-image: url("@{dataCollectionFlag}");
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 0.15rem;
      }
      .main-title {
        font-family: "HarmonyOS Sans SC";
        font-style: normal;
        font-weight: 400;
        font-size: 0.25rem;
        line-height: 0.32rem;
        letter-spacing: 0.01em;
        color: @primary-color;
      }
      .mark {
        width: 0.08rem;
        height: 0.26rem;
        background: linear-gradient(
          269.44deg,
          @primary-color -4%,
          #00ffc2 102.4%
        );
        border-radius: 0.2rem;
        margin-right: 0.18rem;
      }

      .title {
        width: 4.5rem;
        font-family: "HarmonyOS Sans SC";
        font-style: normal;
        font-weight: 400;
        font-size: 0.25rem;
        line-height: 0.32rem;
        letter-spacing: 0.01em;
        color: #ffffff;
        &.cn {
          width: 5.2rem;
        }
      }
      .text {
        width: 4.22rem;
        font-family: "HarmonyOS Sans SC";
        font-style: normal;
        font-weight: 400;
        font-size: 0.18rem;
        line-height: 0.24rem;
        color: #646368;
        margin-top: 0.3rem;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .data-collection-section {
    height: 10.8rem;
    .block {
      display: block;
      position: relative;
      margin: 1.04rem 0 0 0rem;
      height: 7.6rem;
      overflow: hidden;

      .content {
        margin: 0 0 0 1.01rem;
        .text {
          width: 5.26rem;

          &.cn {
            line-height: 0.28rem;
          }
        }
      }

      .pic1,
      .pic2,
      .pic3 {
        float: right;
      }
    }
  }
}
</style>
