import galaxy from "./galaxy-en";
import home from "./home-en";
import footer from "./footer-en";
import matrix from "./matrix-en";
import soul from "./soul-en";
import verse from "./verse-en";
import news from "./news-en";
export default {
  home,
  galaxy,
  matrix,
  footer,
  soul,
  verse,
  news,
};
