<template>
  <div class="development-process-section">
    <section class="head-line">
      <template
        data-aos="fade-up"
        data-aos-offset="120"
        data-aos-delay="100"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        <p :class="['text', 'head-ch', { en: locale === 'en' }]">
          {{ $t("soul.developmentTitle1") }}
        </p>
      </template>
      <p
        class="text head-en"
        data-aos="fade-left"
        data-aos-offset="120"
        data-aos-delay="100"
        data-aos-duration="1200"
        data-aos-once="true"
      >
        {{ $t("soul.developmentTitle2") }}
      </p>
    </section>
    <section class="sub-head-line">
      <p
        class="text sub-head-ch"
        data-aos="fade-up"
        data-aos-offset="120"
        data-aos-delay="100"
        data-aos-duration="1200"
        data-aos-once="true"
      >
        {{ $t("soul.developmentSubTitle1") }}
        &nbsp;&nbsp;&nbsp;
        <br v-if="locale === 'en'" />
        {{ $t("soul.developmentSubTitle2") }}
      </p>
      <!-- <p
        class="text sub-head-en"
        data-aos="fade-left"
        data-aos-offset="120"
        data-aos-delay="100"
        data-aos-duration="1200"
        data-aos-once="true"
      >
        Self-developed compound development framework for SW<br />
        Agile Software Development+ V-Model Software Development
      </p> -->
    </section>
    <section
      class="frame-container"
      data-aos="fade-left"
      data-aos-offset="120"
      data-aos-delay="100"
      data-aos-duration="1200"
      data-aos-once="true"
    >
      <div class="frame">
        <div
          :class="['frame-loading-animate', { en: locale === 'en' }]"
          ref="frame_loading_animate_el"
        >
          <img
            src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/soul/development_process_frame_loading.png"
            alt=""
          />
        </div>
        <img
          v-if="locale === 'cn'"
          class="frame-img"
          src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/soul/development_process_frame.png"
          alt=""
        />
        <img
          v-else
          class="frame-img"
          src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/soul/development_process_frame_en.png"
          alt=""
        />
        <img
          class="vcode-img"
          src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/soul/v_code.png"
          alt=""
        />
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "CmiMpi",
  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },
};
</script>

<style lang="less">
@import "~@/assets/css/common.less";
@import "~@/assets/css/image.less";
.development-process-section {
  background-image: url("@{developmentProcessBg}");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: calc(100vh - 0.5rem);
  min-height: 7.12rem;
  overflow: hidden;
  @mrg-left: 2.02rem;
  p {
    margin: 0;
    padding: 0;
  }
  .text {
    font-family: "HarmonyOS Sans SC";
    font-style: normal;
    letter-spacing: 0.05em;
  }

  .head-line {
    margin-top: 0.88rem;
    margin-left: @mrg-left;

    .head-ch {
      font-weight: 900;
      font-size: 0.28rem;
      color: @primary-color;
    }
    .head-en {
      font-weight: 400;
      font-size: 0.28rem;
      text-transform: uppercase;
      color: #646368;
      margin-top: 0.06rem;
    }
  }

  .sub-head-line {
    margin-top: 0.35rem;
    margin-left: @mrg-left;
    .sub-head-ch {
      font-weight: 400;
      font-size: 0.22rem;
      color: @primary-color;
    }
    .sub-head-en {
      font-weight: 400;
      font-size: 0.16rem;
      color: #646368;
      margin-top: 0.1rem;
    }
  }

  .frame-container {
    width: 100%;
    height: 65%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .frame {
      margin: auto;
      width: fit-content;
      position: relative;
      text-align: center;
      .frame-loading-animate {
        position: absolute;
        left: 4.17rem;
        top: 2.475rem;
        width: 1.74rem;
        height: 1.74rem;
        transform: translate(-50%, -50%);
        background-color: transparent;
        border-radius: 100%;
        z-index: 1;
        animation: loading-rotate 1.5s infinite ease-in-out;
        &.en {
          top: 2.535rem;
        }
        img {
          width: 100%;
        }
        @keyframes loading-rotate {
          from {
            transform: translate(-50%, -50%) rotate(0deg);
          }
          to {
            transform: translate(-50%, -50%) rotate(360deg);
          }
        }
      }
      .frame-img {
        width: 7.67rem;
      }
      .vcode-img {
        width: 3.95rem;
        transform: translate(-0.2rem, -0.6rem);
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .development-process-section {
    height: auto;
    background-image: url("@{mobileDevelopmentProcessBg}");
    padding-bottom: 1rem;
    @mobile-mrg-left: 0.67rem;
    .head-line {
      margin-top: 1.92rem;
      margin-left: @mobile-mrg-left;
      .head-en {
        width: 5rem;
        margin-top: 0.1rem;
      }
      .text {
        &.en {
          color: #646368;
        }
      }
    }

    .sub-head-line {
      margin-left: @mobile-mrg-left;
    }

    .frame-container {
      .frame {
        width: 6.8rem;
        margin: 0.9rem auto 0;
        .frame-loading-animate {
          left: 3.7rem;
          top: 2.21rem;
          width: 1.7rem;
          height: 1.7rem;
          &.en {
            top: 2.235rem;
          }
        }
        .frame-img {
          width: 6.8rem;
        }
        .vcode-img {
          width: 5.22rem;
          transform: translate(0, 0);
          margin-top: 0.5rem;
        }
      }
    }
  }
}
</style>
