<template>
  <div class="structure-card-en">
    <div class="title">
      <span>Data</span><br />
      <span>{{ title }}</span>
    </div>

    <ul class="content">
      <li class="item" v-for="(item, index) in content" :key="index">
        {{ item }}
      </li>
    </ul>

    <div class="order">{{ order }}</div>
  </div>
</template>

<script>
export default {
  name: "StructureCardEn",
  props: {
    title: String,
    content: Array,
    order: String,
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/common.less";
@import "~@/assets/css/font.css";
.structure-card-en {
  position: relative;
  background: linear-gradient(
    98.62deg,
    #364242 3.92%,
    #2b2f37 48.34%,
    #21262b 96.58%
  );
  opacity: 0.95;
  backdrop-filter: blur(0.05rem);
  border-radius: 0.08rem;
  width: 1.6rem;
  height: 2rem;
  padding: 0.2rem 0 0 0.1rem;
  box-sizing: border-box;

  .title {
    font-family: "HarmonyOS Sans SC";
    font-style: normal;
    font-weight: 700;
    font-size: 0.1rem;
    line-height: 0.14rem;
    letter-spacing: 0.01em;
    color: @primary-color;
  }

  .content {
    padding: 0 0.15rem 0 0;
    margin: 0.2rem 0 0 0;
    list-style: none;
    // height: 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .item {
      font-family: "HarmonyOS Sans SC Thin";
      font-style: normal;
      font-weight: 400;
      font-size: 0.08rem;
      line-height: 0.22rem;
      letter-spacing: 0.01em;
      color: #999999;
    }
  }

  .order {
    position: absolute;
    left: 0.1rem;
    bottom: 0.05rem;
    font-family: "GT_LotusUI_Sport_Monospaced";
    font-style: normal;
    font-weight: 400;
    font-size: 0.14rem;
    line-height: 0.19rem;
    letter-spacing: 0.01em;
    color: @primary-color;
  }
}
</style>
