<template>
  <div class="robo-new-creature">
    <video
      class="audio-container"
      autoplay
      muted
      playsinline
      x-webkit-playsinline
      x5-playsinline
      x5-video-player-type="h5"
      x5-video-player-fullscreen
      loop
      poster="http://ad4-static.lotuscars.com.cn/webapp/pc/image/newcreature/new_creature_poster.png"
    >
      <source
        v-if="isMobileFlag"
        src="http://ad4-static.lotuscars.com.cn/webapp/mobile/video/newcreature/mobile_new_creature.mp4"
      />
      <source
        v-else
        src="http://ad4-static.lotuscars.com.cn/webapp/pc/video/newcreature/new_creature.mp4"
      />
    </video>
    <div class="main-text">
      <div
        class="desc-text top"
        data-aos="zoom-in-down"
        data-aos-offset="0"
        data-aos-delay="100"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        <h3>@2023</h3>
        <h3>CODE DIFFERENT</h3>
      </div>
      <div
        class="desc-text bottom"
        data-aos="zoom-in-right"
        data-aos-offset="0"
        data-aos-delay="200"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        <h3 style="text-transform: uppercase">To Boost the Robotics Era</h3>
        <h3></h3>
      </div>
    </div>
    <div class="icon">
      <SlideIcon />
    </div>
  </div>
</template>

<script>
import SlideIcon from "@/components/SlideIcon.vue";
import { isMobile } from "@/utils/isMobile";

export default {
  name: "RoboNewCreature",
  components: {
    SlideIcon,
  },
  data() {
    const isMobileFlag = isMobile();
    return {
      isMobileFlag: isMobileFlag,
    };
  },
};
</script>

<style lang="less" scoped>
.robo-new-creature {
  width: 100%;
  height: calc(100vh - 0.5rem);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .audio-container {
    width: 100%;
    margin-top: -0.5rem;
  }
  .desc-text {
    font-family: "HarmonyOS Sans SC";
    font-style: normal;
    font-weight: 400;
    font-size: 0.1rem;
    line-height: 1.15;
    letter-spacing: 0.01em;
    color: #ffffff;
    position: absolute;
    &.top {
      top: 3.1rem;
      left: 0.84rem;
    }
    &.bottom {
      left: 0.84rem;
      top: 5.72rem;
    }
  }
}

@media only screen and (max-width: 750px) {
  .robo-new-creature {
    height: 100vh;
    .audio-container {
      position: absolute;
      top: 0rem;
    }
    .desc-text {
      &.top {
        font-size: 0.14rem;
        line-height: 0.19rem;
        left: 0.55rem;
        top: 7.31rem;
      }
      &.bottom {
        font-size: 0.14rem;
        line-height: 0.19rem;
        left: 0.55rem;
        top: 10.58rem;
      }
    }
    .icon {
      display: none;
    }
  }
}
</style>
