<template>
  <svg
    class="mobile-value-svg"
    width="411"
    height="249"
    viewBox="0 0 411 249"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="mobile-value-path"
      d="M-138 149.5C-114.5 173.5 -108.5 180 -78 206.5C-50.5 228.167 26.8047 299 107 162C142.905 100.662 183.862 51.7825 227 29.5C290.409 -3.25333 354 6.00012 406.5 6.00011"
      stroke="#00FF00"
      stroke-width="8"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: "ValueMobileSvg",

  props: {
    width: Number,
    height: Number,
    strokeWidth: Number,
  },

  mounted() {
    const path = document.querySelector(".mobile-value-path");
    const pathLength = path.getTotalLength();
    path.style.strokeDasharray = pathLength;
    path.style.strokeDashoffset = pathLength;
    this.setObserver();
  },

  methods: {
    setObserver() {
      var observer = new IntersectionObserver((entries) => {
        let entry = entries[0];
        if (entry.isIntersecting) {
          const path = document.querySelector(".mobile-value-path");
          const pathLength = path.getTotalLength();
          setTimeout(() => {
            path.style.transition = "stroke-dashoffset 1.5s linear";
            path.style.strokeDashoffset = pathLength * 2;
          }, 200);
        }
      });
      observer.observe(document.querySelector(".mobile-value-svg"));
    },
  },
};
</script>
