<template>
  <div class="copy-right">
    ©2023 LOTUS ROBOTICS
    {{ $t("footer.copyRight") + " " }}
    <span @click="clickHandler">{{ $t("footer.filling") }}</span>
  </div>
</template>

<script>
export default {
  name: "CopyRight",
  methods: {
    clickHandler() {
      window.open("https://beian.miit.gov.cn/", "_self");
    },
  },
};
</script>

<style lang="less" scoped>
.copy-right {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 0.12rem;
  color: rgba(0, 0, 0, 0.8);
  background: #3dff2d;
  padding: 0.2rem 0 0.2rem 1rem;
  font-family: "HarmonyOS Sans SC Regular";
  span {
    cursor: pointer;
  }
}

@media only screen and (max-width: 750px) {
  .copy-right {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 0.12rem;
    color: rgba(0, 0, 0, 0.8);
    background: #3dff2d;
    padding: 0.2rem 0 0.2rem 0.2rem;
    font-family: "HarmonyOS Sans SC Regular";
  }
}
</style>
