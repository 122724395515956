export default {
  section2: {
    card1: {
      title1: "ROBOVERSE",
      title2: "INTRODUCE",
      content:
        "Roboverse是一个大型路径规划数据集；利用仿真技术开发了新颖并具挑战性的路径规划基准测试。我们感兴趣的任务是优化路径规划，提高完成效率。现有的规划控制算法缺乏科学性和系统性的评价体系，Roboverse致力于提供一个标准化的规划算法评价体系，旨在评价规划算法性能。",
    },

    card2: {
      title1: "ROBOVERSE",
      title2: "数据集",
      content:
        "Roboverse数据集包括国内4个城市（苏州，上海，杭州和武汉）的2W个场景。这4个城市的道路交通模式差异很大，数据集覆盖城市，高速，停车3大场景；Roboverse数据集拥有业内高配置传感器提供的高质量数据；数据集来源多样性，包括地理多样性及条件多样性。",
    },
  },

  section4: {
    title1: "ROBOVERSE",
    list: [
      {
        icon: "http://ad4-static.lotuscars.com.cn/webapp/pc/image/verse/rv_pv_icon1.png",
        title: "通用接口格式",
        content: "Roboverse为参赛者提供了支持通用接口的规划器格式。",
      },
      {
        icon: "http://ad4-static.lotuscars.com.cn/webapp/pc/image/verse/rv_pv_icon2.png",
        title: "模拟仿真引擎",
        content: "提供从完美控制过渡到车辆空气动力学的轻量闭环模拟仿真引擎。",
      },
      {
        icon: "http://ad4-static.lotuscars.com.cn/webapp/pc/image/verse/rv_pv_icon3.png",
        title: "评价体系与标准",
        content:
          "更加完善合理的评价体系，从安全，智能，舒适，交规这4个方面对规控算法进行全方位评价。更加体系化的评价标准，运用通过率等参数定义什么是最好的规控算法。",
      },
      {
        icon: "http://ad4-static.lotuscars.com.cn/webapp/pc/image/verse/rv_pv_icon4.png",
        title: "Lviz回放系统",
        content:
          "自主研发的Lviz回放系统可还原仿真结果，大大提高了在线回放效率，缩短文件上传下载时间。",
      },
    ],
  },

  section5: {
    title: "数据采集",

    list: [
      {
        icon: "http://ad4-static.lotuscars.com.cn/webapp/pc/image/verse/rv_dc_icon1.png",
        title: "采集方法",
        content:
          "所有数据都是通过手动驾驶收集的真实场景，以获得适合模仿学习的地面实况。",
        no: "NO.1",
        enTitle1: "COLLECTION",
        enTitle2: "METHOD",
      },
      {
        icon: "http://ad4-static.lotuscars.com.cn/webapp/pc/image/verse/rv_dc_icon2.png",
        title: "传感器配置",
        content:
          "4x Lidar (10Hz) <br />1x 4D Radar (50Hz)<br />7x 8M Camera (30Hz)<br />4x 2M Camera (30Hz)",
        no: "NO.2",
        enTitle1: "SENSOR",
        enTitle2: "CONFIGURATION",
      },
      {
        icon: "http://ad4-static.lotuscars.com.cn/webapp/pc/image/verse/rv_dc_icon3.png",
        title: "地图信息",
        content:
          "手动注视的详细2D高清地图，具有语义类别，例如道路，人行道，车道，交通灯等等。并提供通用地图接口。",
        no: "NO.3",
        enTitle1: "MAP",
        enTitle2: "INFORMATION",
      },
    ],
  },

  section6: {
    decoration: "GEOGRAPHIC DIVERSITY",
    title: "地理多样性",
    list: [
      {
        enTitle: "HANGZHOU",
        cnTitle: "杭州",
      },
      {
        enTitle: "SHANGHAI",
        cnTitle: "上海",
      },
      {
        enTitle: "WUHAN",
        cnTitle: "武汉",
      },
      {
        enTitle: "SUZHOU",
        cnTitle: "苏州",
      },
    ],
  },

  section8: {
    title: "COVER MORE SCENARIOS",
    desc: "为了尽可能覆盖更多的场景，以展现规划师真正的算法能力，我们利用场景挖掘算法标注数据集中大家感兴趣及边缘场景，建立大规模的仿真场景库。",
  },

  section9: {
    title: "数据标注平台",
    title1: "点云融合",
    content1:
      "点云融合标注可助力智能驾驶模型的训练，可对多传感器融合的数据进行同时标注，应用于智能驾驶场景的训练落地。",
    title2: "3D点云",
    content2:
      "3D点云助力智能驾驶模型的训练，路特斯机器人基于智能驾驶标注经验，对3D障碍物进行框选、对雷达图进行语义分割，帮助车辆更好的感知路面。",
    title3: "语义分割",
    content3:
      "基于区域的形状标注，对复杂不规则图片进行区域划分及属性标注，主要应用于场景分割，智能驾驶道路分割等。",
    title4: "图片分类",
    content4:
      "可实现千万级图片分类，并对图片做属性归类，有助于图像识别的模型训练。",
  },

  section10: {
    title: "框架规划",
  },

  section11: {
    title: "赛事工具",
    learnMore: "了解更多",
  },

  section12: {
    title: "评价体系",
    list: [
      {
        no: "01",
        feature: "安全",
        desc: "用于衡量算法的碰撞风险，计算和其他车辆或智能体是否存在碰撞风险，是否在地图制定区域内行驶等。",
      },
      {
        no: "02",
        feature: "智能",
        desc: "用于衡量算法的智能程度，是否存在长时间无故停车，绿灯不起步或连续变道等反智能的规划策略。",
      },
      {
        no: "03",
        feature: "交规",
        desc: "用于衡量算法的合规性能，是否出现闯红灯，压实线，逆行等违反法规的操作。",
      },
      {
        no: "04",
        feature: "舒适",
        desc: "用于衡量算法的舒适性，是否存在急刹急停，急转向等引起体感不适的规划策略。",
      },
    ],
  },
};
