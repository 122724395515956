<template>
  <div class="robo-verse-page">
    <first-section />
    <second-section />
    <third-mobile-section v-if="isMobile" />
    <third-section v-else />
    <fourth-section />
    <fifth-mobile-section v-if="isMobile" />
    <fifth-section v-else />
    <sixth-section />
    <seventh-section />
    <eighth-mobile-section v-if="isMobile" />
    <eighth-section v-else />
    <ninth-mobile-section v-if="isMobile" />
    <ninth-section v-else />
    <tenth-section />
    <eleventh-section />
    <twelveth-section />
    <thirteenth-section />
  </div>
</template>

<script>
import FirstSection from "./components/FirstSection.vue";
import SecondSection from "./components/SecondSection.vue";
import ThirdSection from "./components/ThirdSection.vue";
import ThirdMobileSection from "./components/ThirdMobileSection.vue";
import FourthSection from "./components/FourthSection.vue";
import FifthSection from "./components/FifthSection.vue";
import FifthMobileSection from "./components/FifthMobileSection.vue";
import SixthSection from "./components/SixthSection.vue";
import SeventhSection from "./components/SeventhSection.vue";
import EighthSection from "./components/EighthSection.vue";
import EighthMobileSection from "./components/EighthMobileSection.vue";
import NinthSection from "./components/NinthSection.vue";
import NinthMobileSection from "./components/NinthMobileSection.vue";
import TenthSection from "./components/TenthSection.vue";
import EleventhSection from "./components/EleventhSection.vue";
import TwelvethSection from "./components/TwelvethSection.vue";
import ThirteenthSection from "./components/ThirteenthSection.vue";
import { mapState } from "vuex";
export default {
  name: "RoboVerse",

  components: {
    FirstSection,
    SecondSection,
    ThirdSection,
    ThirdMobileSection,
    FourthSection,
    FifthSection,
    FifthMobileSection,
    SixthSection,
    SeventhSection,
    EighthSection,
    EighthMobileSection,
    NinthSection,
    NinthMobileSection,
    TenthSection,
    EleventhSection,
    TwelvethSection,
    ThirteenthSection,
  },

  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
      isMobile: (state) => state.isMobile,
    }),
  },
};
</script>

<style lang="less" scoped>
.robo-verse-page {
  color: #fff;
  overflow-x: hidden;
}
</style>
