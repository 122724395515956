<template>
  <div class="structure-card">
    <div class="top" style="font-size: 0">
      <div class="title-cn">
        <span>数据</span>
        <br />
        <span>{{ titleCn }}</span>
      </div>

      <div class="line"></div>

      <div class="title-en">
        <span>Data</span><br />
        <span>{{ titleEn }}</span>
      </div>
    </div>

    <ul class="content">
      <li class="item" v-for="(item, index) in content" :key="index">
        {{ item }}
      </li>
    </ul>

    <div class="order">{{ order }}</div>
  </div>
</template>

<script>
export default {
  name: "StructureCard",
  props: {
    titleCn: String,
    titleEn: String,
    content: Array,
    order: String,
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/common.less";
@import "~@/assets/css/font.css";
.structure-card {
  position: relative;
  background: linear-gradient(
    98.62deg,
    #364242 3.92%,
    #2b2f37 48.34%,
    #21262b 96.58%
  );
  opacity: 0.95;
  backdrop-filter: blur(0.05rem);
  border-radius: 0.08rem;
  width: 1.2rem;
  height: 1.6rem;
  padding: 0.09rem 0 0 0.1rem;
  box-sizing: border-box;

  .title-cn {
    font-family: "HarmonyOS Sans SC Thin";
    font-style: normal;
    font-weight: 400;
    font-size: 0.12rem;
    line-height: 0.14rem;
    letter-spacing: 0.01em;
    color: @primary-color;
  }

  .title-en {
    margin: -0.1rem 0 0 0.45rem;
    font-family: "HarmonyOS Sans SC Thin";
    font-style: normal;
    font-weight: 400;
    font-size: 0.1rem;
    line-height: 0.12rem;
    letter-spacing: 0.01em;
    color: @primary-color;
  }

  .line {
    position: absolute;
    width: 1px;
    height: 0.5rem;
    top: 0.06rem;
    left: 0.4rem;
    background: @primary-color;
    transform: rotate(30deg);
  }

  .content {
    float: right;
    padding: 0 0.15rem 0 0;
    margin: 0.15rem 0 0 0;
    list-style: none;
    height: 0.6rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .item {
      font-family: "HarmonyOS Sans SC Thin";
      font-style: normal;
      font-weight: 400;
      font-size: 0.1rem;
      line-height: 0.12rem;
      letter-spacing: 0.01em;
      color: #999999;
    }
  }

  .order {
    position: absolute;
    left: 0.1rem;
    bottom: 0.05rem;
    font-family: "GT_LotusUI_Sport_Monospaced";
    font-style: normal;
    font-weight: 400;
    font-size: 0.14rem;
    line-height: 0.19rem;
    letter-spacing: 0.01em;
    color: @primary-color;
  }
}
</style>
