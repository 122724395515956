const state = () => ({
  activeMenu: "Home",
  menuList: [
    { name: "ROBO Soul", router: "RoboSoul" },
    { name: "ROBO Galaxy", router: "RoboGalaxy" },
    { name: "ROBO Matrix", router: "RoboMatrix" },
    { name: "ROBOVERSE", router: "RoboVerse" },
    { name: "ROBO Cube", router: "RoboNewCreature" },
    { name: "ROBO News", router: "RoboNews" },
  ],
});

const getters = {
  menuList(state) {
    let listCopy = JSON.parse(JSON.stringify(state.menuList));
    // if (rootState.app.locale === "en") {
    //   listCopy[4].name = "ROBO Creature";
    //   // listCopy.pop();
    // }
    return listCopy;
  },
};

const mutations = {
  SET_ACTIVE_MENU(state, activeMenu) {
    state.activeMenu = activeMenu;
  },

  SET_MENU_LIST(state, menuList) {
    state.menuList = menuList;
  },
};

export default { state, getters, mutations };
