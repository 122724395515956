<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1026"
    height="353"
    viewBox="0 0 1026 353"
    fill="none"
  >
    <path
      class="path"
      d="M1026 183.223C807.961 183.223 763.336 266.256 572.41 306.926C419.67 339.462 302.682 230.37 251 183.223C157 97.4697 -154 -145.275 -551 306.926"
      stroke="url(#paint0_linear_2925_339)"
      stroke-width="80"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2925_339"
        x1="1089.68"
        y1="41.0001"
        x2="-637.711"
        y2="139.443"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00FF00" />
        <stop offset="0.67836" stop-color="#00FFC2" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "RoadSvgV3",

  props: {
    width: Number,
    height: Number,
    strokeWidth: Number,
  },

  mounted() {
    const path = document.querySelector(".path");
    let pathLength = path.getTotalLength();
    path.style.strokeDasharray = pathLength;
    path.style.strokeDashoffset = pathLength;
    setTimeout(() => {
      path.style.transition = "stroke-dashoffset 2s linear";
      path.style.strokeDashoffset = 0;
    }, 200);
  },
};
</script>
