<template>
  <section class="overview-section">
    <div
      class="headline"
      data-aos="fade-right"
      data-aos-offset="0"
      data-aos-delay="100"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <div class="blur"></div>

      <div class="title">ROBO Galaxy</div>
    </div>

    <div :class="locale === 'cn' ? 'cn-sub' : 'en-sub'">
      {{ $t("galaxy.section1.subtitle") }}
    </div>

    <div
      class="desc-text"
      data-aos="zoom-in-left"
      data-aos-offset="0"
      data-aos-delay="200"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <h3>@2023</h3>
      <h3>CODE DIFFERENT</h3>
      <h3 class="line-3">TO BOOST THE ROBOTICS ERA</h3>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "GalaxyOverviewMobileSection",

  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/font.css";
@import "~@/assets/css/common.less";
@import "~@/assets/css/image.less";
.overview-section {
  position: relative;
  background-image: url("@{mbOverviewBg}");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  height: 13.34rem;

  .headline {
    position: relative;
    top: 1.4rem;
    left: 1.3rem;
    height: 1.38rem;
    .blur {
      z-index: 999;
      width: 1.38rem;
      height: 1.38rem;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("@{pcBlurImg}");
    }

    .title {
      position: absolute;
      top: 0.5rem;
      left: 0.6rem;
      font-style: normal;
      /* 主色渐变 */

      background: linear-gradient(
        269.44deg,
        @primary-color -4%,
        #00ffc2 102.4%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;

      font-family: "HarmonyOS Sans SC Bold";
      font-weight: 900;
      font-size: 0.5rem;
      font-style: normal;
      line-height: 0.59rem;
    }
  }

  .cn-sub {
    font-family: "HarmonyOS Sans SC Thin";
    font-weight: 400;
    font-size: 0.45rem;
    line-height: 0.53rem;
    letter-spacing: 0.05em;
    background: linear-gradient(269.44deg, @primary-color -4%, #00ffc2 102.4%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    position: relative;
    top: 1.6rem;
    text-align: center;
  }

  .en-sub {
    font-size: 0.4rem;
    font-family: "HarmonyOS Sans SC Thin";
    font-style: normal;
    font-weight: 250;
    line-height: normal;
    background: linear-gradient(269.44deg, @primary-color -4%, #00ffc2 102.4%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    position: relative;
    top: 1.6rem;
    text-align: center;
    width: 7rem;
    margin: 0 auto;
  }

  .desc-text {
    position: absolute;
    top: 5.5rem;
    left: 0.92rem;
    font-family: "HarmonyOS Sans SC";
    font-style: normal;
    font-weight: 400;
    font-size: 0.1rem;
    letter-spacing: 0.01em;
    color: #ffffff;
    line-height: 0.12rem;

    .line-3 {
      margin-top: 1.2rem;
    }
  }
}

// @media only screen and (max-width: 750px) {
//   .overview-section {
//     background-image: url("@{mbOverviewBg}");
//     background-size: contain;
//     background-repeat: no-repeat;
//     background-position: bottom;
//     height: 13.34rem;

//     .headline {
//       position: relative;
//       top: 1.93rem;
//       left: 1.3rem;
//     }

//     .desc-text {
//       position: absolute;
//       top: 5.5rem;
//       left: 0.92rem;

//       .line-3 {
//         margin-top: 1.2rem;
//       }
//     }
//   }
// }
</style>
