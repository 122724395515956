import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueI18n from "vue-i18n";
import AOS from "aos";
import "aos/dist/aos.css";
import messages from "./i18n/message";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

AOS.init();
Vue.use(VueI18n);
Vue.component("v-select", vSelect);

Vue.config.productionTip = false;

// 通过选项创建 VueI18n 实例
const i18n = new VueI18n({
  locale: "cn",
  messages,
});

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
