var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-index"},[_c('div',{staticClass:"img",on:{"click":function($event){return _vm.open('https://mp.weixin.qq.com/s/kKAdKjisplMvUd4kM6adfg')}}},[_c('img',{attrs:{"src":"http://ad4-static.lotuscars.com.cn/webapp/pc/image/news/news_index_left.png","alt":""}}),_c('div',{staticClass:"img-content"},[_vm._v(" "+_vm._s(_vm.$t("news.newsIndexLeftHead1"))),_c('br'),_vm._v(_vm._s(_vm.$t("news.newsIndexLeftHead2"))+" ")])]),_c('div',{staticClass:"trending"},[_vm._m(0),_c('div',{staticClass:"trend-content",on:{"click":function($event){return _vm.open(
          'https://mp.weixin.qq.com/s?__biz=MzkyNDQyNTE3MQ==&mid=2247509939&idx=1&sn=2d0b86adb9e05a8054b2936a78e6bb1e&source=41#wechat_redirect'
        )}}},[_c('img',{staticClass:"poster",attrs:{"src":"http://ad4-static.lotuscars.com.cn/webapp/pc/image/news/news_index_content1.png","alt":""}}),_c('div',{staticClass:"trend-content-head linear-text"},[_vm._v("LOTUS & BIT")]),_c('div',{class:['trend-content-text', { en: _vm.locale === 'en' }]},[_c('span',[_vm._v(_vm._s(_vm.$t("news.newsIndexBIT1"))),_c('br'),_vm._v(_vm._s(_vm.$t("news.newsIndexBIT2")))])]),_c('img',{staticClass:"arrow",attrs:{"src":"http://ad4-static.lotuscars.com.cn/webapp/pc/image/news/arrow_tr.png"}})]),_c('div',{staticClass:"trend-content",on:{"click":function($event){return _vm.open(
          'https://mp.weixin.qq.com/s?__biz=MzA5OTYwMjgyMQ==&mid=2649758216&idx=2&sn=282792d44be2e23d289fe07351a7e2ac&chksm=88fb62e2bf8cebf48db36b111fff84f46ab39e4b5e79942dde0da1dcdf22bf4a2ef5afdfcb69&scene=27'
        )}}},[_c('img',{staticClass:"poster",attrs:{"src":"http://ad4-static.lotuscars.com.cn/webapp/pc/image/news/news_index_content2.png","alt":""}}),_vm._m(1),_c('div',{class:['trend-content-text', { en: _vm.locale === 'en' }]},[_c('span',[_vm._v(_vm._s(_vm.$t("news.newsIndexTJ1"))),_c('br'),_vm._v(_vm._s(_vm.$t("news.newsIndexTJ2")))])]),_c('img',{staticClass:"arrow",attrs:{"src":"http://ad4-static.lotuscars.com.cn/webapp/pc/image/news/arrow_tr.png"}})]),_c('div',{staticClass:"trend-content",on:{"click":function($event){return _vm.open('https://mp.weixin.qq.com/s/cw-KYhLkQKpM8E9DgTN4IQ')}}},[_c('img',{staticClass:"poster",attrs:{"src":"http://ad4-static.lotuscars.com.cn/webapp/pc/image/news/news_index_content3.png","alt":""}}),_c('div',{staticClass:"trend-content-head linear-text"},[_vm._v("LOTUS & BIT")]),_c('div',{class:['trend-content-text', { en: _vm.locale === 'en' }]},[_c('span',[_vm._v(_vm._s(_vm.$t("news.newsIndexBITTJ1"))),_c('br'),_vm._v(_vm._s(_vm.$t("news.newsIndexBITTJ2")))])]),_c('img',{staticClass:"arrow",attrs:{"src":"http://ad4-static.lotuscars.com.cn/webapp/pc/image/news/arrow_tr.png"}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head-line"},[_c('p',{staticClass:"linear-text"},[_vm._v("trending")]),_c('i')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"trend-content-head linear-text"},[_vm._v(" LOTUS & Tongji"),_c('br'),_vm._v("University ")])
}]

export { render, staticRenderFns }