<template>
  <div class="robo-matrix-mobile-value-page">
    <div
      class="bg-group"
      data-aos="fade-up-right"
      data-aos-offset="120"
      data-aos-delay="200"
      data-aos-duration="1200"
      data-aos-once="true"
    >
      <div class="trapezoid"></div>
      <div class="triangle"></div>
      <div class="diamond"></div>
    </div>
    <div class="main">
      <h2
        class="decorator"
        data-aos="fade-up"
        data-aos-offset="120"
        data-aos-delay="100"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        VALUE
      </h2>
      <h1
        class="headline"
        data-aos="fade-up"
        data-aos-offset="120"
        data-aos-delay="200"
        data-aos-duration="1200"
        data-aos-once="true"
      >
        {{ $t("matrix.section2.title") }}
      </h1>

      <div
        class="content-swiper"
        data-aos="fade-left"
        data-aos-offset="120"
        data-aos-delay="100"
        data-aos-duration="1000"
        data-aos-once="true"
        data-aos-easing="ease"
      >
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <h1 class="title" v-show="locale === 'cn'">尊享服务</h1>
            <h2 class="subtitle">FOR THE DRIVERS</h2>
            <div :class="'feature ' + locale">
              <h3>{{ $t("matrix.section2.feature1Title") }}</h3>
              <p>{{ $t("matrix.section2.feature1Content") }}</p>
            </div>
            <div :class="'feature ' + locale">
              <h3>{{ $t("matrix.section2.feature2Title") }}</h3>
              <p>{{ $t("matrix.section2.feature2Content") }}</p>
            </div>
            <div :class="'feature ' + locale">
              <h3>{{ $t("matrix.section2.feature3Title") }}</h3>
              <p>{{ $t("matrix.section2.feature4Content") }}</p>
            </div>
            <div :class="'feature ' + locale">
              <h3>{{ $t("matrix.section2.feature4Title") }}</h3>
              <p>{{ $t("matrix.section2.feature5Content") }}</p>
            </div>
          </div>

          <div class="swiper-slide">
            <h1 class="title" v-if="locale === 'cn'">赋能未来</h1>
            <h2 class="subtitle">FOR THE OEMS</h2>

            <div :class="'feature ' + locale">
              <h3>{{ $t("matrix.section2.feature5Title") }}</h3>
              <p>{{ $t("matrix.section2.feature5Content") }}</p>
            </div>
            <div :class="'feature ' + locale">
              <h3>{{ $t("matrix.section2.feature6Title") }}</h3>
              <p>{{ $t("matrix.section2.feature6Content") }}</p>
            </div>
            <div :class="'feature ' + locale">
              <h3>{{ $t("matrix.section2.feature7Title") }}</h3>
              <p>{{ $t("matrix.section2.feature7Content") }}</p>
            </div>
            <div :class="'feature ' + locale">
              <h3>{{ $t("matrix.section2.feature8Title") }}</h3>
              <p>{{ $t("matrix.section2.feature8Content") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="svg-animation">
      <value-mobile-svg :width="411" :height="249" :stroke-width="8" />
    </div>
  </div>
</template>

<script>
// import { getRootFontSize } from "@/utils/rem";
import Swiper from "swiper";
import "swiper/css/swiper.css";
import ValueMobileSvg from "./ValueMobileSvg.vue";
import { mapState } from "vuex";
export default {
  name: "ValueMobileSection",
  components: {
    ValueMobileSvg,
  },

  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },

  mounted() {
    // const rootFontSize = getRootFontSize(750, 100);
    this.$nextTick(() => {
      new Swiper(".content-swiper", {
        observer: true,
        observeParents: true,
        spaceBetween: 20,
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        fadeEffect: {
          crossFade: true,
        },
      });
    });
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/font.css";
@import "~@/assets/css/common.less";
@import "~@/assets/css/image.less";

.robo-matrix-mobile-value-page {
  background-color: #000;
  height: 9.5rem;
  position: relative;

  .bg-group {
    position: absolute;
    bottom: 0.17rem;
    left: -1.25rem;
    overflow: hidden;
    .trapezoid {
      width: 2.57rem;
      height: 6.15rem;
      background-image: url("@{trapezoidImg}");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .triangle {
      margin-top: -1.28rem;
      width: 2.56rem;
      height: 1.28rem;
      background-image: url("@{triangleImg}");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .diamond {
      margin-top: -0.3rem;
      width: 7.56rem;
      height: 2.06rem;
      background-image: url("@{mbDiamondImg}");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transform: rotate(-3deg) translate(1.2rem, -0.52rem);
    }
  }

  .main {
    margin-left: 1.9rem;
    padding-top: 1.1rem;
    h1,
    h2 {
      margin: 0;
      padding: 0;
    }
    .decorator {
      font-family: "HarmonyOS Sans SC Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 0.5rem;
      line-height: 0.48rem;
      letter-spacing: 0.01em;
      background: linear-gradient(
        98.62deg,
        #364242 3.92%,
        #2b2f37 48.34%,
        #21262b 96.58%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    .headline {
      margin: -0.25rem 0 0 0.48rem;
      padding: 0;
      font-family: "HarmonyOS Sans SC Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 0.34rem;
      line-height: 0.4rem;
      letter-spacing: 0.065em;
      color: @primary-color;
    }

    .content-swiper {
      margin-top: 0.3rem;
      margin-left: 0.48rem;
      width: 4.5rem;
      overflow: hidden;

      .swiper-slide {
        width: 4.5rem;
      }
      .title {
        font-family: "HarmonyOS Sans SC";
        font-style: normal;
        font-weight: 700;
        font-size: 0.24rem;
        line-height: 0.28rem;
        letter-spacing: 0.065em;
        color: #ffffff;
      }
      .subtitle {
        font-family: "HarmonyOS Sans SC";
        font-style: normal;
        font-weight: 900;
        font-size: 0.24rem;
        line-height: 0.28rem;
        letter-spacing: 0.065em;
        text-transform: uppercase;
        background: linear-gradient(
          269.44deg,
          @primary-color -4%,
          #00ffc2 102.4%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin-bottom: 0.25rem;
      }
    }

    .feature {
      color: #898989;
      margin-bottom: 0.2rem;
      font-weight: 900;

      &.en {
        font-family: "HarmonyOS Sans SC Thin";
        font-size: 0.12rem;
        line-height: 0.24rem;
      }

      &.cn {
        font-family: "HarmonyOS Sans SC";
        font-size: 0.18rem;
        line-height: 0.28rem;
      }

      h3 {
        font-family: "HarmonyOS Sans SC Bold";
        margin: 0;
        padding: 0;
      }
      p {
        font-family: "HarmonyOS Sans SC";
        font-weight: lighter;
        opacity: 0.8;
        margin: 0;
        padding: 0;
      }
    }
  }

  .svg-animation {
    position: absolute;
    top: 1.1rem;
    left: 0;
    transform: scale(0.5) translate(-50%, -75%);
  }
}
</style>
