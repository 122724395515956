export default {
  newsIndexLeftHead1: "The most expensive is 1.03 million! Shufu Li 's luxury",
  newsIndexLeftHead2: "smart ceiling is on sale: 4 lidars, 34 sensors",
  newsIndexBIT1: "Lotus sponsors Beijing Institute of",
  newsIndexBIT2: "Technology Driverless Formula Team",
  newsIndexTJ1:
    "Teaming up with Tongji Lotus Wing Chi Racing Team, Lotus creates a new benchmark for industry-university-research collaborative innovation",
  newsIndexTJ2: "",
  newsIndexBITTJ1:
    "Lotus teamed up with BIT and Tongji to help the racing dream take off",
  newsIndexBITTJ2: "",
  newsVideo1:
    "2022 Alibaba Cloud Partner Conference, the industrial innovation series was released, and Qingfeng Feng, CEO of Lotus Group, delivered a speech",
  newsVideo2:
    "MEET 2023 Intelligent Future Conference, Lotus Robot Bo Li:The intelligent vehicle represents the first form of a robot",
  newsVideo3: "Yunka is here | Lotus Robotics lands on Amazon Web Services",
};
