export default {
  soulTitle: "ROBO Soul",
  subHead: "Intelligent driving software solution",
  algorithmic: "Algorithm Models",
  developmentTitle1: "DEVELOPMENT PROCESS FOR THE ROBOTICS ERA",
  developmentTitle2: "",
  developmentSubTitle1: "Self-developed hybrid software development framework",
  developmentSubTitle2: "/agile development + traditional V-shape",
  schemaTitle: "Intelligent Driving Solutions for the Robotics Era",
  schemaHead1: "Highway Pilot",
  schemaSubHead1: "",
  schemaHead2: "Urban Pilot",
  schemaSubHead2: "",
  schemaHead3: "Parking Pilot",
  schemaSubHead3: "",
  schemaHead4: "Driving Assistance",
  schemaSubHead4: "",
  schemaHead5: "Parking Assistance",
  schemaSubHead5: "",
  schemaHead6: "Active Safety",
  schemaSubHead6: "",
  cmiMpiTitle: "Redefine Intelligent Driving Grading with",
  cmiMpiSubTitle: '"CMI" and "MPI"',
  evaluation: "Evaluation System/",
  functionalCoverageCn: "",
  functionalCoverageEn: "Functional Coverage",
  vcmi: "VCMI/",
  validCoverageCn: "",
  validCoverageEn: "Valid Mileage of Coverage",
  delivery: "Scope of Delivery",
  roadType: "Road Type/Country or City",
  evaluationSystem: "Evaluation System",
  userCoverage: "UserStory Demand Coverage",
  mpci: "",
  mpri: "",
  mpsi: "",
  mpei: "",
  userExperience: "User Experience",
  userExperienceContent: "Safety, Regulation, Intelligence, Comfort",
  rdModule: "R&D Module",
  rdModuleContent1: "World model， Visual perception， Map engine， Map data，",
  rdModuleContent2:
    "Positioning，Perceptual fusion，Calibration module，Horizontal",
  rdModuleContent3: "planning，Vertical planning，Control，Forecasting，",
  rdModuleContent4: "HMI，System hardware，Others",
  deliveryScope: "Scope of Delivery",
  deliveryScopeContent: "Road Type/Country or City",
};
