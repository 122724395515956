<template>
  <div class="robo-galaxy-page">
    <overview-section v-if="!isMobile" />
    <overview-mobile-section v-else />
    <introduce-section />
    <structure-section v-if="!isMobile" />
    <structure-mobile v-else />
    <data-manage-section />
    <data-collection-section />
    <data-annotation-section />
    <data-traning-section />
    <data-simulation-section />
    <cooperation-section />
    <video-section />
  </div>
</template>

<script>
import OverviewSection from "./components/OverviewSection.vue";
import OverviewMobileSection from "./components/OverviewMobileSection.vue";
import IntroduceSection from "./components/IntroduceSection.vue";
import StructureSection from "./components/StructureSection.vue";
import DataManageSection from "./components/DataManageSection.vue";
import DataCollectionSection from "./components/DataCollectionSection.vue";
import DataAnnotationSection from "./components/DataAnnotationSection.vue";
import DataTraningSection from "./components/DataTraningSection.vue";
import DataSimulationSection from "./components/DataSimulationSection.vue";
import CooperationSection from "./components/CooperationSection.vue";
import VideoSection from "./components/VideoSection.vue";
import StructureMobile from "./components/StructureMobile.vue";
// import { isMobile } from "@/utils/isMobile";
import { mapState } from "vuex";

export default {
  name: "RoboGalaxy",
  components: {
    OverviewSection,
    OverviewMobileSection,
    IntroduceSection,
    StructureSection,
    DataManageSection,
    DataCollectionSection,
    CooperationSection,
    DataAnnotationSection,
    DataTraningSection,
    DataSimulationSection,
    VideoSection,
    StructureMobile,
  },
  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
      isMobile: (state) => state.isMobile,
    }),
  },
};
</script>

<style lang="less" scoped>
.robo-galaxy-page {
  background-color: #000;
  overflow: hidden;
}
</style>
