<template>
  <div class="data-annotation-section">
    <div class="headline">
      <h1
        class="title"
        data-aos="fade-up"
        data-aos-offset="0"
        data-aos-delay="100"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        {{ $t("galaxy.section6.title") }}
      </h1>
      <h2
        class="sub-title"
        data-aos="fade-up"
        data-aos-offset="0"
        data-aos-delay="200"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        {{ $t("galaxy.section6.subtitle") }}
      </h2>
    </div>
    <div
      class="block"
      data-aos="fade-left"
      data-aos-offset="0"
      data-aos-delay="300"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <div class="pic"></div>
      <div class="content">
        <div class="mark"></div>
        <div>
          <div class="title">{{ $t("galaxy.section6.title1") }}</div>
          <div class="subtitle">{{ $t("galaxy.section6.subtitle1") }}</div>
          <div :class="'text ' + locale">
            {{ $t("galaxy.section6.content") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "DataAnnotationSection",

  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/galaxy.less";
@import "~@/assets/css/common.less";
@import "~@/assets/css/image.less";
.data-annotation-section {
  height: 7.2rem;
  background-color: #000000;

  .block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .pic {
      width: 5.06rem;
      height: 5.06rem;
      background-image: url("@{dataAnnotationImg}");
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 0.4rem;
    }

    .content {
      display: flex;
      font-size: 0;
      .mark {
        width: 0.08rem;
        height: 0.26rem;
        background: linear-gradient(
          269.44deg,
          @primary-color -4%,
          #00ffc2 102.4%
        );
        border-radius: 0.2rem;
        margin-right: 0.18rem;
      }

      .title {
        width: 5.16rem;
        font-family: "HarmonyOS Sans SC";
        font-style: normal;
        font-weight: 400;
        font-size: 0.28rem;
        line-height: 0.28rem;
        letter-spacing: 0.01em;
        color: #ffffff;
      }
      .subtitle {
        width: 4.74rem;
        font-family: "HarmonyOS Sans SC";
        font-style: normal;
        font-weight: 400;
        font-size: 0.25rem;
        line-height: 0.32rem;
        letter-spacing: 0.01em;
        color: #ffffff;
        margin-top: 0.3rem;
      }
      .text {
        width: 4.22rem;
        font-family: "HarmonyOS Sans SC";
        font-style: normal;
        font-weight: 400;
        font-size: 0.18rem;
        line-height: 0.24rem;
        color: #646368;
        margin-top: 0.22rem;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .data-annotation-section {
    height: 10.8rem;
    .headline .sub-title {
      width: 5.46rem;
      margin: 0.18rem auto auto auto;
    }
    .block {
      position: relative;
      display: block;
      height: 7.75rem;
      margin: 0.64rem 0 0 0.9rem;

      .pic {
        position: absolute;
        bottom: 0;
        right: 0.5rem;
      }

      .content {
        .title {
          width: 5.8rem;
        }
        .text {
          width: 5.35rem;

          &.cn {
            line-height: 0.28rem;
          }
        }
      }
    }
  }
}
</style>
