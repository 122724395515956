<template>
  <div class="robo-news">
    <OverviewSection />
    <NewsIndex />
    <NewsMain />
  </div>
</template>

<script>
import { mapState } from "vuex";
import OverviewSection from "./components/OverviewSection.vue";
import NewsIndex from "./components/NewsIndex.vue";
import NewsMain from "./components/NewsMain.vue";
export default {
  name: "RoboNews",
  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },
  components: {
    OverviewSection,
    NewsIndex,
    NewsMain,
  },
};
</script>

<style lang="less" scoped>
.robo-news {
  width: 100%;
}
</style>
