export default {
  section1: {
    subtitle: "智能驾驶工具链解决方案",
  },
  section2: {
    title: "智能驾驶云端数据工厂",
    content1:
      "汽车已从机械化逐渐过渡到电动化、 智能化。 作为汽车新物种， 智能驾驶汽车的研发、 测试、 商业化落地都会产生海量数据， 这些过程也需要庞大的数据体系进行支撑。",
    content2:
      "如何进行高效的数据管理和数据运营则成为了智能驾驶公司亟需解决的问题。要进一步提高智能驾驶计算算力和数字化运营能力， 必须要“上云”。",
    content3:
      "“上云”不仅能弥补目前众多智能驾驶公司存储和处理数据的能力缺口，也能为数据采集、数据合规、数据管理、模型训练和数据仿真等全方位赋能。",
    content4: "ROBO Galaxy的出现顺应了智能汽车产业发展需求。",
  },

  section3: {
    centerTitle: "统一数据管理",
  },

  section4: {
    title: "数据管理｜可视可控，更高效",
    subtitle: "Visible, controllable and more efficient data management",
    title1: "数据可视，全量信息多类型回放",
    content1:
      "Lviz平台提供全量数据回放，多类型数据自由显示切换、 多种图表及数据流可独立展示，可视化呈现感知-规划- 控制全过程",
    title2: "终端同步，自由搭建信息大屏",
    content2:
      "提供自定义BI报表与自由搭建可视化大屏能力，可 根据需要呈现包括车队实时信息、全国里程覆盖等 内容.",
  },

  section5: {
    title: "数据采集｜高安全、高性能、高可靠",
    subtitle:
      "Data Acquisition ｜High Security, High Performance, and High Reliability",
    title1: "高性能",
    subtitle1: "PB级数据存储 ｜ 百万级请求并发 ｜ 弹性扩容",
    content1:
      "支持数据大规模，高精度，多维度，高质量存储 可无限扩张，支持百万级请求并发",
    title2: "高安全",
    subtitle2: "合规脱敏 ｜ 保密机房安全隔离 ｜ 隐私安全",
    content2:
      "可对隐私信息进行全量或局部脱敏，公有云、私有云、 保密机房协同工作，确保数据安全性",
    title3: "高可靠",
    subtitle3: "同城双中心灾备 ｜ 负载均衡 ｜ 故障自动切换",
    content3:
      "数据安全备份，互联互通，单个数据中心发生故障或灾难， 另外数据中心可正常运行，有效保证数据可靠性",
  },

  section6: {
    title: "数据标注｜智能高效、自动标注协助人工标注",
    subtitle:
      "Intelligent, efficient and automatic annotation to assist manual annotation",
    title1: "精准识别，支持多类型障碍物自标注",
    subtitle1: "多机构隔离｜高效并发｜高兼容性",
    content:
      "支持图片、点云等多种数据类型自动标注， 同时提供联合标注、语义分割、多边形标注等多种人工标注，实现闭环式数据标注",
  },

  section7: {
    title: "数据训练｜快速部署，一站式模型训练服务",
    subtitle:
      "Data training｜Rapid deployment, one-stop model training service",
    title1: "Argoverse数据集中三项指标两项第一",
    content1:
      "通过此训练平台，智驾预测算法 在国际知名算法比赛Argoverse数据集中 获得三项指标两项第一，一项第二 ，预测轨迹与真实轨迹的偏差从1.96M 降到 1.55M",
    title2: "多类型信息统一维护 灵活、稳定、易用、高效的学习训练环境",
    content2:
      "根据模型训练需要，动态分配计算资源，加速模型推演过程 对训练后的模型版本、训练算法、训练数据、 机器资源等信息进行统一维护",
  },

  section8: {
    title: "数据仿真｜Worldsim Logsim，虚实结合，高效并行",
    subtitle:
      "Data simulation｜Worldsim Logsim, virtual and real combination, efficient parallelism",
    title1: "WorldSim全虚拟仿真，海量测试场景与泛化 有效提高测试覆盖度",
    content1:
      "可支持100+VTD云端并发，日行3万公里以上； 真正量产E2E算法的云端仿真实践； 实现open DRIVE虚拟地图接入规控算法，可与车端使用高精度地图解绑",
    title2: "Logsim全链路数据仿真与多维度结果 评价， 支持全部路测数据回归测试",
    content2:
      "提供多模块仿真，如感知、定位、预测、规控、风险等模型； 支持千级任务并发，可扩展至万级； 精准定位问题，与道路采集测试数据库打通",
  },
  section9: {
    title: "与阿里云“聚力”，  打造智能驾驶云端数据工厂",
    content:
      "围绕数据闭环，优先打造ROBO Galaxy， 致力于建设全数据链的智能驾驶云服务产品体系，助力行业创造新的生产力！",
  },
};
