<template>
  <section class="roboverse-fourth-section">
    <div
      class="headline"
      data-aos="fade-right"
      data-aos-easing="ease-out-cubic"
      data-aos-offset="0"
      data-aos-delay="100"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <h1 class="bg-text">VALUE</h1>
      <h2 class="title">
        <span :class="'text1 ' + locale">{{
          $t("verse.section4.title1")
        }}</span>
        <span class="text2" v-if="locale === 'cn'">能为参赛者提供什么？</span>
      </h2>
    </div>

    <div
      class="providers"
      data-aos="zoom-in"
      data-aos-easing="ease-out-cubic"
      data-aos-offset="0"
      data-aos-delay="100"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <div
        class="item-wrapper"
        v-for="(item, index) in $t('verse.section4.list')"
        :key="index"
      >
        <div class="line"></div>
        <div class="bg">
          <img class="icon" :src="item.icon" />
        </div>
        <div :class="'title ' + locale">{{ item.title }}</div>
        <div :class="'content ' + locale">{{ item.content }}</div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "RoboverseFourthSection",

  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/font.css";
@import "~@/assets/css/image.less";
.roboverse-fourth-section {
  height: calc(100vw / 1280 * 700);
  background-image: url("@{fourthBg}");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .headline {
    padding-top: 1.31rem;
    margin-left: 1.49rem;
    .bg-text {
      padding: 0;
      margin: 0;
      font-family: "HarmonyOS Sans SC Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 0.5rem;
      line-height: 0.48rem;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.15);
    }

    .title {
      padding: 0;
      margin: 0;
      font-family: "HarmonyOS Sans SC";
      font-style: normal;
      font-weight: 900;
      font-size: 0.28rem;
      line-height: 0.33rem;
      letter-spacing: 0.065em;
      margin: -0.23rem 0 0 0.45rem;
      .text1 {
        color: #00ff00;
        margin-right: 0.2rem;
        &.en {
          font-family: "HarmonyOS Sans SC Bold";
          font-size: 0.32rem;
          line-height: 0.38rem;
          letter-spacing: 0.04em;
          background: linear-gradient(269.44deg, #00ff00 -4%, #00ffc2 102.4%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
      }
      .text2 {
        color: #fff;
      }
    }
  }

  .providers {
    width: fit-content;
    margin: 1.1rem auto 0 auto;
    display: flex;

    .item-wrapper {
      margin-right: 0.4rem;
      width: 2.1rem;
      cursor: pointer;
      transition: transform 300ms ease;
      &:hover {
        transform: scale(1.08);
      }
      .line {
        height: 0.03rem;
        background: linear-gradient(269.44deg, #00ff00 -4%, #00ffc2 102.4%);
      }
      .bg {
        position: relative;
        height: 1.1rem;
        background: linear-gradient(
          170.79deg,
          rgba(0, 255, 0, 0.18) 8.53%,
          rgba(217, 217, 217, 0) 67.58%
        );

        .icon {
          position: absolute;
          left: 0.15rem;
          bottom: 0.4rem;
          width: 0.28rem;
          height: 0.28rem;
        }
      }
    }

    .title {
      margin: -0.15rem 0 0.25rem 0.15rem;
      font-family: "HarmonyOS Sans SC";
      font-style: normal;
      font-weight: 700;
      font-size: 0.18rem;
      line-height: 0.21rem;
      letter-spacing: 0.065em;
      color: #ffffff;
      &.en {
        margin: -0.15rem 0 0.1rem 0.15rem;
      }
    }

    .content {
      padding-left: 0.15rem;
      font-family: "HarmonyOS Sans SC Thin";
      font-style: normal;
      font-weight: 400;
      font-size: 0.12rem;
      line-height: 0.18rem;
      letter-spacing: 0.065em;
      color: #cbcbcb;
      &.en {
        line-height: 0.15rem;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .roboverse-fourth-section {
    height: 11.2rem;
    background-image: url("@{mbFourthBg}");

    .headline {
      padding-top: 0.8rem;
      margin-left: 0.71rem;
    }

    .providers {
      width: 5rem;
      margin-top: 0.5rem;
      flex-wrap: wrap;
      justify-content: space-between;

      .item-wrapper {
        margin: 0 0 0.4rem 0;
        width: 2.3rem;
      }

      .title.en {
        margin: -0.15rem 0 0.15rem 0.15rem;
      }

      .content {
        &.cn {
          line-height: 0.28rem;
        }
        &.en {
          line-height: 0.21rem;
          word-break: break-all;
        }
      }
    }
  }
}
</style>
