<template>
  <div class="robo-galaxy-video-section">
    <video
      class="video"
      muted
      autoplay
      playsinline
      x-webkit-playsinline
      x5-playsinline
      loop
      src="http://ad4-static.lotuscars.com.cn/webapp/pc/video/galaxy/robo_galaxy.mp4"
      type="video/mp4"
    />
  </div>
  <!-- <div class="robo-galaxy-video-section" v-else>
    <img src="~@/assets/img/mobile/galaxy_mp4.gif" />
  </div> -->
</template>

<script>
export default {
  name: "VideoSection",

  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    this.isMobile = this.inMobile();
  },
  methods: {
    inMobile() {
      var userAgentInfo = navigator.userAgent;
      var Agents = new Array(
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPod"
      );
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return !flag;
    },
  },
};
</script>

<style lang="less" scoped>
.robo-galaxy-video-section {
  .video {
    width: 100%;
  }
}
</style>
