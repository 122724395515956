<template>
  <div class="navigation-bar">
    <ul class="wrapper">
      <li
        :class="activeMenu === item.router ? 'item active' : 'item'"
        v-for="(item, index) in menuList"
        :key="index"
        @click="tabClick(index)"
      >
        {{ item.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "NavigationBar",

  computed: {
    ...mapState("header", {
      activeMenu: (state) => state.activeMenu,
    }),
    ...mapGetters("header", {
      menuList: "menuList",
    }),
  },

  methods: {
    tabClick(index) {
      if (this.menuList[index].router !== this.activeMenu) {
        this.$router.push({ name: this.menuList[index].router });
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/font.css";
@import "~@/assets/css/common.less";
@import "~@/assets/css/header.less";
.navigation-bar {
  display: inline-block;
  ul.wrapper {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;

    li.item {
      position: relative;
      height: @header-height;
      line-height: @header-height;
      font-family: "HarmonyOS Sans SC";
      font-style: normal;
      font-weight: 700;
      font-size: 0.14rem;
      letter-spacing: 0.01em;
      margin-left: 0.4rem;
      color: @primary-color;
      padding: 0 0.15rem;
      user-select: none;
      &:hover {
        color: #d0ff00;
        font-weight: bold;
      }
      .menu-active-animation();
    }
  }
}
</style>
