import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../views/Layout/Index.vue";
import Home from "../views/Home/Index.vue";
import RoboSoul from "../views/RoboSoul/Index.vue";
import RoboGalaxy from "../views/RoboGalaxy/Index.vue";
import RoboMatrix from "../views/RoboMatrix/Index.vue";
import RoboVerse from "../views/RoboVerse/Index.vue";
import RoboNewCreature from "../views/RoboNewCreature/Index.vue";
import RoboNews from "../views/RoboNews/Index.vue";
import store from "@/store";

Vue.use(VueRouter);

// 重复路由报错
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "Layout",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "home",
        name: "Home",
        component: Home,
      },
      {
        path: "robosoul",
        name: "RoboSoul",
        component: RoboSoul,
      },
      {
        path: "robogalaxy",
        name: "RoboGalaxy",
        component: RoboGalaxy,
      },
      {
        path: "robomatrix",
        name: "RoboMatrix",
        component: RoboMatrix,
      },
      {
        path: "roboverse",
        name: "RoboVerse",
        component: RoboVerse,
      },
      {
        path: "robocube",
        name: "RoboNewCreature",
        component: RoboNewCreature,
      },
      {
        path: "robonews",
        name: "RoboNews",
        component: RoboNews,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.afterEach((to) => {
  const { name } = to;
  store.commit("header/SET_ACTIVE_MENU", name);
});

export default router;
