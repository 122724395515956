<template>
  <div class="data-traning-section">
    <div class="headline">
      <!-- <h1 class="title">{{ $t("galaxy.section7.title") }}</h1>
      <h2 class="sub-title">{{ $t("galaxy.section7.subtitle") }}</h2> -->
      <h1
        class="title"
        data-aos="fade-up"
        data-aos-offset="0"
        data-aos-delay="100"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        {{ $t("galaxy.section7.title") }}
      </h1>
      <h2
        class="sub-title"
        data-aos="fade-up"
        data-aos-offset="0"
        data-aos-delay="200"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        {{ $t("galaxy.section7.subtitle") }}
      </h2>
    </div>

    <div
      class="data-traning-swiper"
      data-aos="fade-right"
      data-aos-offset="0"
      data-aos-delay="300"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <div class="swiper-wrapper">
        <div class="block swiper-slide">
          <div class="content">
            <div class="mark"></div>
            <div>
              <div class="title">
                {{ $t("galaxy.section7.title1") }}
              </div>
              <div :class="'text ' + locale">
                {{ $t("galaxy.section7.content1") }}
              </div>
            </div>
          </div>
          <div class="pic1">
            <div class="rhombus1"></div>
            <div class="rhombus2"></div>
          </div>
        </div>
        <div class="block swiper-slide second">
          <div class="content">
            <div class="mark"></div>
            <div>
              <div class="title">
                {{ $t("galaxy.section7.title2") }}
              </div>
              <div :class="'text ' + locale">
                {{ $t("galaxy.section7.content2") }}
              </div>
            </div>
          </div>
          <div class="pic2"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.css";
import { mapState } from "vuex";
export default {
  name: "DataTraningSection",
  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },

  mounted() {
    new Swiper(".data-traning-swiper", {
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
      },
    });
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/galaxy.less";
@import "~@/assets/css/common.less";
@import "~@/assets/css/image.less";
.data-traning-section {
  height: 7.2rem;
  background-color: #131313;

  .block {
    display: flex;
    align-items: center;
    justify-content: center;
    &.second {
      margin-top: -0.5rem;
    }
    width: 100%;
    .pic1 {
      position: relative;
      width: 4.37rem;
      height: 4.37rem;
      background-image: url("@{dataTrainingImg1}");
      background-repeat: no-repeat;
      background-size: contain;
      margin-left: 0.9rem;
    }

    .pic2 {
      width: 6rem;
      height: 6rem;
      background-image: url("@{dataTrainingImg2}");
      background-repeat: no-repeat;
      background-size: contain;
      margin-top: -0.5rem;
    }

    .rhombus1 {
      position: absolute;
      width: 4.18rem;
      height: 1.98rem;
      background-image: url("@{dataCollectionRhombus1}");
      background-repeat: no-repeat;
      background-size: contain;
      bottom: 0.39rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }
    .rhombus2 {
      position: absolute;
      width: 5.79rem;
      height: 2.97rem;
      background-image: url("@{dataCollectionRhombus2}");
      background-repeat: no-repeat;
      background-size: contain;
      bottom: -0.2rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }

    .content {
      display: flex;
      font-size: 0;
      .mark {
        width: 0.08rem;
        height: 0.26rem;
        background: linear-gradient(
          269.44deg,
          @primary-color -4%,
          #00ffc2 102.4%
        );
        border-radius: 0.2rem;
        margin-right: 0.18rem;
      }

      .title {
        width: 4.22rem;
        font-family: "HarmonyOS Sans SC";
        font-style: normal;
        font-weight: 400;
        font-size: 0.28rem;
        line-height: 0.28rem;
        letter-spacing: 0.01em;
        color: #ffffff;
      }
      .text {
        width: 4.22rem;
        font-family: "HarmonyOS Sans SC";
        font-style: normal;
        font-weight: 400;
        font-size: 0.18rem;
        line-height: 0.24rem;
        color: #646368;
        margin-top: 0.3rem;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .data-traning-section {
    height: 10.8rem;

    .block {
      display: block;
      margin: 0.85rem 0 0 0;
      overflow: hidden;

      &.second {
        margin-top: 0.85rem;
      }

      .content {
        margin-left: 0.95rem;
        .text {
          width: 5rem;

          &.cn {
            line-height: 0.28rem;
          }
        }
      }

      .pic1 {
        float: right;
      }

      .pic2 {
        float: right;
        width: 5.05rem;
        height: 3.79rem;
        background-image: url("@{mbDataTrainingImg2}");
        margin: 0.55rem 0.48rem 0 0;
      }
    }
  }
}
</style>
