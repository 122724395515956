<template>
  <div class="algorithmic-section" ref="algorithmic_section_el">
    <div
      class="head-line"
      data-aos="fade-up"
      data-aos-offset="120"
      data-aos-delay="100"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <p :class="['head-text', 'number', { en: locale === 'en' }]">3</p>
      <p class="head-text">{{ $t("soul.algorithmic") }}</p>
    </div>
    <div class="robo">
      <img
        src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/soul/robo.png"
        alt=""
      />
    </div>
    <div class="video-container">
      <div
        class="video-container-inner left"
        data-aos="fade-up"
        data-aos-offset="120"
        data-aos-delay="100"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        <div class="video-img game_model" @click="() => videoPlay(1)">
          <img
            class="play-icon"
            src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/soul/play_icon.png"
            alt=""
          />
        </div>
        <p>GAME MODEL</p>
        <div class="model-desc" v-if="locale === 'cn'">
          全链条风险识别预测｜计算化智能｜消灭主动接管
        </div>
      </div>
      <div
        class="video-container-inner center"
        data-aos="fade-up"
        data-aos-offset="120"
        data-aos-delay="200"
        data-aos-duration="1200"
        data-aos-once="true"
      >
        <div class="video-img risk_model" @click="() => videoPlay(2)">
          <img
            class="play-icon"
            src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/soul/play_icon.png"
            alt=""
          />
        </div>
        <p>RISK MODEL</p>
        <div class="model-desc" v-if="locale === 'cn'">
          数据驱动认知｜算力换智能｜预期推演博弈共赢
        </div>
      </div>
      <div
        class="video-container-inner right"
        data-aos="fade-up"
        data-aos-offset="120"
        data-aos-delay="300"
        data-aos-duration="1400"
        data-aos-once="true"
      >
        <div class="video-img u_model" @click="() => videoPlay(3)">
          <img
            class="play-icon"
            src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/soul/play_icon.png"
            alt=""
          />
        </div>
        <p>U MODEL</p>
        <div class="model-desc" v-if="locale === 'cn'">
          效率+舒适+智能的融合，打造“智驾合一”
        </div>
      </div>
    </div>
    <div class="video" v-show="videoShowStatus">
      <div class="video-content">
        <img
          class="close"
          src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/soul/close.png"
          @click="exitFullScreen"
          alt=""
        />
        <video
          :src="videoList[videoId]"
          preload="true"
          autoplay
          playsinline
          x-webkit-playsinline
          x5-playsinline
          x5-video-player-type="h5"
          x5-video-player-fullscreen
          :poster="videoPosterList[videoId]"
          ref="video"
          controls
        ></video>
      </div>
    </div>
  </div>
</template>

<script>
// risk_model_video
import { mapState } from "vuex";

export default {
  name: "AlgorithmicSection",
  data() {
    return {
      video: {},
      videoShowStatus: false,
      videoList: {
        1: "http://ad4-static.lotuscars.com.cn/webapp/pc/video/soul/game_model.mp4",
        2: "http://ad4-static.lotuscars.com.cn/webapp/pc/video/soul/risk_model.mp4",
        3: "http://ad4-static.lotuscars.com.cn/webapp/pc/video/soul/u_model.mp4",
      },
      videoPosterList: {
        1: "http://ad4-static.lotuscars.com.cn/webapp/pc/image/soul/game_model.png",
        2: "http://ad4-static.lotuscars.com.cn/webapp/pc/image/soul/risk_model.png",
        3: "http://ad4-static.lotuscars.com.cn/webapp/pc/image/soul/u_model.png",
      },
      videoId: 1,
    };
  },
  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },
  methods: {
    videoPlay(id) {
      // const el = this.$refs.algorithmic_section_el;
      // el.scrollIntoView()
      const documentEl = document.documentElement;
      documentEl.scrollTop = this.$refs.algorithmic_section_el.offsetTop - 50;
      this.videoId = id;

      this.videoShowStatus = true;
      this.$refs.video.currentTime = 0;
      let promise = this.$refs.video.play();
      promise.catch((err) => console.log(err));
      document.addEventListener("keydown", this.exitFullScreen);
    },
    exitFullScreen(e) {
      if (e.key === "Escape" || e.target.className === "close") {
        const documentEl = document.documentElement;
        documentEl.scrollTop = this.$refs.algorithmic_section_el.offsetTop - 50;
        // this.$refs.video.pause();
        this.videoShowStatus = false;
        document.removeEventListener("keydown", this.exitFullScreen);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/common.less";
@import "~@/assets/css/image.less";
.algorithmic-section {
  background: #36353e;
  background-image: url("@{algorithmicModuleBg}");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  // height: calc(100vh - 0.5rem);
  height: 7.21rem;
  position: relative;
  overflow: hidden;

  .head-line {
    width: 100%;
    text-align: center;
    padding-top: 0.28rem;
    p {
      padding: 0;
      margin: 0;
    }
    .head-text {
      font-family: "HarmonyOS Sans SC";
      font-size: 0.42rem;
      font-weight: 400;
      letter-spacing: 0.05em;
      background: linear-gradient(
        269.44deg,
        @primary-color -4%,
        #00ffc2 102.4%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      display: inline-block;
      text-transform: uppercase;
      &.number {
        font-size: 0.8rem;
        &.en {
          font-size: 0.42rem;
          margin-right: 0.1rem;
        }
      }
    }
  }

  .robo {
    width: 100%;
    text-align: center;
    margin-top: 0.56rem;
    img {
      width: 100%;
    }
  }

  .video-container {
    width: 100%;
    position: absolute;
    top: 3.72rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .video-container-inner {
      display: flex;
      flex-direction: column;
      p {
        width: fit-content;
        margin: auto;
        margin-top: 0.23rem;
        font-family: "HarmonyOS Sans SC Black";
        font-weight: 900;
        font-size: 0.2rem;
        line-height: 0.22rem;
        text-transform: uppercase;
        background: linear-gradient(
          269.44deg,
          @primary-color -4%,
          #00ffc2 102.4%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
      &.left,
      &.center {
        margin-right: 0.36rem;
      }
      .model-desc {
        text-align: center;
        font-size: 0.12rem;
        margin-top: 0.06rem;
        color: #999;
      }
    }
    .video-img {
      width: 2.93rem;
      height: 1.81rem;
      background-color: transparent;
      border-radius: 0.2rem;
      display: inline-block;
      overflow: hidden;
      filter: grayscale(0.5);
      &:hover {
        filter: grayscale(0);
      }
      &.game_model {
        background: url("@{gameModel}");
      }
      &.risk_model {
        background: url("@{riskModel}");
      }
      &.u_model {
        background: url("@{uModel}");
      }
    }
    .play-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      &:hover {
        transform: translate(-50%, -50%) scale(1.2);
      }
    }
  }

  .video {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    .video-content {
      width: 100%;
      // height: 100vh;
      position: absolute;
      top: 0;
      .close {
        position: absolute;
        width: 0.4rem;
        top: 4%;
        right: 4%;
        z-index: 2;
      }
      video {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .algorithmic-section {
    background-image: url("@{mobileAlgorithmicModuleBg}");
    height: 14.13rem;
    width: 100vw;
    .head-line {
      padding-top: 1.63rem;
      p {
        padding: 0;
        margin: 0;
      }
      .head-text {
        font-size: 0.42rem;
        text-transform: capitalize;
        &.number {
          font-size: 0.8rem;
          &.en {
            font-size: 0.8rem;
            font-family: "HarmonyOS Sans SC Thin";
            margin-right: 0.05rem;
          }
        }
      }
    }

    .robo {
      margin-top: 0.9rem;
    }
    .video-container {
      top: 5.2rem;
      flex-direction: column;

      .video-container-inner {
        width: 5.53rem;
        // max-width: 553px;
        height: 1.9rem;
        flex-direction: column;
        margin-bottom: 0.45rem;
        p {
          margin-top: 0.22rem;
          font-size: 0.24rem;
          line-height: 1.2;
        }
        &.left,
        &.center {
          margin-right: 0;
        }
      }
      .video-img {
        width: 100%;
        height: 100%;
        border-radius: 0.2rem;
        position: relative;
        img {
          width: 100%;
        }
        .play-icon {
          width: 0.5rem;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        &.game_model {
          background: url("@{mobileGameModel}");
          background-size: cover;
        }
        &.risk_model {
          background: url("@{mobileRiskModel}");
          background-size: cover;
        }
        &.u_model {
          background: url("@{mobileUModel}");
          background-size: cover;
        }
      }
    }
  }
}
</style>
