<template>
  <div class="robo-matrix-overview-page">
    <div
      class="headline"
      data-aos="fade-right"
      data-aos-offset="0"
      data-aos-delay="100"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <div class="blur"></div>
      <div class="title">
        <div class="en">ROBO Matrix</div>
        <div :class="locale === 'cn' ? 'cn-sub' : 'en-sub'">
          {{ $t("matrix.section1.subtitle") }}
        </div>
      </div>
    </div>

    <div
      class="desc-text top"
      data-aos="zoom-in-down"
      data-aos-offset="0"
      data-aos-delay="100"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <h3>@2023</h3>
      <h3>CODE DIFFERENT</h3>
    </div>

    <div
      class="desc-text bottom"
      data-aos="zoom-in-right"
      data-aos-offset="0"
      data-aos-delay="200"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <h3>TO BOOST THE ROBOTICS ERA</h3>
    </div>

    <div class="car-drive-wrapper">
      <div class="animation-container">
        <div class="road-svg">
          <road-svg-v3 />
        </div>
        <div class="position">
          <span class="outer-circle">
            <span class="inner-circle"></span>
          </span>
          <img
            class="car"
            src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/matrix/car_new.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import RoadSvg from "./RoadSvg.vue";
import RoadSvgV3 from "./RoadSvgV3.vue";
import { mapState } from "vuex";
export default {
  name: "MatrixOverviewSectionV2",
  components: {
    // RoadSvg,
    RoadSvgV3,
  },

  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/font.css";
@import "~@/assets/css/common.less";
@import "~@/assets/css/image.less";

.robo-matrix-overview-page {
  position: relative;
  height: calc(100vh - 0.5rem);
  min-height: 7.2rem;
  background: linear-gradient(
    118.48deg,
    #333a55 -25.09%,
    rgba(43, 45, 64, 0.526042) 37.81%,
    rgba(68, 68, 68, 0) 65.04%
  );

  .headline {
    position: relative;
    top: 1.01rem;
    left: 2rem;
    height: 1.38rem;
    .blur {
      position: absolute;
      z-index: 999;
      width: 1.38rem;
      height: 1.38rem;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("@{pcBlurImg}");
    }

    .title {
      position: absolute;
      top: 0.5rem;
      left: 0.54rem;
      font-style: normal;
      background: linear-gradient(
        269.44deg,
        @primary-color -4%,
        #00ffc2 102.4%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      z-index: 99999;

      .en {
        font-family: "HarmonyOS Sans SC Bold";
        font-weight: 900;
        font-size: 0.64rem;
        line-height: 0.75rem;
      }

      .en-sub {
        font-family: "HarmonyOS Sans SC Thin";
        font-size: 0.42rem;
        font-style: normal;
        font-weight: 250;
        margin-top: 0.08rem;
      }

      .cn-sub {
        font-family: "HarmonyOS Sans SC Thin";
        font-weight: 400;
        font-size: 0.5rem;
        line-height: 0.59rem;
        letter-spacing: 2.5px;
      }
    }
  }

  .desc-text {
    font-family: "HarmonyOS Sans SC";
    font-style: normal;
    font-weight: 400;
    font-size: 0.1rem;
    letter-spacing: 0.01em;
    color: #ffffff;
    &.top {
      display: inline-block;
      line-height: 0.12rem;
      margin: 2.1rem 0 2.6rem 2.8rem;
    }
    &.bottom {
      line-height: 0.19rem;
      margin-left: 2.8rem;
    }
  }
  .car-drive-wrapper {
    position: absolute;
    top: 2rem;
    left: 0;
    width: 100%;

    .animation-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    // .road-svg {
    //   margin-top: 1.3rem;
    // }
    .position {
      position: relative;
      .outer-circle {
        display: inline-block;
        width: 6.15rem;
        height: 6.15rem;
        border-radius: 50%;
        background: rgba(0, 255, 0, 0.2);
        text-align: center;
        .inner-circle {
          display: inline-block;
          width: 3.81rem;
          height: 3.81rem;
          border-radius: 50%;
          background: linear-gradient(
            269.44deg,
            @primary-color -4%,
            #00ffc2 102.4%
          );
          border: 1px solid #868484;
          margin-top: 1.17rem;
        }
      }
      .car {
        position: absolute;
        top: 50%;
        left: -0.3rem;
        transform: translateY(-50%);
        width: 8.03rem;
        height: 5.36rem;
      }
    }
  }
}
</style>
