<template>
  <div class="structure-mobile">
    <video
      class="video"
      muted
      autoplay
      playsinline
      x-webkit-playsinline
      x5-playsinline
      loop
      :src="videoUrl"
      type="video/mp4"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "StructureMobile",

  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
    videoUrl() {
      return this.locale === "cn"
        ? "http://ad4-static.lotuscars.com.cn/webapp/mobile/video/galaxy/struct.mp4"
        : "http://ad4-static.lotuscars.com.cn/webapp/mobile/video/galaxy/struct_en.mp4";
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/common.less";
@import "~@/assets/css/font.css";
@media only screen and (max-width: 750px) {
  .structure-mobile {
    video {
      height: calc(100vw * 1334 / 750);
      width: 100vw;
    }
  }
}
</style>
