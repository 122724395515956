<template>
  <div class="home-page">
    <div class="lr-bg">
      <div class="lr-bg-center">
        <div :class="{ 'lr-bg-title': true, en: locale === 'en' }">
          TO BOOST THE ROBOTICS ERA
        </div>
        <section
          data-aos="fade-in"
          data-aos-offset="0"
          data-aos-delay="30"
          data-aos-duration="500"
          data-aos-once="true"
        >
          <div :class="['lr-bg-text', { en: locale === 'en' }]">
            {{ $t("home.homeTitle") }}
          </div>
        </section>
      </div>
      <div class="lr-bg-left">
        <div
          class="lr-bg-year"
          data-aos="zoom-in-down"
          data-aos-offset="0"
          data-aos-delay="100"
          data-aos-duration="1000"
          data-aos-once="true"
        >
          <div>@2023</div>
          <div style="margin-top: 5px">CODE DIFFERENT</div>
        </div>
        <div
          class="lr-bg-cloud"
          data-aos="zoom-in-right"
          data-aos-offset="0"
          data-aos-delay="200"
          data-aos-duration="1000"
          data-aos-once="true"
        >
          <div style="text-transform: uppercase">To Boost the Robotics Era</div>
          <div style="margin-top: 8px"></div>
        </div>
      </div>
      <slide-icon class="slide-mobile" />
    </div>
    <intro-robo></intro-robo>
    <div class="lr-module">
      <module-content></module-content>
    </div>
    <div class="lr-join">
      <join-us></join-us>
    </div>
    <div class="lr-contact" id="contact">
      <!-- <contact-us></contact-us> -->
      <globalization-module></globalization-module>
    </div>
  </div>
</template>

<script>
import ModuleContent from "./components/ModuleContent.vue";
import JoinUs from "./components/JoinUs.vue";
// import ContactUs from "./components/ContactUs.vue";
import IntroRobo from "./components/IntroRobo.vue";
import SlideIcon from "@/components/SlideIcon.vue";
import GlobalizationModule from "./components/GlobalizationModule.vue";
import { mapState } from "vuex";

export default {
  name: "Home",
  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },
  components: {
    ModuleContent,
    JoinUs,
    // ContactUs,
    IntroRobo,
    SlideIcon,
    GlobalizationModule,
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/font.css";
@import "~@/assets/css/common.less";
@import "~@/assets/css/image.less";
.home-page {
  background-color: #000;
}

.lr-bg {
  height: calc(100vh - 0.5rem);
  position: relative;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("@{pcLrBg}") center/cover no-repeat;
}

.lr-bg-center {
  padding-top: 1.95rem;
  text-align: center;
}

.lr-bg-title {
  font-size: 0.5rem;
  letter-spacing: 0.01rem;
  font-family: "HarmonyOS Sans SC Black";
  font-weight: 900;
  text-align: center;
  background: linear-gradient(269.44deg, #00ff00 -4%, #00ffc2 102.4%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.lr-bg-text {
  font-size: 0.4rem;
  font-family: "HarmonyOS Sans SC Thin";
  margin-top: 0.25rem;
  text-transform: uppercase;
  background: linear-gradient(269.44deg, #00ff00 -4%, #00ffc2 102.4%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.lr-bg-left {
  font-size: 10px;
  color: #fff;
  font-family: "HarmonyOS Sans SC";
}

.lr-bg-year {
  left: 0.84rem;
  top: 3.1rem;
  position: absolute;
  font-family: "HarmonyOS Sans SC";
}

.lr-bg-cloud {
  position: absolute;
  left: 0.84rem;
  bottom: 0.65rem;
  font-family: "HarmonyOS Sans SC";
}

.lr-module {
  height: 720px;
  width: 100%;
  background: url("@{lrModule}") center/cover no-repeat;
}

.lr-join {
  height: 720px;
  width: 100%;
  background: url("@{pcLrJoin}") 10% 12% / cover no-repeat;
}

@media only screen and (max-width: 750px) {
  .lr-bg {
    height: 100vh;
    position: relative;
    width: 100%;
    background: url("@{mbLrBg}");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .lr-bg-center {
    padding-top: 2.98rem;
    text-align: center;
  }

  .lr-bg-title {
    font-size: 0.4rem;
    letter-spacing: 0.01rem;
    text-align: center;
    background: linear-gradient(269.44deg, #00ff00 -4%, #00ffc2 102.4%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

    &.en {
      font-size: 0.42rem;
      letter-spacing: 0;
    }
  }

  .lr-bg-text {
    font-size: 0.45rem;
    letter-spacing: 0.1rem;
    font-family: "HarmonyOS Sans SC Thin";
    margin-top: 10px;
    background: linear-gradient(269.44deg, #00ff00 -4%, #00ffc2 102.4%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    &.en {
      letter-spacing: 0;
      font-size: 0.35rem;
      width: 5.37rem;
      margin: 0.25rem auto;
    }
  }

  .lr-bg-left {
    font-size: 0.14rem;
    color: #fff;
    font-family: "HarmonyOS Sans SC Regular";
  }

  .lr-bg-year {
    left: 0.55rem;
    top: 7.31rem;
    position: absolute;
  }

  .lr-bg-cloud {
    position: absolute;
    left: 0.55rem;
    top: 10.58rem;
    font-family: "HarmonyOS Sans SC";
  }

  .slide-mobile {
    display: none;
  }

  .lr-join {
    height: 8.04rem;
    width: 100%;
    background: url("@{mbLrJoin}");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .lr-contact {
    height: 5.03rem;
    background: url("@{mbLrContact}");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .lr-module {
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("@{lrModule}") center/cover no-repeat;
  }
}
</style>
