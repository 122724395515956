<template>
  <div class="footer-container">
    <div class="lr-sub">
      <img
        src="http://ad4-static.lotuscars.com.cn/webapp/common/footer_logo.png"
        class="lr-sub-img"
      />
      <div class="lr-sub-text">{{ $t("footer.subscribe") }}</div>
      <div class="lr-sub-line"></div>
      <div class="lr-sub-co">
        <!-- <div class="lr-sub-coimgs coimgs-bill" @click="toBili()">
          <img src="../assets/img/bili.png" class="bili" />
          <img src="../assets/img/arrow.png" />
        </div> -->
        <div class="lr-sub-coimgs coimgs-wechat" @click="toWechat()">
          <img
            src="http://ad4-static.lotuscars.com.cn/webapp/common/wechat.png"
            class="wechat"
          />
          <img
            src="http://ad4-static.lotuscars.com.cn/webapp/common/arrow.png"
          />
        </div>
        <div class="lr-sub-coimgs" @click="toZhihu()">
          <img
            src="http://ad4-static.lotuscars.com.cn/webapp/common/zhihu.png"
            class="zhihu"
          />
          <img
            src="http://ad4-static.lotuscars.com.cn/webapp/common/arrow.png"
          />
        </div>
      </div>
    </div>
    <div class="lr-text">
      <div class="lr-module-concact">
        <div class="lr-module">
          <div class="lr-module-title">{{ $t("footer.solution") }}</div>
          <div class="lr-modules">
            <div @click="toDrive()">ROBO Soul</div>
            <div @click="toGalaxy()">ROBO Galaxy</div>
            <div @click="toMatrix()">ROBO Matrix</div>
            <div @click="toVerse()">ROBOVERSE</div>
            <div @click="toCreature()">{{ $t("footer.newCreature") }}</div>
            <div @click="toNews()">ROBO News</div>
          </div>
        </div>
        <div :class="['lr-contact', { en: locale === 'en' }]">
          <div class="lr-contact-title">{{ $t("footer.contact") }}</div>
          <div class="lr-contacts">
            <div>lotus-robotics.pr@lotuscars.com.cn</div>
            <div>BD_LR@lotuscars.com.cn</div>
          </div>
        </div>
      </div>
      <div class="lr-about">
        <div class="lr-about-title">{{ $t("footer.about1") }}</div>
        <div class="lr-about-title">{{ $t("footer.about2") }}</div>
        <div class="lr-abouts">
          <div @click="toHome()">{{ $t("footer.team") }}</div>
          <div @click="toJoin()">{{ $t("footer.joinUs") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Footer",
  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },
  methods: {
    toContact() {
      this.$router.push("/home");
      this.$nextTick(() => {
        document.getElementById("contact").scrollIntoView();
      });
    },
    toJoin() {
      window.open(
        "https://app.mokahr.com/social-recruitment/lotus/41375#/jobs?department%5B0%5D=964243",
        "_self"
      );
    },
    toDrive() {
      this.$router.push("/robosoul");
    },
    toGalaxy() {
      this.$router.push("/robogalaxy");
    },
    toHome() {
      this.$router.push("/home");
    },
    toMatrix() {
      this.$router.push("/robomatrix");
    },
    toVerse() {
      this.$router.push("/roboverse");
    },
    toCreature() {
      this.$router.push("/robocube");
    },
    toNews() {
      this.$router.push("/robonews");
    },
    toBili() {
      window.open("https://space.bilibili.com/1184833090", "_self");
    },
    toWechat() {
      window.open("https://mp.weixin.qq.com/s/EAep66X5L2TmLhE9W5oyjw", "_self");
    },
    toZhihu() {
      const element = document.createElement("a");
      element.href = "https://www.zhihu.com/org/lu-te-si-zhi-neng-jia-shi";
      element.target = "_self";
      element.click();
    },
  },
};
</script>

<style lang="less" scoped>
.footer-container {
  background-color: #000;
  display: flex;
  padding: 0.52rem 1.83rem 1.35rem 2.07rem;
}

.lr-sub {
}

.lr-sub-img {
  width: 1.78rem;
  height: 61px;
}

.lr-sub-text {
  font-size: 16px;
  color: #fff;
  margin-top: 34px;
  font-family: "HarmonyOS Sans SC Light";
}

.lr-sub-co {
  display: flex;
  width: 3.41rem;
  margin-top: 27px;
}

.lr-sub-line {
  width: 3.41rem;
  height: 1px;
  background-color: #4d4d4d;
  margin-top: 16px;
}

.lr-sub-coimgs {
  position: relative;
  display: flex;
}

.coimgs-bill {
  margin-right: 65px;
}

.coimgs-wechat {
  margin-right: 81px;
}

.bili {
  width: 0.61rem;
  height: 18px;
  cursor: pointer;
}

.wechat {
  width: 0.74rem;
  height: 23px;
  cursor: pointer;
}

.zhihu {
  width: 0.36rem;
  height: 0.17rem;
  cursor: pointer;
}

.lr-sub-coimgs img:nth-child(2) {
  display: block;
  top: -8px;
  right: -16px;
  width: 8px;
  height: 8px;
  position: absolute;
  cursor: pointer;
}

.lr-text {
  position: relative;
  @title-bottom: 0.15rem;
  @content-bottom: 0.12rem;
  .lr-module-concact {
    position: absolute;
    left: 2.24rem;
    top: 0.1rem;
  }

  .lr-module {
    display: flex;
    flex-direction: column;

    .lr-module-title {
      font-size: 0.16rem;
      color: #fff;
      font-family: "HarmonyOS Sans SC Black";
      white-space: normal;
    }
    .lr-modules {
      font-size: 14px;
      color: rgba(142, 142, 142, 1);
      margin-top: @title-bottom;
      font-family: "HarmonyOS Sans SC Light";
      white-space: nowrap;
    }

    .lr-modules div {
      width: 1.1rem;
      margin-bottom: @content-bottom;
      cursor: pointer;

      &:hover {
        color: #fff;
      }
    }
  }

  .lr-about {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 3.94rem;
    top: 0.1rem;
    .lr-about-title {
      width: 1.8rem;
      font-size: 0.16rem;
      color: #fff;
      font-family: "HarmonyOS Sans SC Black";
    }
    .lr-abouts {
      font-size: 14px;
      color: rgba(142, 142, 142, 1);
      margin-top: @title-bottom;
      font-family: "HarmonyOS Sans SC Light";
    }

    .lr-abouts div {
      margin-bottom: @content-bottom;
      cursor: pointer;

      &:hover {
        color: #fff;
      }
    }
  }

  .lr-contact {
    margin-top: 0.2rem;
    &.en {
      top: 2.8rem;
    }
    .lr-contact-title {
      font-size: 0.16rem;
      color: #fff;
      font-family: "HarmonyOS Sans SC Black";
      white-space: normal;
    }
    .lr-contacts {
      font-size: 14px;
      color: rgba(142, 142, 142, 1);
      margin-top: @title-bottom;
      font-family: "HarmonyOS Sans SC Light";
      white-space: nowrap;
    }

    .lr-contacts div {
      width: 1.1rem;
      margin-bottom: @content-bottom;
      cursor: pointer;

      &:hover {
        color: #fff;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .footer-container {
    background-color: #000;
    display: flex;
    padding: 1.17rem 0 1.58rem 1.55rem;
  }

  .lr-text {
    .lr-module,
    .lr-about,
    .lr-contact {
      display: none;
    }
  }

  .lr-sub-img {
    width: 1.78rem;
    height: 0.61rem;
  }

  .lr-sub-text {
    font-size: 0.22rem;
    color: #fff;
    margin-top: 0.44rem;
    font-family: "HarmonyOS Sans SC Light";
  }
  .bili {
    width: 0.81rem;
    height: 0.24rem;
    cursor: pointer;
  }
  .wechat {
    width: 0.98rem;
    height: 0.3rem;
    cursor: pointer;
  }

  .zhihu {
    width: 0.47rem;
    height: 0.22rem;
    cursor: pointer;
  }

  .coimgs-bill {
    margin-right: 0.85rem;
  }
  .coimgs-wechat {
    margin-right: 0.95rem;
  }

  .lr-sub-coimgs img:nth-child(2) {
    display: block;
    top: -0.08rem;
    right: -0.16rem;
    width: 0.08rem;
    height: 0.08rem;
    position: absolute;
    cursor: pointer;
  }
  .lr-sub-line {
    width: 4.39rem;
    height: 1px;
    background-color: #4d4d4d;
    margin-top: 16px;
  }
}
</style>
