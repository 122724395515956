<template>
  <section class="roboverse-second-section">
    <div ref="card" @mouseover="cardRotate" class="rotate-container">
      <div class="intro-panel front">
        <div class="headline">
          <h1 :class="'title-line1 ' + locale">
            {{ $t("verse.section2.card1.title1") }}
          </h1>
          <h1 :class="'title-line2 ' + locale">
            {{ $t("verse.section2.card1.title2") }}
          </h1>
        </div>

        <p :class="'content ' + locale">
          {{ $t("verse.section2.card1.content") }}
        </p>
      </div>
      <div class="intro-panel back">
        <div class="headline">
          <h1 :class="'title-line1 ' + locale">
            {{ $t("verse.section2.card2.title1") }}
          </h1>
          <h1 :class="'title-line2 ' + locale">
            {{ $t("verse.section2.card2.title2") }}
          </h1>
        </div>

        <p :class="'content ' + locale">
          {{ $t("verse.section2.card2.content") }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "RoboverseSecondSection",
  data() {
    return {
      rotateAngle: 0,
      rotateFlag: true,
    };
  },
  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },
  methods: {
    cardRotate() {
      if (this.rotateFlag) {
        this.rotateFlag = false;
        this.rotateAngle = (this.rotateAngle + 180) % 360;
        this.$refs.card.style.setProperty(
          "--rotateAngle",
          this.rotateAngle + "deg"
        );
        setTimeout(() => {
          this.rotateFlag = true;
        }, 1000);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/font.css";
@import "~@/assets/css/image.less";

.roboverse-second-section {
  position: relative;
  height: calc(100vw / 1280 * 720);
  background-image: url("@{secondBg}");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .rotate-container {
    position: absolute;
    top: 0.65rem;
    left: 2.1rem;
    width: 3.45rem;
    height: 5.03rem;
    perspective: 500px;
    transform-style: preserve-3d;
    transition: all 1s linear;
    transform: rotateY(var(--rotateAngle));

    // &:hover {
    //   transform: rotateY(180deg);
    // }
  }

  .intro-panel {
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 1.2rem 0.3rem 0.6rem 0.3rem;
    background: rgba(255, 255, 255, 0.16);
    backdrop-filter: blur(5px);
    border-radius: 0.3rem;
    cursor: pointer;

    &.front {
      backface-visibility: hidden;
    }

    &.back {
      backface-visibility: hidden;
      transform: rotateY(180deg);
    }

    .headline {
      font-family: "HarmonyOS Sans SC";
      font-style: normal;
      letter-spacing: 0.01em;

      .title-line1 {
        font-size: 0.3rem;
        font-weight: 700;
        padding: 0;
        margin: 0;
        background: linear-gradient(269.44deg, #00ff00 -4%, #00ffc2 102.4%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        &.en {
          line-height: 0.35rem;
        }
      }

      .title-line2 {
        float: right;
        font-size: 0.3rem;
        font-weight: 700;
        padding: 0;
        margin: 0;
        color: #ffffff;
        &.en {
          line-height: 0.35rem;
          background: linear-gradient(269.44deg, #00ff00 -4%, #00ffc2 102.4%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
      }
      &::after {
        display: block;
        content: "";
        visibility: hidden;
        clear: both;
      }
    }

    .content {
      padding: 0;
      margin: 0.4rem 0 0 0;
      font-family: "HarmonyOS Sans SC Thin";
      font-style: normal;
      font-weight: 400;
      font-size: 0.14rem;
      line-height: 0.28rem;
      letter-spacing: 0.03em;
      color: #ffffff;

      &.en {
        font-size: 0.12rem;
        line-height: 0.22rem;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .roboverse-second-section {
    position: relative;
    height: calc(100vw / 785 * 750);
    background-image: url("@{mbSecondBg}");

    .rotate-container {
      top: 0.6rem;
      left: 1rem;
    }

    .intro-panel {
      padding: 1rem 0.3rem 0.15rem 0.3rem;
      box-sizing: content-box;
    }
  }
}
</style>
