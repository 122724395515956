<template>
  <section class="roboverse-thirteenth-section">
    <div
      class="headline"
      data-aos="fade-left"
      data-aos-easing="ease-out-cubic"
      data-aos-offset="0"
      data-aos-delay="100"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      L.N. UNITED<br />PROMOTERS
    </div>

    <div
      class="cooperations"
      data-aos="zoom-in"
      data-aos-easing="ease-out-cubic"
      data-aos-offset="0"
      data-aos-delay="100"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <div class="logo"></div>
      <div class="co-logo1"></div>
      <div class="co-logo2"></div>
      <div class="co-logo3"></div>
    </div>
  </section>
</template>

<script>
export default {
  name: "RoboverseThirteenthSection",
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/font.css";
@import "~@/assets/css/image.less";
.roboverse-thirteenth-section {
  position: relative;
  height: calc(100vw / 1280 * 617);
  background-image: url("@{thirteenthBg}");
  background-size: 100% 100%;

  .headline {
    padding-top: 1rem;
    margin-left: 2.53rem;
    font-family: "HarmonyOS Sans SC";
    font-style: normal;
    font-weight: 700;
    font-size: 0.5rem;
    line-height: 0.48rem;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    background: linear-gradient(269.44deg, #00ff00 -4%, #00ffc2 102.4%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .cooperations {
    display: flex;
    align-items: center;
    width: fit-content;
    margin: 2.7rem auto 0 auto;
    .logo {
      width: 1.26rem;
      height: 0.43rem;
      background-image: url("@{lotusLogo}");
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 0.7rem;
    }

    .co-logo1 {
      width: 1.62rem;
      height: 0.43rem;
      background-image: url("@{coLogo1}");
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 0.7rem;
    }

    .co-logo2 {
      width: 1.17rem;
      height: 0.25rem;
      background-image: url("@{coLogo2}");
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 0.7rem;
    }

    .co-logo3 {
      width: 1.69rem;
      height: 0.34rem;
      background-image: url("@{coLogo3}");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}

@media only screen and (max-width: 750px) {
  .roboverse-thirteenth-section {
    background-image: url("@{mbThirteenthBg}");
    height: 6.21rem;

    .headline {
      padding-top: 1.7rem;
      margin-left: 1.3rem;
      font-weight: 700;
      font-size: 0.32rem;
      line-height: 0.4rem;
    }

    .cooperations {
      margin-top: 2.43rem;
      .logo {
        width: 1.18rem;
        height: 0.44rem;
        background-image: url("@{mbLotusLogo}");
        margin-right: 0.23rem;
      }

      .co-logo1 {
        width: 1.25rem;
        height: 0.32rem;
        margin-right: 0.23rem;
      }

      .co-logo2 {
        width: 1.11rem;
        height: 0.22rem;
        margin-right: 0.23rem;
      }
      .co-logo3 {
        width: 1.22rem;
        height: 0.25rem;
      }
    }
  }
}
</style>
