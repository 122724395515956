<template>
  <div class="cmi-mpi-section">
    <p
      class="text head-top"
      data-aos="slide-up"
      data-aos-offset="120"
      data-aos-delay="100"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      {{ $t("soul.cmiMpiTitle") }}
    </p>
    <h1
      class="text head-bottom"
      data-aos="slide-up"
      data-aos-offset="120"
      data-aos-delay="150"
      data-aos-duration="1500"
      data-aos-once="true"
    >
      {{ $t("soul.cmiMpiSubTitle") }}
    </h1>
    <div class="cmi-mpi-table">
      <div
        class="cmi-mpi-table-left"
        data-aos="fade-up"
        data-aos-offset="120"
        data-aos-delay="100"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        <h2 class="table-header bold cmi">CMI</h2>
        <p>
          <span class="table-bold-hcontent bold">{{
            $t("soul.evaluation")
          }}</span>
          <span class="table-hcontent">{{
            $t("soul.functionalCoverageCn")
          }}</span>
          <span class="table-content min-size-content">{{
            $t("soul.functionalCoverageEn")
          }}</span>
        </p>
        <p>
          <span class="table-bold-hcontent bold">{{ $t("soul.vcmi") }}</span>
          <span class="table-hcontent">{{ $t("soul.validCoverageCn") }}</span
          ><br />
          <span class="table-content min-size-content">{{
            $t("soul.validCoverageEn")
          }}</span>
        </p>
        <p :class="['odd', { en: locale === 'en' }]">ODD</p>
        <p>
          <span class="table-bold-hcontent bold">{{ $t("soul.delivery") }}</span
          ><br />
          <span class="table-content min-size-content">{{
            $t("soul.roadType")
          }}</span>
        </p>
        <div class="cmi-mpi-table-bg"></div>
      </div>
      <div
        class="cmi-mpi-table-right"
        data-aos="fade-up"
        data-aos-offset="120"
        data-aos-delay="200"
        data-aos-duration="1200"
        data-aos-once="true"
      >
        <div class="cmi-mpi-table-right-left">
          <h2 style="" class="table-header bold mpi">MPI</h2>
          <p>
            <span class="table-bold-hcontent bold">{{
              $t("soul.evaluationSystem")
            }}</span
            ><br />
            <span class="table-hcontent thin">{{
              $t("soul.userCoverage")
            }}</span>
          </p>
        </div>
        <div class="cmi-mpi-table-right-center">
          <p>
            <span class="table-bold-hcontent bold">MPCI</span>
            <span class="table-hcontent">{{ $t("soul.mpci") }}</span
            ><br />
            <span class="table-content min-size-content"
              >Mileage Per Critical Intervention</span
            >
          </p>
          <p>
            <span class="table-bold-hcontent bold">MPRI</span>
            <span class="table-hcontent">{{ $t("soul.mpri") }}</span
            ><br />
            <span class="table-content min-size-content"
              >Mileage Per Regulation Issue Intervention
            </span>
          </p>
          <p>
            <span class="table-bold-hcontent bold">MPSI</span>
            <span class="table-hcontent">{{ $t("soul.mpsi") }}</span
            ><br />
            <span class="table-content min-size-content"
              >Mileage Per Smart Issue Intervention</span
            >
          </p>
          <p>
            <span class="table-bold-hcontent bold">MPEI</span>
            <span class="table-hcontent">{{ $t("soul.mpei") }}</span
            ><br />
            <span class="table-content min-size-content"
              >Mileage Per Experience Issue Intervention</span
            >
          </p>
        </div>
        <div class="cmi-mpi-table-right-right">
          <p>
            <span class="table-bold-hcontent bold">{{
              $t("soul.userExperience")
            }}</span
            ><br />
            <span class="table-content min-size-content">{{
              $t("soul.userExperienceContent")
            }}</span>
          </p>
          <p>
            <span class="table-bold-hcontent bold">{{
              $t("soul.rdModule")
            }}</span
            ><br />
            <span class="table-content min-size-content">
              {{ $t("soul.rdModuleContent1") }}<br />
              {{ $t("soul.rdModuleContent2") }}<br />
              {{ $t("soul.rdModuleContent3") }}<br />
              {{ $t("soul.rdModuleContent4") }}
            </span>
          </p>
          <p>
            <span class="table-bold-hcontent bold">{{
              $t("soul.deliveryScope")
            }}</span
            ><br />
            <span class="table-content min-size-content">
              {{ $t("soul.deliveryScopeContent") }}
            </span>
          </p>
        </div>
        <div class="cmi-mpi-table-bg"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "CmiMpiSection",
  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/common.less";
@import "~@/assets/css/image.less";
.cmi-mpi-section {
  &::before {
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    background: url("@{seeLandscapeFormat}") 100% 100% no-repeat;
    mix-blend-mode: luminosity;
  }
  height: calc(100vh - 0.5rem);
  min-height: 7.12rem;
  text-align: center;
  position: relative;
  p {
    margin: 0;
    padding: 0;
  }
  .text {
    font-family: "HarmonyOS Sans SC";
    font-style: normal;
    letter-spacing: 0.05em;
    &.head-top {
      padding-top: 1.27rem;
      font-weight: 400;
      font-size: 0.28rem;
      color: @primary-color;
    }
    &.head-bottom {
      font-weight: 900;
      font-size: 0.42rem;
      width: 4.7rem;
      margin: auto;
      margin-top: 0.25rem;
      background: linear-gradient(
        269.44deg,
        @primary-color -4%,
        #00ffc2 102.4%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }

  .cmi-mpi-table {
    height: 2.88rem;
    margin-top: 0.75rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    border: #00ffc2;
    p {
      color: @primary-color;
      font-family: "HarmonyOS Sans SC";
      font-style: normal;
      font-weight: 400;
      font-size: 0.1rem;
      margin-bottom: 0.22rem;
    }
    .bold {
      font-family: "HarmonyOS Sans SC Black";
      font-weight: 900;
    }
    .thin {
      font-family: "HarmonyOS Sans SC Thin";
    }
    .table-header {
      background: linear-gradient(
        269.44deg,
        @primary-color -4%,
        #00ffc2 102.4%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      width: fit-content;
      font-size: 0.24rem;
      line-height: 0.2813rem;
      margin-top: 0;
      margin-bottom: 0.18rem;
    }
    .table-bold-hcontent {
      font-size: 0.14rem;
    }
    .table-hcontent {
      font-size: 0.12rem;
    }
    .table-content {
      color: #c2c2c2;
      line-height: 1.2;
    }
    .min-size-content {
      display: block;
      // width: 110%;
      font-size: 0.12rem !important;
      transform: scale(0.67);
      transform-origin: top left;
      white-space: nowrap;
      font-family: "HarmonyOS Sans SC Thin";
      line-height: 1.5;
    }
    .cmi-mpi-table-left {
      flex-basis: 1.5rem;
      margin-right: 0.391rem;
      height: 100%;
      border-radius: 0.08rem;
      text-align: left;
      box-sizing: border-box;
      position: relative;
      overflow: hidden;
      padding: 0.17rem 0 0 0.15rem;
      z-index: 2;
      .odd {
        font-size: 0.3rem;
        line-height: 0.35rem;
        margin-bottom: 0.22rem;
        font-family: "HarmonyOS Sans SC Light";
      }
      .min-size-content {
        transform: scale(0.67);
      }
    }
    .cmi-mpi-table-right {
      flex-basis: 7.136rem;
      height: 100%;
      border-radius: 0.08rem;
      display: flex;
      flex-direction: row;
      position: relative;
      overflow: hidden;
      z-index: 2;
      p {
        margin-bottom: 0.23rem;
      }
      .table-bold-hcontent {
        font-size: 0.16rem;
      }
      .cmi-mpi-table-right-left {
        flex-basis: 2.26rem;
        height: 100%;
        text-align: left;
        box-sizing: border-box;
        padding: 0.15rem 0 0 0.5rem;
        .table-bold-hcontent {
          font-size: 0.12rem;
        }
      }
      .cmi-mpi-table-right-center {
        flex-basis: 2.16rem;
        height: 100%;
        text-align: left;
        box-sizing: border-box;
        padding-top: 0.6rem;
      }
      .cmi-mpi-table-right-right {
        height: 100%;
        text-align: left;
        padding-top: 0.6rem;
        max-width: 2.62rem;
        .table-bold-hcontent {
          font-size: 0.1rem;
          line-height: 1.5;
        }
        .table-content {
          font-size: 0.08rem;
          line-height: 1.2;
        }
      }
    }
    .cmi-mpi-table-bg {
      width: 100%;
      height: 100%;
      background: rgba(245, 245, 245, 0.29);
      backdrop-filter: blur(0.05rem);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }
  .bg {
    width: 100%;
    height: 100%;
    img {
      width: 60%;
      position: absolute;
      bottom: 0;
      right: 0;
      mix-blend-mode: luminosity;
    }
  }
}

@media only screen and (max-width: 750px) {
  .cmi-mpi-section {
    width: 100%;
    height: 12.72rem;
    &::before {
      background: url("@{mobileSeeLandscapeFormat}") 0 100% no-repeat;
    }
    .text {
      &.head-bottom {
        width: 4.7rem;
      }
    }
    .cmi-mpi-table {
      // margin-top: 80px;
      height: auto;
      flex-direction: column;
      p {
        font-size: 0.12rem;
        margin-bottom: 0.3rem;
      }
      .table-header {
        font-size: 0.24rem;
        // line-height: 1.2;
        margin-bottom: 0.2rem;
        &.mpi {
          margin-bottom: 0.3rem;
        }
      }
      .table-bold-hcontent {
        font-size: 0.2rem;
      }
      .table-hcontent {
        font-size: 0.16rem;
      }
      .cmi-mpi-table-left {
        width: 6.1rem;
        margin: 0 0 0.34rem 0;
        height: 1.53rem;
        white-space: nowrap;
        display: inline-block;
        padding: 0.32rem 0 0 0.35rem;
        p {
          vertical-align: top;
          &:nth-child(n + 2) {
            display: inline-block;
            margin-right: 0.15rem;
          }
          &.odd {
            font-size: 0.24rem;
            &.en {
              margin-left: -0.5rem;
            }
          }
        }
      }
      .cmi-mpi-table-right {
        flex-basis: 5.07rem;
        width: 6.1rem;
        border-radius: 0.08rem;
        box-sizing: border-box;
        // padding: 0.32rem 0 0 0.42rem;
        p {
          margin-bottom: 0.35rem;
        }
        .table-bold-hcontent {
          font-size: 0.2rem;
        }
        .cmi-mpi-table-right-left {
          flex-basis: 2.26rem;
          height: 0.5rem;
          position: absolute;
          top: 0.32rem;
          left: 0.35rem;
          padding: 0;
          .table-bold-hcontent {
            font-size: 0.2rem;
          }
        }
        .cmi-mpi-table-right-center {
          position: absolute;
          top: 1.1rem;
          left: 0.35rem;
        }
        .cmi-mpi-table-right-right {
          position: absolute;
          top: 0.28rem;
          left: 3rem;
          .table-bold-hcontent {
            font-size: 0.2rem;
          }
          .table-content {
            font-size: 0.12rem;
          }
        }
      }
      .cmi-mpi-table-bg {
        width: 100%;
        height: 100%;
        background: rgba(245, 245, 245, 0.29);
        backdrop-filter: blur(0.05rem);
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
      }
    }
  }
}
</style>
