<template>
  <div class="path-svg">
    <svg
      :width="w"
      :height="h"
      :view-box="vb"
      :transform="transform"
      transform-origin="330 0"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      overflow="visible"
    >
      <path
        id="path1"
        d="M295.238 220.656L146.293 -195.498L967.502 272.46L295.238 220.656Z"
        stroke="url(#paint0_linear_1544_224)"
      />
      <path
        id="path2"
        d="M713.791 315.682L194.95 401.554L127.595 213.362L713.791 315.682ZM713.791 315.682L969.43 273.371L426.185 805.881L1179.04 594.908"
        stroke="url(#paint1_linear_1544_224)"
      />
      <path
        id="path3"
        d="M865.759 104.586L1076.66 -145.544L972.981 272.101L865.759 104.586Z"
        stroke="url(#paint2_linear_1544_224)"
      />
      <path
        id="path4"
        d="M975.523 279.203L923.107 423.74L1251.54 506.474M975.523 279.203L1251.54 506.474M975.523 279.203L973.157 606.109L1251.54 506.474"
        stroke="url(#paint3_linear_1544_224)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1544_224"
          x1="436.038"
          y1="-299.2"
          x2="677.756"
          y2="376.162"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0BFFFF" />
          <stop offset="1" stop-color="#04FF69" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1544_224"
          x1="533.096"
          y1="68.2294"
          x2="773.543"
          y2="740.041"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C4FFFF" />
          <stop offset="1" stop-color="#04FF69" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1544_224"
          x1="943.505"
          y1="-97.8863"
          x2="1064.24"
          y2="239.44"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0BFFFF" />
          <stop offset="1" stop-color="#04FF69" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1544_224"
          x1="1008.9"
          y1="267.256"
          x2="1112.35"
          y2="556.292"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0BFFFF" />
          <stop offset="1" stop-color="#04FF69" />
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: "PathSvg",
  props: {
    width: Number,
    height: Number,
  },
  computed: {
    w() {
      return this.width + "px";
    },
    h() {
      return this.height + "px";
    },
    vb() {
      return `0 0 ${this.width} ${this.h}`;
    },
    transform() {
      return `scale(${this.width / 898}, ${this.height / 680})`;
    },
  },
};
</script>

<style lang="less" scoped>
#path1 {
  animation: dash 5s linear;
}
#path2 {
  animation: dash 5s linear;
}
#path3 {
  animation: dash 5s linear;
}
#path4 {
  animation: dash 5s linear;
}
@keyframes dash {
  0% {
    stroke-dashoffset: 1300;
    stroke-dasharray: 0 1300;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 1300 0;
  }
}
</style>
