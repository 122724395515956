export default {
  soulTitle: "ROBO Soul",
  subHead: "全新高阶智能驾驶",
  algorithmic: "大算法模型",
  developmentTitle1: "面向机器人时代的开发流程",
  developmentTitle2: "Development process for the robotics era",
  developmentSubTitle1: "自研混合软件开发框架",
  developmentSubTitle2: "/敏捷开发+传统V型",
  schemaTitle: "面向机器人时代的智能驾驶解决方案",
  schemaHead1: "高快领航",
  schemaSubHead1: "Highway Pilot",
  schemaHead2: "城市领航",
  schemaSubHead2: "Urban Pilot",
  schemaHead3: "停车场领航",
  schemaSubHead3: "Parking Pilot",
  schemaHead4: "驾驶辅助",
  schemaSubHead4: "Driving Assistance",
  schemaHead5: "泊车辅助",
  schemaSubHead5: "Parking Assistance",
  schemaHead6: "主动安全",
  schemaSubHead6: "Active Safety",
  cmiMpiTitle: "用覆盖里程与接管里程",
  cmiMpiSubTitle: "重新定义智能驾驶分级",
  evaluation: "评价体系",
  functionalCoverageCn: "/功能覆盖率",
  functionalCoverageEn: "",
  vcmi: "VCMI",
  validCoverageCn: "/有效覆盖里程",
  validCoverageEn: "Valid Mileage of Coverage",
  delivery: "交付范围",
  roadType: "道路类型/国家城市",
  evaluationSystem: "评价体系",
  userCoverage: "UserStory功能需求覆盖率",
  mpci: "/安全接管里程",
  mpri: "/法规接管里程",
  mpsi: "/智能接管里程",
  mpei: "/舒适接管里程",
  userExperience: "用户体验",
  userExperienceContent: "安全、法规、智能、舒适",
  rdModule: "R&D Module",
  rdModuleContent1: "World model 视觉感知 地图引擎 地图数据 定位",
  rdModuleContent2: "感知融合 标定模块 横向规划 纵向规划 控制 预测",
  rdModuleContent3: "HMI 系统硬件 其他",
  rdModuleContent4: "",
  deliveryScope: "交付范围",
  deliveryScopeContent: "道路类型/国家城市",
};
