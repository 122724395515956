<template>
  <div class="module-container">
    <div
      class="module-content"
      data-aos="zoom-in"
      data-aos-offset="120"
      data-aos-delay="300"
      data-aos-duration="1000"
      data-aos-once="true"
      v-for="(module, index) in moduleList"
      :key="index"
    >
      <div class="module-content-ku">
        <div class="module-title">{{ module.title }}</div>
        <div
          :class="
            locale === 'cn' ? 'module-text1' : 'module-text1 module-text-en'
          "
        >
          {{ $t(`home.moduleContent.${index}.text1`) }}
        </div>
        <div
          :class="
            locale === 'cn' ? 'module-text2' : 'module-text2 module-text-en'
          "
        >
          {{ $t(`home.moduleContent.${index}.text2`) }}
        </div>
        <div
          :class="
            locale === 'cn' ? 'module-more' : 'module-more module-more-en'
          "
          @click="toMore(module)"
        >
          {{ $t("home.knowMore") }} >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ModuleContent",
  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },
  data() {
    const isMobile = this.isMobile();

    const matrix = {
      title: "ROBO Matrix",
      text1:
        "在智能驾驶能力不断提升的过程中，用运营服务来弥补用户对智能驾驶的期待，提升用户的乘用体验",
      text2: "",
      link: "/robomatrix",
    };

    const verse = isMobile
      ? {
          title: "ROBOVERSE",
          text1:
            "用“接管里程”与“覆盖里程”重新定义智能驾驶分级，赋能行业，培养人才",
          text2: "",
          link: "/roboverse",
        }
      : {
          title: "ROBOVERSE",
          text1: "用“接管里程”与“覆盖里程”重新定义智能驾驶分级，",
          text2: "赋能行业，培养人才",
          link: "/roboverse",
        };
    return {
      moduleList: [
        {
          title: "ROBO Soul",
          text1: "在更高更快更强的时代，",
          text2: "路特斯是智能驾驶的优秀实践平台",
          link: "/robosoul",
        },
        {
          title: "ROBO Galaxy",
          text1: "我们处理明天的问题，用的不是今天的工具，",
          text2: "而是明天的工具",
          link: "/robogalaxy",
        },
        matrix,
        verse,
      ],
    };
  },

  methods: {
    toMore(module) {
      this.$router.push(module.link);
    },
    isMobile() {
      var userAgentInfo = navigator.userAgent;
      var Agents = new Array(
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPod"
      );
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return !flag;
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/font.css";
@import "~@/assets/css/common.less";
.module-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 720px;

  padding-left: 1.46rem;
  padding-right: 1.53rem;
}
.module-content {
  height: 4.47rem;
  width: 2.41rem;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  margin-left: 10px;
  font-family: "HarmonyOS Sans SC";
  padding-left: 0.41rem;
  &:hover {
    border: 1px solid;
    // background-image: linear-gradient(rgba(0, 255, 0, 1), rgba(0, 255, 194, 1));
    border-color: rgba(0, 255, 0, 1);
  }
}

.module-title {
  margin-top: 105px;
  text-align: left;
  color: rgba(0, 255, 0, 1);
  font-size: 28px;
  font-family: "HarmonyOS Sans SC Black";
}

.module-text1 {
  margin-top: 30px;
  width: 1.82rem;
  text-align: left;
  font-size: 14px;
  color: rgba(0, 255, 0, 0.5);
  line-height: 0.25rem;
  letter-spacing: 0.07rem;
  padding-right: 0.32rem;
  font-family: "HarmonyOS Sans SC Light";
}

.module-text2 {
  width: 1.82rem;
  text-align: left;
  letter-spacing: 0.05rem;
  font-size: 14px;
  color: rgba(0, 255, 0, 0.5);
  line-height: 0.25rem;
  padding-right: 0.32rem;
  font-family: "HarmonyOS Sans SC Light";
}

.module-text-en {
  letter-spacing: 0rem;
  line-height: 0.25rem;
  padding-right: 0.17rem;
}

.module-more {
  font-size: 14px;
  color: rgba(0, 255, 0, 1);
  letter-spacing: 6px;
  height: 31px;
  cursor: pointer;
  font-family: "HarmonyOS Sans SC Light";
  position: absolute;
  bottom: 0.5rem;
  &.module-more-en {
    font-size: 14px;
    letter-spacing: 0;
    font-family: "HarmonyOS Sans SC Light";
  }
}

@media only screen and (max-width: 750px) {
  .module-container {
    display: flex;

    flex-direction: column;
  }
  .module-content {
    height: 2.22rem;
    width: 5.73rem;
    background-color: rgba(0, 0, 0, 0.4);
    margin-bottom: 0.34rem;
    font-family: "HarmonyOS Sans SC";
    padding: 0.34rem 0 0 0.47rem;
    &:hover {
      border: 1px solid;
      // background-image: linear-gradient(rgba(0, 255, 0, 1), rgba(0, 255, 194, 1));
      border-color: rgba(0, 255, 0, 1);
    }
  }

  .module-title {
    margin-top: 0;
    text-align: left;
    color: rgba(0, 255, 0, 1);
    font-size: 0.32rem;
    font-family: "HarmonyOS Sans SC Black";
    font-weight: bolder;
  }

  .module-text1 {
    margin-top: 0.27rem;
    font-size: 0.22rem;
    width: 5rem;
    color: rgba(0, 255, 0, 0.5);
    line-height: 0.25rem;
    font-family: "HarmonyOS Sans SC Light";
    height: auto;
    padding-right: 0rem;
    letter-spacing: 0rem;
  }

  .module-text2 {
    font-size: 0.22rem;
    width: 5rem;
    letter-spacing: 0rem;
    color: rgba(0, 255, 0, 0.5);
    line-height: 0.25rem;
    font-family: "HarmonyOS Sans SC Light";
    height: auto;
    padding-right: 0rem;
  }

  .module-text-en {
    letter-spacing: 0rem;
    line-height: 0.25rem;
    padding-left: 0;
    padding-right: 0.17rem;
    font-size: 0.18rem;
  }

  .module-more {
    float: right;
    font-size: 0.22rem;
    color: rgba(0, 255, 0, 1);
    letter-spacing: 6px;
    padding-left: 0;
    padding-right: 0.61rem;
    margin-top: 0.28rem;
    cursor: pointer;
    font-family: "HarmonyOS Sans SC Light";
    opacity: 0.8;
    font-weight: lighter;
    position: relative;
    bottom: auto;
    &.module-more-en {
      font-size: 0.18rem;
      letter-spacing: 0;
      font-family: "HarmonyOS Sans SC Light";
    }
  }
}
</style>
