<template>
  <div
    class="globalization-module"
    data-aos="zoom-in"
    data-aos-offset="120"
    data-aos-delay="100"
    data-aos-duration="1000"
    data-aos-once="true"
  >
    <div
      data-aos="fade-left"
      data-aos-offset="120"
      data-aos-delay="100"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <h2 :class="{ decorator: true, en: locale === 'en' }">globalization</h2>
    </div>
    <div
      data-aos="fade-up"
      data-aos-offset="120"
      data-aos-delay="200"
      data-aos-duration="1200"
      data-aos-once="true"
    >
      <h1 :class="{ headline: true, en: locale === 'en' }">
        {{ $t("home.global") }}
      </h1>
    </div>
    <div class="place-container ger">
      <div
        data-aos="fade-in"
        data-aos-offset="120"
        data-aos-delay="400"
        data-aos-duration="1200"
        data-aos-once="true"
      >
        <div v-if="locale === 'cn'" class="place-cn">
          @德国法兰克福<br />路特斯科技创新中心
        </div>
        <div class="place-en">
          @Frankfurt, Germany<br />Lotus Tech Innovation Center
        </div>
      </div>
      <i ref="line0" class="place-line"></i>
    </div>
    <div class="place-container sh">
      <div
        data-aos="fade-in"
        data-aos-offset="120"
        data-aos-delay="700"
        data-aos-duration="1200"
        data-aos-once="true"
      >
        <div v-if="locale === 'cn'" class="place-cn">
          @中国上海市<br />路特斯科技软件&技术中心
        </div>
        <div class="place-en">
          @Shanghai, China<br />Lotus Tech Algorithm and<br />
          Technology Center
        </div>
      </div>
      <i ref="line1" class="place-line"></i>
    </div>
    <div class="place-container hz">
      <div
        data-aos="fade-in"
        data-aos-offset="120"
        data-aos-delay="1000"
        data-aos-duration="1200"
        data-aos-once="true"
      >
        <div v-if="locale === 'cn'" class="place-cn">
          @中国杭州市<br />路特斯科技研发基地
        </div>
        <div class="place-en">
          @Hangzhou Bay, China<br />
          Lotus Tech Research Facility
        </div>
      </div>
      <i ref="line2" class="place-line"></i>
    </div>
    <div class="place-container nb">
      <div
        data-aos="fade-in"
        data-aos-offset="120"
        data-aos-delay="1300"
        data-aos-duration="1200"
        data-aos-once="true"
      >
        <div v-if="locale === 'cn'" class="place-cn">
          @中国宁波市<br />路特斯科技研发基地
        </div>
        <div class="place-en">
          @Ningbo Bay, China<br />
          Lotus Tech Research Facility
        </div>
      </div>
      <i ref="line3" class="place-line"></i>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "GlobalizationModule",
  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },
  methods: {
    observeIntersection() {
      const io = new IntersectionObserver((entries) => {
        entries.forEach((item) => {
          if (item.isIntersecting) {
            item.target.classList.add("show");
          }
        });
      });

      for (let i = 0; i < 4; i++) {
        const line = this.$refs[`line${i}`];
        io.observe(line);
      }
    },
  },
  mounted() {
    this.observeIntersection();
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/common.less";
@import "~@/assets/css/image.less";
.globalization-module {
  width: 100%;
  height: 78.4vw;
  background-image: url("@{pcGlobalizationMap}");
  background-size: 100% auto;
  background-repeat: no-repeat;
  position: relative;
  @mrg-left: 2.1rem;

  .decorator {
    margin: 0 0 -0.15rem 2rem;
    padding: 1.24rem 0 0 0;
    font-family: "HarmonyOS Sans SC Black";
    font-style: normal;
    font-weight: 700;
    font-size: 0.5rem;
    line-height: 0.48rem;
    letter-spacing: 0.01em;
    background: linear-gradient(
      98.62deg,
      #364242 3.92%,
      #2b2f37 48.34%,
      #21262b 96.58%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-transform: uppercase;
    &.en {
      width: fit-content;
      font-size: 0.5rem;
      background: linear-gradient(269.44deg, #00ff00 -4%, #00ffc2 102.4%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  .headline {
    margin: 0 0 0 @mrg-left;
    padding: 0;
    font-family: "HarmonyOS Sans SC Black";
    font-style: normal;
    font-weight: 700;
    font-size: 0.28rem;
    line-height: 0.19rem;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #0f0;
    &.en {
      display: none;
    }
  }

  .place-container {
    position: absolute;
    font-family: "HarmonyOS Sans SC";
    &.ger {
      left: 30%;
      top: 48%;
      .place-line {
        top: 0;
        left: 0.05rem;
        rotate: -101deg;
        &.show {
          height: 18.8vw;
        }
      }
    }
    &.sh {
      left: 85%;
      top: 38%;
      .place-line {
        top: 0.05rem;
        left: -0.05rem;
        rotate: 43deg;
        transform-origin: right top;
        &.show {
          height: 13.5vw;
        }
      }
    }
    &.hz {
      left: 88%;
      top: 51.5%;
      .place-line {
        top: 0.05rem;
        left: -0.05rem;
        rotate: 90deg;
        transform-origin: right top;
        &.show {
          height: 13.2vw;
        }
      }
    }
    &.nb {
      left: 60.5%;
      top: 60%;
      .place-line {
        top: 0;
        left: 0.05rem;
        rotate: -112.5deg;
        &.show {
          height: 14.8vw;
        }
      }
    }
    .place-cn {
      font-size: 0.12rem;
      line-height: 0.16rem;
      color: #0f0;
      margin-bottom: 0.1rem;
    }
    .place-en {
      font-size: 0.1rem;
      line-height: 0.12rem;
      color: #fff;
    }
    .place-line {
      display: inline-block;
      width: 0.01rem;
      height: 0;
      background-color: #0f0;
      position: absolute;
      transform-origin: left top;
      transition: height 2s ease-in-out;
    }
  }
}

@media only screen and (max-width: 750px) {
  .globalization-module {
    .place-container {
      &.hz {
        top: 58.5%;
        .place-line {
          rotate: 114deg;
          &.show {
            height: 14.2vw;
          }
        }
      }
    }
  }
}
</style>
