export default {
  section1: {
    subtitle: "INTELLIGENT DRIVING OPERATION SYSTEM",
  },
  section2: {
    title: "Technical Value",

    feature1Title: "Track speeding coaching",
    feature1Content:
      "Provide users with remote track training courses and real-time 5G online guidance and teaching",
    feature2Title: "Low-speed valet parking",
    feature2Content: "Unmanned valet parking in low-speed scenarios",
    feature3Title: "Parallel world",
    feature3Content:
      "Provide users with a real view of the vehicle while ensuring user privacy",
    feature4Title: "Recharging portal",
    feature4Content:
      "Park the vehicle at the designated place and charge it with the automatic charging robot",
    feature5Title: "Special operations",
    feature5Content:
      "Support remote operations in dangerous scenarios such as ports and mines, greatly improving operation efficiency and safety",
    feature6Title: "Situation retrieval",
    feature6Content:
      "Realize remote recording of intervention scenarios in combination with ROBO Galaxy, and send data back to the simulation cloud platform for algorithm optimization",
    feature7Title: "Remote diagnostics",
    feature7Content:
      "Detect prospective faults in moving vehicles, identify vehicle problems and alert users",
    feature8Title: "Worry-free travel",
    feature8Content: "Create an exclusive personal travel service",
  },

  section3: {
    title: "Cabin display",
  },

  section4: {
    title: "Capability introduction",
    feature1:
      "The cloud cabin driver, upon receiving the user request, can briefly obtain the vehicle driving permission so that it can control the vehicle at the cloud cabin to complete unmanned parking/charging/moving, etc. in low-speed scenarios.",
    feature2:
      "As an advanced function of cloud driving, the cloud cabin driver can supervise the operation of the automatic driving system instead of the user, and can form a double guarantee together with the automatic driving in scenarios such as danger prediction/complex road conditions/risk control. so that the driver does not need to do anything.",
    feature3:
      "Cloud guardian is an advanced vehicle control solution by cloud cabin. It integrates the regulation and control functions of the automatic driving system to allow the vehicle to do decision-making including semi-automatic obstacle avoidance, extrication, and route changes, realizing multi-channel concurrency and more efficient and safe remote vehicle control.",
  },

  section5: {
    title: "Technical advantages",
    avtTitle1: "High Security",
    avtContent1:
      "Encrypted vehicle control, defense-in-depth security design, and a complete set of Alibaba Cloud security products. 「Intelligent Driving System」 system-level protection and 3-level authentication. The vehicle and cloud jointly monitors link stability, with MRM 「minimum risk management」 redundancy configured at vehicle side, ensuring safe stop of vehicle independent of the network.",
    avtTitle2: "Data Compliance",
    avtContent2:
      "System-level data masking, and real-time uploading of compliance images 「human faces and vehicle license plates in videos and images transmitted from the vehicle will be blotted out」.",
    avtTitle3: "Low Delay",
    avtContent3:
      "5G network ensures millisecond-level response of vehicle control and images.",
  },
};
