<template>
  <div class="header-container" ref="header_container">
    <div class="pc-header">
      <div class="logo" @click="logoClick">
        <img
          src="http://ad4-static.lotuscars.com.cn/webapp/common/header_logo.png"
          class="icon"
        />
      </div>
      <navigation-bar />
      <!-- <v-select
        class="lang"
        :clearable="false"
        :options="localeOptions"
        :searchable="false"
        v-model="lacaleModel"
      >
      </v-select> -->
      <div class="lang-change-btn" @click="changeLang">
        <div :class="['cn', { active: locale === 'cn' }]">CN</div>
        <i class="split-line"></i>
        <div :class="['en', { active: locale === 'en' }]">EN</div>
      </div>
    </div>
    <div class="mobile">
      <div class="logo-img-container" @click="homeClick">
        <img
          src="http://ad4-static.lotuscars.com.cn/webapp/common/header_logo.png"
          alt=""
        />
      </div>
      <div class="mobile-right">
        <div class="lang-change-btn" @click="changeLang">
          <div :class="['cn', { active: locale === 'cn' }]">CN</div>
          <i class="split-line"></i>
          <div :class="['en', { active: locale === 'en' }]">EN</div>
        </div>
        <div class="menu-img-container" @click="changeMenu">
          <img
            src="http://ad4-static.lotuscars.com.cn/webapp/common/menu_button.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div
      class="mobile-menu"
      :style="{
        // '--width': mobileMenuShowStatus ? '100vw' : '0',
        '--opacity': mobileMenuShowStatus ? '100%' : '0',
      }"
      ref="mobile_menu"
    >
      <!-- <div class="mobile-menu-header">
        <img src="~@/assets/img/close.png" class="close" @click="closeMenu" />
      </div> -->
      <div class="mobile-menu-list">
        <ul>
          <li @click="() => menuClick('home')">LOTUS ROBOTICS</li>
          <li
            v-for="(menu, index) in menuList"
            :key="menu.name"
            @click="() => menuClick(index)"
          >
            {{ menu.name }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationBar from "./NavigationBar.vue";
import { mapState, mapMutations, mapGetters } from "vuex";
import "animate.css";

export default {
  name: "Header",

  data() {
    return {
      mobileMenuShowStatus: false,
    };
  },

  components: {
    NavigationBar,
  },

  computed: {
    ...mapState("header", {
      activeMenu: (state) => state.activeMenu,
    }),
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
    // ...mapGetters("app", {
    //   localeOptions: "localeOptions",
    // }),
    ...mapGetters("header", {
      menuList: "menuList",
    }),
    lacaleModel: {
      get() {
        return this.localeOptions.filter(
          (item) => item.value === this.locale
        )[0];
      },
    },
  },
  mounted() {
    this.$refs.mobile_menu.addEventListener("transitionend", this.changeWidth);
  },

  beforeDestroy() {
    this.$refs.mobile_menu.removeEventListener(
      "transitionend",
      this.changeWidth
    );
  },
  methods: {
    ...mapMutations("app", {
      setLocale: "SET_LOCALE",
    }),
    logoClick() {
      if (this.activeMenu !== "Home") {
        this.$router.push({ name: "Home" });
      }
    },
    changeMenu() {
      this.mobileMenuShowStatus = !this.mobileMenuShowStatus;
      if (this.mobileMenuShowStatus) {
        this.$refs.mobile_menu.style.width = "100vw";
        document.body.addEventListener("touchmove", this.preventDefault, {
          passive: false,
        });
      } else {
        document.body.removeEventListener("touchmove", this.preventDefault);
      }
    },
    preventDefault(event) {
      event.preventDefault();
    },
    homeClick() {
      this.$router.push({ name: "Home" });
      this.mobileMenuShowStatus = false;
      document.body.removeEventListener("touchmove", this.preventDefault);
    },
    menuClick(index) {
      if (this.mobileMenuShowStatus) {
        if (index === "home") {
          this.homeClick();
          return;
        }
        this.$router.push({ name: this.menuList[index].router });
        this.mobileMenuShowStatus = false;
        document.body.removeEventListener("touchmove", this.preventDefault);
      }
    },
    changeWidth() {
      if (!this.mobileMenuShowStatus) {
        this.$refs.mobile_menu.style.width = 0;
      }
    },
    changeLang() {
      if (this.locale === "en") {
        this.setLocale("cn");
        this.$i18n.locale = "cn";
      } else if (this.locale === "cn") {
        this.setLocale("en");
        this.$i18n.locale = "en";
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/common.less";
@import "~@/assets/css/header.less";
.header-container {
  position: fixed;
  top: 0;
  font-size: 0;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  z-index: 999999;

  .pc-header {
    width: 100%;
    border-bottom: solid 1px @primary-color;
    display: flex;
    align-items: center;
  }

  .logo {
    position: relative;
    display: inline-block;
    height: @header-height;
    line-height: @header-height;
    margin: 0 0.5rem 0 0.7rem;
    .menu-active-animation();
    .icon {
      width: 0.6rem;
      height: 0.2rem;
      vertical-align: middle;
    }

    &:hover {
      transform: scale(1.2);
    }
    vertical-align: top;
  }

  .lang {
    display: inline-block;
    width: 1rem;
    height: 0.3rem;
    background-color: rgba(0, 0, 0, 0.6);
    // background-color: #fff;
    border-color: @primary-color;
    color: @primary-color;
    font-size: 12px;
    margin-left: 0.8rem;
    --vs-dropdown-min-width: 160px;
    --vs-dropdown-max-height: 350px;
    --vs-dropdown-toggle-height: 10px;
    --vs-dropdown-option--active-bg: #000;
    --vs-dropdown-option--active-color: #0f0;
    --vs-dropdown-bg: #282c34;
    --vs-dropdown-option-color: #fff;
    --vs-dropdown-min-width: 0;
    --vs-selected-color: #0f0;
    ::v-deep .vs__dropdown-toggle {
      height: 100%;
      background-color: #000;
      border-color: #0f0;
      color: #0f0;
      .vs__selected {
        height: 100%;
      }
      .vs__open-indicator {
        fill: #0f0;
      }
    }
  }

  .lang-change-btn {
    position: relative;
    font-family: "HarmonyOS Sans SC";
    font-weight: 400;
    font-size: 0.14rem;
    color: #686868;
    width: 0.45rem;
    height: 0.3rem;
    margin-left: 0.4rem;
    .active {
      color: #0f0;
    }
    .cn {
      position: absolute;
      top: 5%;
      left: 5%;
    }
    .en {
      position: absolute;
      bottom: 5%;
      right: 5%;
    }
    .split-line {
      width: 0.01rem;
      height: 0.36rem;
      background-color: #0f0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(30deg);
    }
  }

  .mobile,
  .mobile-menu {
    width: 0%;
    height: 0%;
    overflow: hidden;
    position: absolute;
  }

  @media only screen and (max-width: 750px) {
    .header-container {
      border-bottom: solid 0px @primary-color;
    }
    .pc-header {
      display: none;
    }

    .mobile {
      background-color: #000;
      width: 100vw;
      height: @mobile-header-height;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      .mobile-right {
        display: flex;
        flex-direction: row;
        .lang-change-btn {
          margin-right: 0.2rem;
          font-size: 0.16rem;
          width: 0.57rem;
        }
      }
      .logo-img-container {
        width: 1.43rem;
        margin-left: 0.48rem;
        img {
          width: 80%;
        }
      }
      .menu-img-container {
        width: 0.46rem;
        margin-right: 0.4rem;
        img {
          width: 100%;
        }
      }
    }

    .mobile-menu {
      position: absolute;
      width: 0;
      opacity: var(--opacity);
      height: 100vh;
      top: @mobile-header-height;
      left: 0;
      box-sizing: border-box;
      background: rgba(0, 0, 0, 0.7);
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      -moz-backdrop-filter: blur(10px);
      transition: opacity 1.2s;
      .mobile-menu-header {
        width: 100%;
        height: 0.7rem;
        color: #fff;
        position: relative;
        overflow: hidden;
        .close {
          width: 0.5rem;
          height: 0.5rem;
          position: absolute;
          top: 0.2rem;
          right: 0.2rem;
        }
      }
      .mobile-menu-list {
        height: 50%;
        width: 100%;
        margin-top: 2.14rem;
        ul {
          padding: 0;
          list-style: none;
          li {
            font-family: "HarmonyOS Sans SC";
            font-size: 0.32rem;
            width: 100vw;
            font-weight: 400;
            line-height: 0.38rem;
            color: @primary-color;
            text-align: center;
            margin-bottom: 0.78rem;
          }
        }
      }
    }
  }
}
</style>
