export default {
  subscribe: "Subscribe",
  solution: "TECHNICAL SOLUTIONS",
  about1: "ABOUT",
  about2: "LOTUS ROBOTICS",
  team: "Team Introduction",
  joinUs: "Join Us",
  contactUs: "Contact Us",
  newCreature: "ROBO Cube",
  copyRight: "All rights reserved",
  filling: "Zhejiang ICP Filing No. 2022012519",
  contact: "CONTACT US",
};
