<template>
  <div class="robo-matrix-advantage-section">
    <div class="headline">
      <h2
        class="decorator"
        data-aos="fade-up"
        data-aos-offset="120"
        data-aos-delay="100"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        ADVANTAGES
      </h2>
      <h1
        class="title"
        data-aos="fade-up"
        data-aos-offset="120"
        data-aos-delay="100"
        data-aos-duration="1200"
        data-aos-once="true"
      >
        {{ $t("matrix.section5.title") }}
      </h1>
    </div>

    <div class="content">
      <div
        class="advantage"
        data-aos="fade-up"
        data-aos-offset="120"
        data-aos-delay="150"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        <img
          class="icon"
          src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/matrix/shield_icon.png"
        />
        <div class="desc">
          <h1 class="title">{{ $t("matrix.section5.avtTitle1") }}</h1>
          <p class="text">{{ $t("matrix.section5.avtContent1") }}</p>
        </div>
      </div>

      <div
        class="advantage"
        data-aos="fade-up"
        data-aos-offset="120"
        data-aos-delay="200"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        <img
          class="icon"
          src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/matrix/compliance_icon.png"
        />
        <div class="desc">
          <h1 class="title">{{ $t("matrix.section5.avtTitle2") }}</h1>
          <p class="text">{{ $t("matrix.section5.avtContent2") }}</p>
        </div>
      </div>

      <div
        class="advantage"
        data-aos="fade-up"
        data-aos-offset="120"
        data-aos-delay="250"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        <img
          class="icon"
          src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/matrix/latency_icon.png"
        />
        <div class="desc">
          <h1 class="title">{{ $t("matrix.section5.avtTitle3") }}</h1>
          <p class="text">{{ $t("matrix.section5.avtContent3") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdvantageSection",
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/common.less";
@import "~@/assets/css/font.css";
@import "~@/assets/css/image.less";
@design_width: 1280;
.robo-matrix-advantage-section {
  // height: calc(100vw * 720 / @design_width);
  height: calc(100vh - 0.5rem);
  background-color: #151618;
  background-image: url("@{advantageBg}");
  background-size: 11.82rem 9.32rem;
  background-repeat: no-repeat;
  background-position: right bottom;
  .headline {
    margin-left: 1.73rem;
    padding-top: 1.2rem;
    .decorator {
      margin: 0 0 -0.26rem 0;
      padding: 0;
      font-family: "HarmonyOS Sans SC Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 0.5rem;
      line-height: 0.48rem;
      letter-spacing: 0.01em;
      background: linear-gradient(
        98.62deg,
        #364242 3.92%,
        #2b2f37 48.34%,
        #21262b 96.58%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    .title {
      margin: 0 0 0 0.49rem;
      padding: 0;
      font-family: "HarmonyOS Sans SC Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 0.28rem;
      line-height: 0.33rem;
      letter-spacing: 0.01em;
      color: @primary-color;
    }
  }

  .content {
    font-size: 0;
    margin: 0.8rem 0 0 1.88rem;
  }

  .advantage {
    margin-bottom: 0.3rem;
    .icon {
      width: 0.24rem;
      height: 0.24rem;
      margin-right: 0.1rem;
      vertical-align: top;
    }
    .desc {
      display: inline-block;
      .title {
        margin: 0;
        padding: 0;
        font-family: "HarmonyOS Sans SC";
        font-style: normal;
        font-weight: 700;
        font-size: 0.2rem;
        line-height: 0.28rem;
        letter-spacing: 0.01em;
        color: @primary-color;
      }

      .text {
        margin-top: 0.1rem;
        width: 7rem;
        font-family: "HarmonyOS Sans SC";
        font-style: normal;
        font-weight: 400;
        font-size: 0.14rem;
        line-height: 0.22rem;
        letter-spacing: 0.065em;
        color: #9393a0;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .robo-matrix-advantage-section {
    height: 11.97rem;
    background-color: #151618;
    background-image: url("@{mbAdvantageBg}");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: right bottom;

    .headline {
      margin-left: 1.02rem;
      padding-top: 1.2rem;
    }

    .content {
      font-size: 0;
      margin: 0.63rem 0 0 1.33rem;
    }

    .advantage {
      margin-bottom: 0.3rem;
      .icon {
        width: 0.24rem;
        height: 0.24rem;
        margin-right: 0.1rem;
        vertical-align: top;
      }
      .desc {
        display: inline-block;
        .title {
          margin: 0;
          padding: 0;
          font-size: 0.24rem;
          line-height: 0.28rem;
        }

        .text {
          width: 5.17rem;
          font-size: 0.2rem;
          line-height: 0.32rem;
        }
      }
    }
  }
}
</style>
