<template>
  <div class="robo-matrix-feature-page">
    <div class="headline">
      <h2
        class="decorator"
        data-aos="fade-up"
        data-aos-offset="120"
        data-aos-delay="100"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        INTRODUCTION
      </h2>
      <h1
        class="title"
        data-aos="fade-up"
        data-aos-offset="120"
        data-aos-delay="200"
        data-aos-duration="1200"
        data-aos-once="true"
      >
        {{ $t("matrix.section4.title") }}
      </h1>
    </div>

    <div
      class="features"
      data-aos="zoom-in"
      data-aos-offset="120"
      data-aos-delay="300"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <feature-card
        class="custom"
        order-num="01"
        title-cn="云代驾"
        title-en="Cloud Driving"
        :content="$t('matrix.section4.feature1')"
      />
      <feature-card
        class="custom"
        order-num="02"
        title-cn="云守护"
        title-en="Cloud Guardian"
        :content="$t('matrix.section4.feature2')"
      />
      <feature-card
        class="custom"
        order-num="03"
        title-cn="云引导"
        title-en="Cloud Boot"
        :content="$t('matrix.section4.feature3')"
      />
    </div>
  </div>
</template>

<script>
import FeatureCard from "./FeatureCard.vue";
export default {
  name: "FeatureSection",
  components: {
    FeatureCard,
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/common.less";
@import "~@/assets/css/font.css";
@import "~@/assets/css/image.less";
.robo-matrix-feature-page {
  background-image: url("@{featureBg}");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: calc(100vh - 0.5rem);
  min-height: 7.2rem;
  .headline {
    margin-left: 1.33rem;
    padding-top: 1.22rem;
    .decorator {
      margin: 0 0 -0.26rem 0;
      padding: 0;
      font-family: "HarmonyOS Sans SC Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 0.5rem;
      line-height: 0.48rem;
      letter-spacing: 0.01em;
      background: linear-gradient(
        98.62deg,
        #364242 3.92%,
        #2b2f37 48.34%,
        #21262b 96.58%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    .title {
      margin: 0 0 0 0.49rem;
      padding: 0;
      font-family: "HarmonyOS Sans SC Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 0.28rem;
      line-height: 0.33rem;
      letter-spacing: 0.01em;
      color: @primary-color;
    }
  }

  .features {
    width: 9.02rem;
    margin: 0.76rem auto 0 auto;
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 750px) {
  .robo-matrix-feature-page {
    background-image: url("@{mbFeatureBg}");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 13rem;
    .headline {
      padding-top: 0.8rem;
    }
    .features {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .custom {
        margin-bottom: 0.4rem;
      }
    }
  }
}
</style>
