<template>
  <svg
    width="572"
    height="1071"
    viewBox="0 0 572 1071"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
  >
    <path
      class="mobile-path"
      d="M538.148 1048.57C385.395 811.538 263.866 821.198 85.8966 642.135C-56.4791 498.885 -33.8897 291.735 -4.79804 206.067C21.9111 121.119 131.429 -42.7755 355.829 -18.7755"
      stroke="url(#paint0_linear_1176_1042)"
      stroke-width="80"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1176_1042"
        x1="918.866"
        y1="854.901"
        x2="260.88"
        y2="-127.216"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00FF00" />
        <stop offset="0.977696" stop-color="#00FFC2" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "RoadMobileSvg",

  props: {
    width: Number,
    height: Number,
    strokeWidth: Number,
  },

  mounted() {
    const path = document.querySelector(".mobile-path");
    let pathLength = path.getTotalLength();
    path.style.strokeDasharray = pathLength;
    path.style.strokeDashoffset = pathLength;
    setTimeout(() => {
      path.style.transition = "stroke-dashoffset 2s linear";
      path.style.strokeDashoffset = 2 * pathLength;
    }, 200);
  },
};
</script>
