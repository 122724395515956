<template>
  <div
    class="introduce-div"
    data-aos="zoom-in"
    data-aos-offset="120"
    data-aos-delay="100"
    data-aos-duration="1000"
    data-aos-once="true"
  >
    <section class="introduce-section">
      <div
        data-aos="fade-up"
        data-aos-offset="120"
        data-aos-delay="100"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        <h2 :class="{ decorator: true, en: locale === 'en' }">
          FOR THE DRIVERS
        </h2>
      </div>
      <div
        data-aos="fade-up"
        data-aos-offset="120"
        data-aos-delay="200"
        data-aos-duration="1200"
        data-aos-once="true"
      >
        <h1 :class="{ headline: true, en: locale === 'en' }">
          {{ $t("home.robotics") }}
        </h1>
      </div>
      <div
        class="content"
        data-aos="fade-in"
        data-aos-offset="0"
        data-aos-delay="100"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        <p v-if="locale === 'cn'">
          路特斯机器人(LOTUS
          ROBOTICS)致力于打造最佳智能驾驶实践平台，并以“全面赋能机器人的时代”作为企业使命。
          率先提出“以终为始”的智能驾驶开发策略，并通过更好的硬件、更好的软件、更好的云来实现。
        </p>
        <p v-else :class="{ 'content-en': locale === 'en' }">
          Committed to creating the best intelligent driving practice platform,
          Lotus Robotics pursues to "fully empower the era with robotics". For
          this purpose, Lotus Robotics takes the lead in putting forward the
          development strategy "to begin with the end in mind" for intelligent
          driving, which will be realized through better hardware, software, and
          clouds.
        </p>
        <p v-if="locale === 'cn'" class="mrg-top">
          同时，在当下的智能化时代，路特斯机器人创新提出用“接管里程”和“覆盖里程”替代SAE智能驾驶分级系统，加速高级别智能驾驶的商业化落地进程。
          除此之外，团队未来还将为企业提供包含工程定制化服务、高级别智能驾驶平台化软件、智能云工具链、智能运营等全栈式解决方案。
        </p>
        <p v-else :class="{ 'content-en': locale === 'en', 'mrg-top': true }">
          At the meantime, in the current intelligent era, Lotus Robotics also
          proposes to replace the SAE intelligent driving grading system with
          the "Mileage Per Intervention (MPI)" and "Coverage Mileage Index
          (CMI)", aiming to accelerate the commercialization of advanced
          intelligent driving. In addition, the team of Lotus Robotics is about
          to provide enterprises with full-stack solutions including engineering
          customized services, advanced intelligent driving platform software,
          intelligent cloud tool chain, and intelligent operation.
        </p>
      </div>
    </section>
    <!-- <div
      class="intro-img"
      data-aos="fade-down-left"
      data-aos-offset="120"
      data-aos-delay="100"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <img src="../../../assets/img/lr_intro.png" />
    </div> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "IntroRobo",
  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/common.less";
@import "~@/assets/css/font.css";
@import "~@/assets/css/image.less";

.introduce-div {
  position: relative;
  background: url("@{pcLrIntroBg}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.intro-img {
  height: 7.2rem;
  position: absolute;
  display: inline-block;
  right: 0;
}

.introduce-section {
  display: inline-block;
  @mrg-left: 2.1rem;
  background-color: #000;
  height: 7.2rem;

  p {
    margin: 0;
    padding: 0;
  }

  .decorator {
    margin: 0 0 -0.15rem 2rem;
    padding: 1.24rem 0 0 0;
    font-family: "HarmonyOS Sans SC Black";
    font-style: normal;
    font-weight: 700;
    font-size: 0.5rem;
    line-height: 0.48rem;
    letter-spacing: 0.01em;
    background: linear-gradient(
      98.62deg,
      #364242 3.92%,
      #2b2f37 48.34%,
      #21262b 96.58%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .headline {
    margin: 0 0 0 @mrg-left;
    padding: 0;
    font-family: "HarmonyOS Sans SC Black";
    font-style: normal;
    font-weight: 700;
    font-size: 0.28rem;
    line-height: 0.19rem;
    letter-spacing: 0.01em;
    color: @primary-color;
  }

  .summary {
    margin: 0.39rem 0 0.31rem @mrg-left;
    font-family: "HarmonyOS Sans SC";
    font-style: normal;
    font-weight: 400;
    font-size: 0.18rem;
    line-height: 0.28rem;
    letter-spacing: 0.01em;
    color: #ffffff;
  }

  .content {
    width: 5.81rem;
    margin-left: @mrg-left;
    font-family: "HarmonyOS Sans SC";
    font-style: normal;
    font-weight: 400;
    font-size: 0.14rem;
    line-height: 0.22rem;
    letter-spacing: 0.03em;
    color: #646368;
    margin-top: 0.69rem;

    .mrg-top {
      margin-top: 0.2rem;
    }
  }
}

@media only screen and (max-width: 750px) {
  .introduce-div {
    position: relative;
    background: url("@{mbLrIntroBg}");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 10.8rem;
  }
  .introduce-section {
    display: inline-block;
    background-color: #000;
    height: 1.08rem;

    .decorator {
      margin: 0 0 -0.15rem 0.97rem;
      padding: 1.4rem 0 0 0;
      font-family: "HarmonyOS Sans SC Black";
      font-style: normal;
      font-weight: 700;
      font-size: 0.5rem;
      line-height: 0.48rem;
      letter-spacing: 0.01em;
      background: linear-gradient(
        98.62deg,
        #364242 3.92%,
        #2b2f37 48.34%,
        #21262b 96.58%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      &.en {
        display: none;
      }
    }

    .decorator-en {
      display: none;
    }

    .headline {
      margin: 0 0 0 1.1rem;
      padding: 0;
      font-family: "HarmonyOS Sans SC Black";
      font-style: normal;
      font-weight: 700;
      font-size: 0.28rem;
      line-height: 0.19rem;
      letter-spacing: 0.01em;
      color: @primary-color;
      &.en {
        background: linear-gradient(269.44deg, #00ff00 -4%, #00ffc2 102.4%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        font-size: 0.36rem;
        line-height: 0.48rem;
        margin-left: 0.8rem;
        margin-top: 1.38rem;
      }
    }

    .content {
      width: 5.9rem;
      margin-left: 0.8rem;
      font-family: "HarmonyOS Sans SC";
      font-style: normal;
      font-weight: 400;
      font-size: 0.24rem;
      line-height: 0.34rem;
      letter-spacing: 0.03em;
      color: #646368;
      margin-top: 0.69rem;

      .mrg-top {
        margin-top: 0.2rem;
      }
    }

    .content-en {
      font-size: 0.2rem;
    }
  }
}
</style>
