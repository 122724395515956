<template>
  <section class="roboverse-eighth-mobile-section">
    <div
      class="headline"
      data-aos="fade-down"
      data-aos-easing="ease-out-cubic"
      data-aos-offset="0"
      data-aos-delay="100"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <h1 :class="'bg-text ' + locale">{{ $t("verse.section8.title") }}</h1>
      <h2 class="title" v-show="locale === 'cn'">覆盖更多场景</h2>
    </div>
    <div
      class="desc"
      data-aos="fade-left"
      data-aos-easing="ease-out-cubic"
      data-aos-offset="0"
      data-aos-delay="100"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      {{ $t("verse.section8.desc") }}
    </div>

    <div
      class="ipad"
      data-aos="fade-up"
      data-aos-easing="ease-out-cubic"
      data-aos-offset="0"
      data-aos-delay="200"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <video
        :src="playSrc"
        type="video/mp4"
        class="video"
        autoplay
        loop
        muted
      ></video>
    </div>

    <ul class="menu">
      <li
        :class="item.active ? 'item active' : 'item'"
        v-for="(item, index) in menuList"
        :key="index"
        @click="menuClick(index)"
        @mouseover="mouseoverHandler(index)"
        @mouseleave="mouseoutHandler(index)"
      >
        <div :class="'en-name ' + locale">{{ item.enName }}</div>
        <div class="cn-name" v-if="locale === 'cn'">{{ item.cnName }}</div>
      </li>
    </ul>
  </section>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "RoboverseEighthMobileSection",

  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },

  data() {
    const scene1Video =
      "http://ad4-static.lotuscars.com.cn/webapp/pc/video/verse/rbvs_scene_1.mp4";
    const scene2Video =
      "http://ad4-static.lotuscars.com.cn/webapp/pc/video/verse/rbvs_scene_2.mp4";
    const scene3Video =
      "http://ad4-static.lotuscars.com.cn/webapp/pc/video/verse/rbvs_scene_3.mp4";
    const scene4Video =
      "http://ad4-static.lotuscars.com.cn/webapp/pc/video/verse/rbvs_scene_4.mp4";
    const scene5Video =
      "http://ad4-static.lotuscars.com.cn/webapp/pc/video/verse/rbvs_scene_5.mp4";
    const scene6Video =
      "http://ad4-static.lotuscars.com.cn/webapp/pc/video/verse/rbvs_scene_6.mp4";
    return {
      activeIndex: 0,
      playSrc: scene1Video,
      menuList: [
        {
          enName: "CONTINUOUS LANE CHANGE IN MULTIPLE LANES",
          cnName: "多车道连续变道",
          active: true,
          video: scene1Video,
        },
        {
          enName: "AUTOMATIC PARKING",
          cnName: "自动泊车",
          active: false,
          video: scene2Video,
        },
        {
          enName: "ROUNDABOUT SCENE",
          cnName: "环岛场景",
          active: false,
          video: scene3Video,
        },
        {
          enName: "AUTOMATIC LANE CHANGE TO BYPASS MERGING TRAFFIC",
          cnName: "自动道汇入避让",
          active: false,
          video: scene4Video,
        },
        {
          enName: "UNPROTECTED LEFT TURN",
          cnName: "无保护左转",
          active: false,
          video: scene5Video,
        },
        {
          enName: "VIADUCT OFF-RAMP",
          cnName: "高架下匝道",
          active: false,
          video: scene6Video,
        },
      ],
      rotateAngle: 0,
      rotateFlag: true,
    };
  },

  methods: {
    menuClick(index) {
      if (index !== this.activeIndex) {
        this.menuList[this.activeIndex].active = false;
        this.menuList[index].active = true;
        this.activeIndex = index;
        this.playSrc = this.menuList[index].video;
      }
    },
    mouseoverHandler(index) {
      if (index !== this.activeIndex) {
        this.menuList[index].active = true;
        this.menuList[this.activeIndex].active = false;
      }
    },
    mouseoutHandler(index) {
      this.menuList[index].active = false;
      this.menuList[this.activeIndex].active = true;
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/image.less";
@import "~@/assets/css/font.css";
.roboverse-eighth-mobile-section {
  position: relative;

  .headline {
    padding-top: 1.47rem;
    margin-left: 0.94rem;
    h1,
    h2 {
      margin: 0;
      padding: 0;
    }
    .bg-text {
      font-family: "HarmonyOS Sans SC Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 0.32rem;
      line-height: 0.45rem;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      background: linear-gradient(
        98.62deg,
        #364242 3.92%,
        #2b2f37 48.34%,
        #21262b 96.58%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;

      &.en {
        font-size: 0.32rem;
        line-height: 0.48rem;
        background-image: linear-gradient(
          269.44deg,
          #00ff00 -4%,
          #00ffc2 102.4%
        );
      }
    }

    .title {
      font-family: "HarmonyOS Sans SC Bold";
      font-style: normal;
      font-weight: 900;
      font-size: 0.28rem;
      line-height: 0.33rem;
      letter-spacing: 0.065em;
      color: #00ff00;
    }
  }

  .desc {
    font-family: "HarmonyOS Sans SC Thin";
    font-style: normal;
    font-weight: 400;
    font-size: 0.18rem;
    line-height: 0.25rem;
    letter-spacing: 0.08em;
    color: #898989;
    margin: 0.39rem 0 0 0.94rem;
    width: 5.8rem;
  }

  .menu {
    margin: 0 auto 0 auto;
    padding: 0;
    list-style: none;
    width: 6rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .item {
      width: 2rem;
      margin-top: 0.25rem;
      text-align: center;
      cursor: pointer;
      .en-name {
        font-family: "HarmonyOS Sans SC";
        font-style: normal;
        font-weight: 400;
        font-size: 0.1rem;
        line-height: 0.22rem;
        letter-spacing: 0.065em;
        text-transform: uppercase;
        color: #646464;
        &.en {
          margin-bottom: 0.3rem;
        }
      }

      &.active {
        .en-name {
          background: linear-gradient(269.44deg, #00ff00 -4%, #00ffc2 102.4%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
      }

      .cn-name {
        font-family: "HarmonyOS Sans SC";
        font-style: normal;
        font-weight: 700;
        font-size: 0.14rem;
        line-height: 0.25rem;
        letter-spacing: 0.065em;
        color: #ffffff;
        margin-top: 0.08rem;
      }
    }
  }

  .ipad {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5.74rem;
    height: 3.5rem;
    margin: 0.45rem auto 0.3rem auto;
    background-image: url("@{rbvsIpad2}");
    background-size: contain;
    background-repeat: no-repeat;
    .video {
      width: 5.16rem;
      height: 2.91rem;
    }
  }
}
</style>
