<template>
  <div class="overview-section">
    <div class="main-text">
      <div
        class="desc-text top"
        data-aos="zoom-in-down"
        data-aos-offset="0"
        data-aos-delay="100"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        <h3>@2023</h3>
        <h3>CODE DIFFERENT</h3>
      </div>
      <div
        class="head-text"
        data-aos="fade-in"
        data-aos-delay="200"
        data-aos-duration="1500"
        data-aos-once="true"
      >
        <h1 class="headline">ROBO NEWS</h1>
      </div>
    </div>

    <div
      class="desc-text bottom"
      data-aos="zoom-in-right"
      data-aos-offset="0"
      data-aos-delay="200"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <h3 style="text-transform: uppercase">To Boost the Robotics Era</h3>
    </div>

    <div class="icon">
      <SlideIcon />
    </div>

    <div ref="pathContainer" class="path-container">
      <PathSvg :width="width" :height="height" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SlideIcon from "@/components/SlideIcon.vue";
import PathSvg from "./PathSvg.vue";

export default {
  name: "OverviewSection",
  data() {
    return {
      width: 0,
      height: 0,
    };
  },
  components: {
    SlideIcon,
    PathSvg,
  },
  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },
  mounted() {
    this.width = this.$refs.pathContainer.offsetWidth;
    this.height = this.$refs.pathContainer.offsetHeight;
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/common.less";
@import "~@/assets/css/image.less";
.overview-section {
  background-image: url("@{robonewsBg}");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: calc(100vh - 0.5rem);
  min-height: 7.12rem;
  width: 100vw;
  overflow: hidden;
  position: relative;

  .desc-text {
    font-family: "HarmonyOS Sans SC";
    font-style: normal;
    font-weight: 400;
    font-size: 0.1rem;
    letter-spacing: 0.01em;
    color: #ffffff;
    &.top {
      position: absolute;
      line-height: 0.12rem;
      margin: 0.28rem 0 0 0.85rem;
    }
    &.bottom {
      position: absolute;
      line-height: 0.19rem;
      margin-left: 0.85rem;
      bottom: 0.65rem;
    }
  }

  .main-text {
    margin-top: 2.38rem;
    margin-bottom: 2.13rem;
    .head-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .headline {
        font-family: "HarmonyOS Sans SC Black";
        text-transform: uppercase;
        font-style: normal;
        font-weight: 900;
        font-size: 0.6rem;
        line-height: 0.75rem;
        letter-spacing: 0.1em;
        background: linear-gradient(269.44deg, #00ff00 -4%, #00ffc2 102.4%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        margin-top: 1.05rem;
        padding: 0;
      }
    }
  }

  .path-container {
    position: absolute;
    right: 0;
    top: 0;
    width: 80%;
    height: 100%;
    text-align: right;
  }
}

@media only screen and (max-width: 750px) {
  .overview-section {
    height: calc(100vh - 0.9rem);
    background: url("@{mbRobonewsBg}") no-repeat;
    background-size: cover;

    .path-container {
      left: -50%;
    }

    .desc-text {
      font-size: 0.14rem;
      &.top {
        margin: 0;
        left: 0.55rem;
        top: 7.31rem;
        position: absolute;
      }
      &.bottom {
        margin: 0;
        position: absolute;
        left: 0.55rem;
        top: 10.58rem;
      }
    }
    .main-text {
      margin-top: 2.98rem;
      .head-text {
        .headline {
          font-size: 0.55rem;
          line-height: 1.15;
          text-align: center;
        }
        .subhead {
          font-size: 0.4rem;
          line-height: 1.15;
        }
      }
    }
    .icon {
      display: none;
    }
  }
}
</style>
