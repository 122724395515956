<template>
  <section class="roboverse-sixth-section">
    <div
      class="headline"
      data-aos="fade-right"
      data-aos-easing="ease-out-cubic"
      data-aos-offset="0"
      data-aos-delay="100"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <h1 class="bg-text" v-if="locale == 'cn'">
        {{ $t("verse.section6.decoration") }}
      </h1>
      <h2 class="title">{{ $t("verse.section6.title") }}</h2>
    </div>

    <div
      class="container"
      data-aos="fade-up"
      data-aos-easing="ease-out-cubic"
      data-aos-offset="0"
      data-aos-delay="100"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <div
        :class="'card ' + item.enTitle"
        v-for="(item, index) in $t('verse.section6.list')"
        :key="index"
        @mouseover="mouseoverHandler(index)"
        @mouseleave="mouseleaveHandler(index)"
      >
        <div class="en-title">{{ item.enTitle }}</div>
        <div class="cn-title" v-show="locale === 'cn'">{{ item.cnTitle }}</div>

        <div class="hover">
          <div class="gradient"></div>
          <div class="border"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SixthSection",

  data() {
    return {
      list: [
        {
          enTitle: "HANGZHOU",
          cnTitle: "杭州",
          isHovered: false,
        },
        {
          enTitle: "SHANGHAI",
          cnTitle: "上海",
          isHovered: false,
        },
        {
          enTitle: "WUHAN",
          cnTitle: "武汉",
          isHovered: false,
        },
        {
          enTitle: "SUZHOU",
          cnTitle: "苏州",
          isHovered: false,
        },
      ],
    };
  },
  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },

  methods: {
    mouseoverHandler(index) {
      this.list[index].isHovered = true;
    },
    mouseleaveHandler(index) {
      this.list[index].isHovered = false;
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/font.css";
@import "~@/assets/css/image.less";
.roboverse-sixth-section {
  position: relative;
  height: calc(100vw / 1280 * 600);

  .headline {
    padding-top: 1.1rem;
    margin-left: 1.5rem;
    h1,
    h2 {
      margin: 0;
      padding: 0;
    }
    .bg-text {
      font-family: "HarmonyOS Sans SC Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 0.32rem;
      line-height: 0.45rem;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      background: linear-gradient(
        98.62deg,
        #364242 3.92%,
        #2b2f37 48.34%,
        #21262b 96.58%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    .title {
      font-family: "HarmonyOS Sans SC Bold";
      font-style: normal;
      font-weight: 900;
      font-size: 0.28rem;
      line-height: 0.33rem;
      letter-spacing: 0.065em;
      color: #00ff00;
    }
  }

  .container {
    display: flex;
    width: fit-content;
    margin: 0.72rem auto 0 auto;

    .card {
      position: relative;
      width: 2.19rem;
      height: 1.48rem;
      box-sizing: border-box;
      padding: 0.58rem 0 0 0;
      background: #252525;
      backdrop-filter: blur(10px);
      margin-right: 0.2rem;
      cursor: pointer;
      background-repeat: no-repeat;
      background-size: cover;

      &.HANGZHOU {
        background-image: url("@{hangzhouMap}");
      }
      &.SHANGHAI {
        background-image: url("@{shanghaiMap}");
      }
      &.WUHAN {
        background-image: url("@{wuhanMap}");
      }
      &.SUZHOU {
        background-image: url("@{suzhouMap}");
      }

      .en-title {
        font-family: "HarmonyOS Sans SC Bold";
        font-style: normal;
        font-weight: 900;
        font-size: 0.24rem;
        line-height: 0.35rem;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        background: linear-gradient(269.44deg, #00ff00 -4%, #00ffc2 102.4%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin-left: 0.18rem;
      }

      .cn-title {
        font-family: "HarmonyOS Sans SC";
        font-style: normal;
        font-weight: 400;
        font-size: 0.2rem;
        line-height: 0.35rem;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #ffffff;
        margin-left: 0.18rem;
      }

      &:hover {
        .hover {
          display: block;
        }
      }

      .hover {
        display: none;
        position: absolute;
        bottom: 0;
        width: 100%;
        .gradient {
          background: linear-gradient(
            350.79deg,
            rgba(0, 255, 0, 0.18) 8.53%,
            rgba(217, 217, 217, 0) 67.58%
          );
          width: 100%;
          height: 0.54rem;
        }
        .border {
          width: 100%;
          height: 0.03rem;
          background: linear-gradient(269.44deg, #00ff00 -4%, #00ffc2 102.4%);
        }
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .roboverse-sixth-section {
    height: 7.5rem;

    .headline {
      padding-top: 0.8rem;
      margin-left: 1rem;
    }

    .container {
      width: 4.7rem;
      margin: 1.13rem auto 0 auto;
      flex-wrap: wrap;
      justify-content: space-between;

      .card {
        margin-right: 0;
        margin-bottom: 0.21rem;
      }
    }
  }
}
</style>
