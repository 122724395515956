<template>
  <div class="news-main">
    <div class="news-main-left">
      <div class="news-main-left-title linear-text">ROBO NEWS</div>
      <div
        class="news-main-container"
        v-for="item in unExpandNewsList"
        :key="item.id"
      >
        <NewsCard
          :title="item.title"
          :content="item.content"
          :img="item.img"
          :url="item.url"
        />
      </div>
      <div
        v-if="!expandFlag"
        @click="expandFlag = true"
        class="news-button linear-text"
      >
        LOAD MORE STORIES
      </div>
      <div
        v-else
        class="news-main-container"
        v-for="item in expandNewsList"
        :key="item.id"
      >
        <NewsCard
          :title="item.title"
          :content="item.content"
          :img="item.img"
          :url="item.url"
        />
      </div>
    </div>
    <div class="news-main-right">
      <span class="shadow">TOP VIDEOS</span>
      <div class="head-line">
        <p class="linear-text">TOP VIDEOS</p>
        <i></i>
      </div>
      <div
        class="video-container"
        @click="
          open(
            'https://summit.aliyun.com/partner2022?spm=a2clf.27172419.J_2269535540.1.54a21005pbX6lH'
          )
        "
      >
        <img
          src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/news/news_top_video1.png"
          alt=""
        />
        <div :class="['video-title', { en: locale === 'en' }]">
          {{ $t("news.newsVideo1") }}
        </div>
        <div class="world linear-text">world</div>
      </div>
      <div class="video-container">
        <section style="position: relative">
          <video
            width="100%"
            preload="true"
            playsinline
            x-webkit-playsinline
            x5-playsinline
            x5-video-player-type="h5"
            x5-video-player-fullscreen
            ref="Video2"
            poster="http://ad4-static.lotuscars.com.cn/webapp/pc/image/news/news_top_video2.png"
            @click="this.videoPlayToggle"
            alt=""
          >
            <source
              src="http://ad4-static.lotuscars.com.cn/webapp/pc/video/news/news_top_video2.mp4"
            />
          </video>
          <div v-if="!videoPlayStatus" class="play-btn">
            <img
              class="arrow"
              src="http://ad4-static.lotuscars.com.cn/webapp/common/arrow_r.png"
              alt=""
            />
          </div>
        </section>
        <div :class="['video-title', { en: locale === 'en' }]">
          {{ $t("news.newsVideo2") }}
        </div>
        <div class="world linear-text">world</div>
      </div>
      <div
        class="video-container"
        @click="
          open(
            'https://www.bilibili.com/video/BV1xP411U7CC/?spm_id_from=333.999.0.0&vd_source=4feba028e574ff8d0dd7ae3736291a06'
          )
        "
      >
        <img
          src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/news/news_top_video3.png"
          alt=""
        />
        <div :class="['video-title', { en: locale === 'en' }]">
          {{ $t("news.newsVideo3") }}
        </div>
        <div class="world linear-text">world</div>
      </div>
    </div>
  </div>
</template>

<script>
import NewsCard from "./NewsCard.vue";
import { mapState } from "vuex";

export default {
  name: "NewsMain",
  components: { NewsCard },
  data() {
    return {
      expandFlag: false,
      videoPlayStatus: false,
      newsList: [
        {
          // 2022/11/04
          title: [
            "路特斯机器人云栖首发平行守护系统，探索自动驾驶“5G云代驾”技术新高地",
            "Lotus Robotics launched the parallel guardian system in APSARA, exploring ...",
          ],
          content: [
            "作为新技术与交通运输业融合发展的产物，近年来，随着5G、大数据、人工智能等新一代信息技术快速演进，自动驾驶已逐渐从梦想照进现实...",
            "As a product of the integrated development of new technologies and the transportation industry, in recent years, with the rapid evolution of new..",
          ],
          img: "http://ad4-static.lotuscars.com.cn/webapp/pc/image/matrix/cockpit_1.png",
          url: "http://www.news.cn/tech/20221104/175f0b99905f4354b6ff4a7e51e3b878/c.html",
          id: 1,
        },
        {
          // 2022/10/29
          title: [
            "最贵103万！李书福旗下豪华智能天花板开卖：4激光雷达、34个传感器",
            "The most expensive is 1.03 million! Shufu Li 's luxury smart ceiling is on sale: 4 lidars, 34 sensors",
          ],
          content: [
            "吉利旗下最智能最豪华电动SUV，80万起售，最贵102.8万。Eletre，够电动，纯电续航高达650公里，最快20分钟可以充电至80%，零百加速最快可以达到2.95秒...",
            "Geely's smartest and most luxurious electric SUV starts at 800,000 yuan and is the most expensive at 1.028 million yuan. Eletre, enough electric...",
          ],
          img: "http://ad4-static.lotuscars.com.cn/webapp/pc/image/news/lotus_eletre.png",
          url: "https://mp.weixin.qq.com/s/kKAdKjisplMvUd4kM6adfg",
          id: 2,
        },
        {
          // 2022/09/14
          title: [
            "WAIC 2022 | 路特斯李博：让智能驾驶开发从「手工坊」变成「流水线」",
            "WAIC 2022 | Lotus Bo Li: Let the development of intelligent driving change f...",
          ],
          content: [
            "虽然现在大家对「汽车机器人」的概念已不再陌生，但如此定位的汽车产品，仍需跨过极高的研发门槛。如今，随着路特斯打造汽车机器人的规划逐渐清晰，路特斯...",
            'Although everyone is no longer unfamiliar with the concept of "automotive robots", such positioning of automotive products still needs to cross a very ...',
          ],
          img: "http://ad4-static.lotuscars.com.cn/webapp/pc/image/news/lotus_cars.png",
          url: "https://mp.weixin.qq.com/s/upgwoHOCMnZ5xOZvJZ27ew",
          id: 3,
        },
        {
          // 2022/06/20
          title: [
            "对话路特斯科技李博：超豪华百万SUV，如何征战电动智能化时代？",
            "Dialogue with  Bo Li from Lotus : How will the ultra-luxury million-dollar SUV ...",
          ],
          content: [
            "即便保时捷已经推出了纯电动车Taycan，仍不忘“表明心迹”，“保时捷将永远不会停产内燃机，品牌的灵魂——保时捷911将一直提供内燃机选项”。法拉利也曾经表示...",
            'Even though Porsche has launched the pure electric car Taycan, it still does not forget to "show its heart", "Porsche will never stop producing internal com...',
          ],
          img: "http://ad4-static.lotuscars.com.cn/webapp/pc/image/news/lotus_eletre_1.png",
          url: "https://36kr.com/p/1792913533435271",
          id: 4,
        },
        {
          // 2022/06/10
          title: [
            "路特斯科技副总裁李博：智能化是汽车革命的根本",
            "Bo Li , Vice President of Lotus Technology: Intelligence is the foundation of the auto..",
          ],
          content: [
            "对于车迷来说，路特斯一直是一家十分独特的汽车制造商。即便在汽车同质化严重的今天，路特斯仍然「不忘初心」，坚持追求车辆的极致操控。但是面对时代变化...",
            'For fans, Lotus has always been a very unique car manufacturer. Even today, when the homogenization of automobiles is serious, Lotus still "remains true...',
          ],
          img: "http://ad4-static.lotuscars.com.cn/webapp/pc/image/news/lotus_eletre_2.png",
          url: "https://www.42how.com/article/6504",
          id: 5,
        },
        {
          // 2022/06/02
          title: [
            "为什么我们应该看好路特斯？",
            "Why should we be optimistic about Lotus?",
          ],
          content: [
            "回顾汽车百年历史，若把各个品牌比作星辰，路特斯绝对是那星海中最闪耀的之一。在过去的岁月中，路特斯品牌几经沉浮。在当下，汽车行业即将跨入新时代的十字...",
            "Looking back on the century-old history of automobiles, if you compare various brands to stars, Lotus is definitely one of the most shining stars...",
          ],
          img: "http://ad4-static.lotuscars.com.cn/webapp/pc/image/news/lotus_f1.png",
          url: "https://mp.weixin.qq.com/s/UyrrEOF3Vs6yulHOFybImg",
          id: 6,
        },
        {
          // 2022/05/06
          title: [
            "管清友、李博、吴迎秋共论新汽车2.0时代的“第三赛道”|汽车预言家",
            'Qingyou Guan, Bo Li, and Yingqiu Wu  discuss the "third track" in the car 2.0 era | Auto Prophet',
          ],
          content: [
            "当所有国际超高端汽车品牌纷纷走上电动化、智能化赛道时，英国老牌跑车品牌路特斯也在不断努力。早在2018年全球燃油汽车产销量出现历史性增长拐点的时候...",
            "When all international ultra-high-end car brands have embarked on the electric and intelligent track, the old British sports car brand Lotus is also making...",
          ],
          img: "http://ad4-static.lotuscars.com.cn/webapp/pc/image/news/lotus_evijia.png",
          url: "https://mp.weixin.qq.com/s/vl3eoZCQmB5fN2lztkJIsQ",
          id: 7,
        },
        {
          // 2022/04/22
          title: [
            "路特斯科技副总裁李博：要为用户带来“以终为始”的智能驾驶体验",
            "Bo Li, Vice President of Lotus Technology: To bring users an intelligent driving exp...",
          ],
          content: [
            "智能驾驶产业的发展正吸引一众行业内外玩家参与，从通用、宝马等主机厂到百度、谷歌等科技公司；从吉利、长城等自主品牌巨头到“蔚小理”等新秀；再从家用汽车...",
            "The development of the intelligent driving industry is attracting the participation of many players inside and outside the industry, from OEMs such as GM...",
          ],
          img: "http://ad4-static.lotuscars.com.cn/webapp/pc/image/news/lotus_soul.png",
          url: "https://www.cheyun.com/content/46368",
          id: 8,
        },
        {
          // 2022/04/01
          title: [
            "路特斯科技副总裁李博：汽车正在经历从「心」到「脑」的变革",
            'Bo Li , Vice President of Lotus Technology: Cars are undergoing a transformation from "heart" to "brain"',
          ],
          content: [
            "机器之心「AI 科技年会」的并行论坛之一——首席智行官大会受到了业界广泛关注。本次大会围绕汽车机器人、芯片及自动驾驶等领域，邀请了 11 位业内极具代...",
            'On March 23, the Chief Intelligent Officer Conference of the "AI Technology Annual Conference" at the Heart of the Machine received widespread attention..',
          ],
          img: "http://ad4-static.lotuscars.com.cn/webapp/pc/image/news/lotus_evijia1.png",
          url: "https://www.jiqizhixin.com/articles/2022-04-01-4",
          id: 9,
        },
      ],
      unExpandNewsList: [],
      expandNewsList: [],
    };
  },
  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },
  methods: {
    videoPlayToggle() {
      if (this.videoPlayStatus) {
        this.$refs.Video2.pause();
        this.videoPlayStatus = false;
      } else {
        this.$refs.Video2.play();
        this.videoPlayStatus = true;
      }
    },
    open(url) {
      window.open(url);
    },
    isMobile() {
      var userAgentInfo = navigator.userAgent;
      var Agents = new Array(
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPod"
      );
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return !flag;
    },
  },
  mounted() {
    if (this.isMobile()) {
      this.unExpandNewsList = this.newsList.slice(0);
    } else {
      this.unExpandNewsList = this.newsList.slice(0, 4);
    }

    this.expandNewsList = this.newsList.slice(4);
  },
};
</script>

<style lang="less" scoped>
.news-main {
  width: 100%;
  overflow: hidden;
  font-family: "HarmonyOS Sans SC";
  font-weight: 400;
  color: #fff;
  padding: 0 13% 0 13%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.12rem;
  .linear-text {
    background: linear-gradient(269.44deg, #00ff00 -4%, #00ffc2 102.4%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: 700;
  }
  .news-main-left {
    flex-basis: 6.57rem;
    .news-main-left-title {
      font-size: 0.25rem;
      letter-spacing: 0.01rem;
      font-family: "HarmonyOS Sans SC Black";
      font-weight: 700;
      margin-top: 0.91rem;
    }
    .news-main-container {
      border-bottom: 1px solid #575757;
    }
    .news-button {
      height: 0.92rem;
      width: 4.77rem;
      border: 1px solid #0f0;
      border-radius: 0.46rem;
      font-size: 0.32rem;
      font-weight: 700;
      line-height: 0.92rem;
      text-align: center;
      margin: 1rem auto;
      user-select: none;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        scale: 1.05;
      }
      &:active {
        opacity: 0.8;
        scale: 1;
      }
    }
  }

  .news-main-right {
    padding-top: 0.63rem;
    .shadow {
      font-family: "HarmonyOS Sans SC Black";
      font-size: 0.5rem;
      font-weight: 700;
      letter-spacing: 0.01rem;
      background: linear-gradient(
        #364242 4.05%,
        #2b2f37 49.85%,
        #21262b 99.61%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      margin-top: 0.63rem;
      vertical-align: bottom;
      line-height: 0.8;
    }
    .head-line {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0.5rem;
      p {
        font-family: "HarmonyOS Sans SC Black";
        font-weight: 700;
        font-size: 0.15rem;
        background-color: #fff;
        text-transform: uppercase;
        margin: 0 0.3rem 0 0.1rem;
      }
      i {
        display: block;
        width: 1.91rem;
        height: 0.03rem;
        background: linear-gradient(269.44deg, #00ff00 -4%, #00ffc2 102.4%);
      }
    }
    .video-container {
      border-bottom: 1px solid #575757;
      width: 2.84rem;
      margin-left: 0.1rem;
      margin-bottom: 0.4rem;
      cursor: pointer;
      position: relative;
      img {
        width: 100%;
      }
      .arrow {
        width: 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-40%);
      }

      &:hover .play-btn {
        opacity: 0.3;
      }
      .video-title {
        width: 2.94rem;
        margin: 0.23rem 0;
        &.en {
          font-size: 0.11rem;
        }
      }
      .play-btn {
        width: 0.34rem;
        height: 0.34rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(5, 5, 5, 0.5);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: opacity 0.4s;
        pointer-events: none;
        img {
          width: 50%;
        }
      }
      .world {
        font-family: "HarmonyOS Sans SC Black";
        font-size: 0.15rem;
        line-height: 0.22rem;
        letter-spacing: 0.01rem;
        text-transform: uppercase;
        margin-bottom: 0.37rem;
        width: fit-content;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .news-main {
    flex-direction: column;
    padding: 0;
    .news-main-left {
      padding: 0.04rem 0.71rem 0.82rem;
      background-color: rgb(28, 28, 28);
      order: 2;
      .news-button {
        display: none;
      }
    }
    .news-main-right {
      padding: 0.91rem 0.78rem;
      order: 1;
      .head-line {
        p {
          font-size: 0.22rem;
        }
      }
      .video-container {
        width: 100%;
        margin-left: 0;
        &:last-of-type {
          margin-bottom: 0;
        }
        img {
          width: 100%;
        }
        .video-title {
          width: 100%;
          font-size: 0.2rem;
        }
        .world {
          font-size: 0.2rem;
        }
      }
    }
  }
}
</style>
