<template>
  <div class="slide">
    <svg
      data-v-5c760780=""
      id="scrolldown"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      preserveAspectRatio="none"
      x="0px"
      y="0px"
      width="20px"
      height="40px"
      viewBox="0 0 20 40"
    >
      <defs data-v-5c760780="">
        <path
          data-v-5c760780=""
          id="scrolldown_1"
          stroke-width="2"
          stroke-linejoin="round"
          stroke-linecap="round"
          fill="none"
          d="M 18 12 L 18 28.2 Q 17.95 31.35 15.65 33.65 13.3 36 10 36 6.7 36 4.35 33.65 2.05 31.35 2 28.2 L 2 12 Q 2 8.7 4.35 6.35 6.7 4 10 4 13.3 4 15.65 6.35 18 8.7 18 12 Z"
        ></path>
        <path data-v-5c760780="" id="scrollpath" d="M 0 0 L 0 15"></path>
        <path
          data-v-5c760780=""
          id="scrolldown_0"
          stroke-width="3"
          stroke-linejoin="round"
          stroke-linecap="round"
          fill="none"
          d="M 10 8.9 L 10 12.4"
        >
          <animateMotion
            data-v-5c760780=""
            dur="1.5s"
            keyPoints="0;0.75;1"
            keyTimes="0;0.5;1"
            repeatCount="indefinite"
            calcMode="linear"
          >
            <mpath data-v-5c760780="" xlink:href="#scrollpath"></mpath>
          </animateMotion>
          <animate
            data-v-5c760780=""
            attributeName="opacity"
            dur="1.5s"
            keyTimes="0;0.5;1"
            values="1;1;0"
            repeatCount="indefinite"
          ></animate>
        </path>
      </defs>
      <g data-v-5c760780="" transform="matrix( 1, 0, 0, 1, 0,0) ">
        <use data-v-5c760780="" xlink:href="#scrolldown_1"></use>
      </g>
      <g data-v-5c760780="" transform="matrix( 1, 0, 0, 1, 0,0) ">
        <use data-v-5c760780="" xlink:href="#scrolldown_0"></use>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "SlideIcon",
};
</script>

<style>
.slide {
  color: #fff;
  position: absolute;
  bottom: 0.2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  stroke: #3dff2d;
  color: #3dff2d;
}
</style>
