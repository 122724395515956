<template>
  <div class="robo-matrix-mobile-overview-page">
    <div
      class="headline"
      data-aos="fade-right"
      data-aos-offset="0"
      data-aos-delay="100"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <div class="blur"></div>
      <div class="title">ROBO Matrix</div>
    </div>
    <div :class="locale === 'cn' ? 'cn-sub' : 'en-sub'">
      {{ $t("matrix.section1.subtitle") }}
    </div>

    <div
      class="desc-text top"
      data-aos="zoom-in-down"
      data-aos-offset="0"
      data-aos-delay="100"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <h3>@2023</h3>
      <h3>CODE DIFFERENT</h3>
    </div>

    <div
      class="desc-text bottom"
      data-aos="zoom-in-right"
      data-aos-offset="0"
      data-aos-delay="200"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <h3>TO BOOST THE ROBOTICS ERA</h3>
    </div>

    <div class="car-drive-wrapper">
      <div class="road-svg">
        <road-mobile-svg />
      </div>
      <div class="position">
        <span class="outer-circle">
          <span class="inner-circle"></span>
        </span>
        <img
          class="car"
          src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/matrix/car_new.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RoadMobileSvg from "./RoadMobileSvg.vue";
import { mapState } from "vuex";
export default {
  name: "MatrixMobileOverviewSection",

  components: {
    RoadMobileSvg,
  },

  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/font.css";
@import "~@/assets/css/common.less";
@import "~@/assets/css/image.less";

.robo-matrix-mobile-overview-page {
  position: relative;
  height: calc(100vh - 0.5rem);
  background: linear-gradient(
    118.48deg,
    #333a55 -25.09%,
    rgba(43, 45, 64, 0.526042) 37.81%,
    rgba(68, 68, 68, 0) 65.04%
  );

  .headline {
    position: relative;
    top: 2rem;
    left: 1rem;
    height: 1.38rem;
    .blur {
      position: absolute;
      z-index: 999;
      width: 1.38rem;
      height: 1.38rem;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("@{pcBlurImg}");
    }

    .title {
      position: absolute;
      top: 0.5rem;
      left: 0.54rem;
      font-style: normal;
      background: linear-gradient(
        269.44deg,
        @primary-color -4%,
        #00ffc2 102.4%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      font-family: "HarmonyOS Sans SC Bold";
      font-weight: 900;
      font-size: 0.64rem;
      line-height: 0.75rem;
    }
  }

  .cn-sub {
    font-family: "HarmonyOS Sans SC Thin";
    font-weight: 400;
    font-size: 0.5rem;
    line-height: 0.59rem;
    letter-spacing: 0.05em;
    background: linear-gradient(269.44deg, @primary-color -4%, #00ffc2 102.4%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-align: center;
    position: relative;
    top: 2.3rem;
    z-index: 99999;
  }
  .en-sub {
    font-family: "HarmonyOS Sans SC Thin";
    font-weight: 400;
    font-size: 0.35rem;
    background: linear-gradient(269.44deg, @primary-color -4%, #00ffc2 102.4%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-align: center;
    position: relative;
    top: 2.3rem;
    z-index: 99999;
    width: 6.5rem;
    margin: 0 auto;
  }

  .desc-text {
    font-family: "HarmonyOS Sans SC";
    font-style: normal;
    font-weight: 400;
    font-size: 0.14rem;
    letter-spacing: 0.01em;
    color: #ffffff;
    &.top {
      display: inline-block;
      line-height: 0.19rem;
      margin: 8rem 0 0 0.71rem;
    }
    &.bottom {
      line-height: 0.19rem;
      margin: 2.31rem 0 0 0.71rem;
    }
  }

  .car-drive-wrapper {
    display: inline-block;
    position: absolute;
    bottom: 0.2rem;
    // .road {
    //   display: inline-block;
    //   transform: rotate(-5.2deg);
    // }

    .road-mobile {
      width: 5.93rem;
      height: 10.76rem;
      background-image: url("@{mbRoad}");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      // transform: rotate(-10.2deg);
      position: relative;
      top: 3.5rem;
    }

    .road-svg {
      height: 10.76rem;
      width: 5.93rem;
      transform: scale(0.6) translate(-30%, 15%);
    }
    .position {
      position: relative;
      right: -2rem;
      bottom: 0.5rem;
      transform: rotate(41.15deg);
      display: inline-block;
      .outer-circle {
        display: inline-block;
        width: 7.78rem;
        height: 7.78rem;
        border-radius: 50%;
        background: rgba(0, 255, 0, 0.2);
        text-align: center;
        .inner-circle {
          display: inline-block;
          width: 4.97rem;
          height: 4.97rem;
          border-radius: 50%;
          background: linear-gradient(
            269.44deg,
            @primary-color -4%,
            #00ffc2 102.4%
          );
          border: 1px solid #868484;
          margin-top: 1.17rem;
        }
      }
    }
    .car {
      width: 11.43rem;
      height: 7.21rem;
      position: absolute;
      top: 50%;
      left: 0.5rem;
      transform: translateY(-50%);
    }
  }
}
</style>
