export default {
  homeTitle: "全面赋能机器人时代",
  robotics: "路特斯机器人",
  moduleContent: [
    {
      text1: "在更高更快更强的时代，",
      text2: "路特斯是智能驾驶的优秀实践平台",
    },
    {
      text1: "我们处理明天的问题，用的不是今天的工具，",
      text2: "而是明天的工具",
    },
    {
      text1:
        "在智能驾驶能力不断提升的过程中，用运营服务来弥补用户对智能驾驶的期待，提升用户的乘用体验",
      text2: "",
    },
    {
      text1: "用“接管里程”与“覆盖里程”重新定义智能驾驶分级，",
      text2: "赋能行业，培养人才",
    },
  ],
  knowMore: "了解更多",
  soulContentText1: "在更高更快更强的时代，",
  soulContentText2: "路特斯是智能驾驶的优秀实践平台",
  joinTitle: "加入LOTUS ROBOTICS",
  joinContent: "一起成为更好的我们",
  joinButton: "查看在招职位",
  mediaContact: "媒体合作",
  comContact: "商务合作",
  email: "邮箱",
  global: "全球化布局",
};
