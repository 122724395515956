<template>
  <div class="robo-matrix-page" v-show="isShow">
    <template v-if="isMobile">
      <overview-mobile-section />
      <value-mobile-section />
      <cockpit-section />
      <feature-section />
      <advantage-section />
    </template>
    <template v-else>
      <!-- <overview-section /> -->
      <overview-section-v2 />
      <value-section />
      <cockpit-section />
      <feature-section />
      <advantage-section />
    </template>
  </div>
</template>

<script>
// import OverviewSection from "./components/OverviewSection.vue";
import OverviewSectionV2 from "./components/OverviewSectionV2.vue";
import OverviewMobileSection from "./components/OverviewMobileSection.vue";
import ValueSection from "./components/ValueSection.vue";
import ValueMobileSection from "./components/ValueMobileSection.vue";
import CockpitSection from "./components/CockpitSection.vue";
import FeatureSection from "./components/FeatureSection.vue";
import AdvantageSection from "./components/AdvantageSection.vue";
export default {
  name: "RoboMatrix",

  components: {
    // OverviewSection,
    OverviewSectionV2,
    OverviewMobileSection,
    ValueSection,
    ValueMobileSection,
    CockpitSection,
    FeatureSection,
    AdvantageSection,
  },

  data() {
    return {
      isShow: false,
      isMobile: false,
    };
  },

  mounted() {
    this.isMobile = document.documentElement.clientWidth <= 750;
    this.isShow = true;

    window.addEventListener("resize", () => {
      this.isMobile = document.documentElement.clientWidth <= 750;
    });
  },
};
</script>

<style lang="less" scoped>
.robo-matrix-page {
  color: #fff;
  overflow-x: hidden;
}
</style>
