<template>
  <div class="robo-matrix-value-page">
    <div
      class="bg-group"
      data-aos="fade-up-right"
      data-aos-offset="120"
      data-aos-delay="200"
      data-aos-duration="1200"
      data-aos-once="true"
    >
      <div class="trapezoid"></div>
      <div class="triangle"></div>
      <div class="diamond"></div>
    </div>
    <div class="main">
      <h2
        class="decorator"
        data-aos="fade-up"
        data-aos-offset="120"
        data-aos-delay="100"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        VALUE
      </h2>
      <h1
        class="headline"
        data-aos="fade-up"
        data-aos-offset="120"
        data-aos-delay="200"
        data-aos-duration="1200"
        data-aos-once="true"
      >
        {{ $t("matrix.section2.title") }}
      </h1>

      <div class="content">
        <div
          class="block left"
          data-aos="fade-left"
          data-aos-offset="120"
          data-aos-delay="200"
          data-aos-duration="1200"
          data-aos-once="true"
          data-aos-easing="ease"
        >
          <h1 class="title" v-show="locale === 'cn'">尊享服务</h1>
          <h2 class="subtitle">FOR THE DRIVERS</h2>

          <div class="feature">
            <h3>{{ $t("matrix.section2.feature1Title") }}</h3>
            <p>{{ $t("matrix.section2.feature1Content") }}</p>
          </div>
          <div class="feature">
            <h3>{{ $t("matrix.section2.feature2Title") }}</h3>
            <p>{{ $t("matrix.section2.feature2Content") }}</p>
          </div>
          <div class="feature">
            <h3>{{ $t("matrix.section2.feature3Title") }}</h3>
            <p>{{ $t("matrix.section2.feature4Content") }}</p>
          </div>
          <div class="feature">
            <h3>{{ $t("matrix.section2.feature4Title") }}</h3>
            <p>{{ $t("matrix.section2.feature5Content") }}</p>
          </div>
        </div>

        <div
          class="block right"
          data-aos="fade-left"
          data-aos-offset="120"
          data-aos-delay="300"
          data-aos-duration="1200"
          data-aos-once="true"
          data-aos-easing="ease"
        >
          <h1 class="title" v-if="locale === 'cn'">赋能未来</h1>
          <h2 class="subtitle">FOR THE OEMS</h2>

          <div class="feature">
            <h3>{{ $t("matrix.section2.feature5Title") }}</h3>
            <p>{{ $t("matrix.section2.feature5Content") }}</p>
          </div>
          <div class="feature">
            <h3>{{ $t("matrix.section2.feature6Title") }}</h3>
            <p>{{ $t("matrix.section2.feature6Content") }}</p>
          </div>
          <div class="feature">
            <h3>{{ $t("matrix.section2.feature7Title") }}</h3>
            <p>{{ $t("matrix.section2.feature7Content") }}</p>
          </div>
          <div class="feature">
            <h3>{{ $t("matrix.section2.feature8Title") }}</h3>
            <p>{{ $t("matrix.section2.feature8Content") }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="svg-animation">
      <value-svg :width="549" :height="249" :stroke-width="8" />
    </div>
  </div>
</template>

<script>
import ValueSvg from "./ValueSvg.vue";
import { mapState } from "vuex";
export default {
  name: "ValueSection",
  components: {
    ValueSvg,
  },

  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/font.css";
@import "~@/assets/css/common.less";
@import "~@/assets/css/image.less";

.robo-matrix-value-page {
  background-color: #000;
  height: calc(100vh - 0.5rem);
  min-height: 7.2rem;
  position: relative;

  .bg-group {
    position: absolute;
    bottom: 0;
    overflow: hidden;
    .trapezoid {
      width: 2.57rem;
      height: 6.15rem;
      background-image: url("@{trapezoidImg}");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .triangle {
      margin-top: -1.28rem;
      width: 2.56rem;
      height: 1.28rem;
      background-image: url("@{triangleImg}");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .diamond {
      margin-top: -0.7rem;
      width: 7.56rem;
      height: 2.06rem;
      background-image: url("@{diamondImg}");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transform: rotate(4.1deg) translate(-0.07rem, 0.03rem);
    }
  }

  .main {
    margin-left: 3.43rem;
    padding-top: 1.86rem;
    h1,
    h2 {
      margin: 0;
      padding: 0;
    }
    .decorator {
      font-family: "HarmonyOS Sans SC Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 0.5rem;
      line-height: 0.48rem;
      letter-spacing: 0.01em;
      background: linear-gradient(
        98.62deg,
        #364242 3.92%,
        #2b2f37 48.34%,
        #21262b 96.58%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    .headline {
      margin: -0.35rem 0 0 0.48rem;
      padding: 0;
      font-family: "HarmonyOS Sans SC Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 0.28rem;
      line-height: 0.33rem;
      letter-spacing: 0.01em;
      color: @primary-color;
    }

    .content {
      margin-top: 0.5rem;
      display: flex;

      .block {
        display: inline-block;
        &.left {
          width: 4.2rem;
          margin: -0.2rem 0.3rem 0 0;
        }

        &.right {
          width: 5.5rem;
          margin-top: 0.1rem;
        }
      }
      .title {
        font-family: "HarmonyOS Sans SC";
        font-style: normal;
        font-weight: 700;
        font-size: 0.18rem;
        line-height: 0.21rem;
        letter-spacing: 0.065em;
        color: #ffffff;
      }
      .subtitle {
        font-family: "HarmonyOS Sans SC";
        font-style: normal;
        font-weight: 900;
        font-size: 0.16rem;
        line-height: 0.19rem;
        letter-spacing: 0.065em;
        text-transform: uppercase;
        background: linear-gradient(269.44deg, #00ff00 -4%, #00ffc2 102.4%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin-bottom: 0.33rem;
      }
    }

    .feature {
      font-size: 0.12rem;
      line-height: 0.16rem;
      font-family: "HarmonyOS Sans SC";
      color: #898989;
      margin-bottom: 0.2rem;
      font-weight: 900;

      h3 {
        font-family: HarmonyOS Sans SC Bold;
        margin: 0;
        padding: 0;
      }
      p {
        font-family: HarmonyOS Sans SC;
        opacity: 0.6;
        font-weight: lighter;
        margin: 0;
        padding: 0;
        margin-top: 0.02rem;
      }
    }
  }

  .svg-animation {
    position: absolute;
    top: 0.9rem;
    left: 0;
  }
}
</style>
