<template>
  <section class="roboverse-tenth-section">
    <div
      class="headline"
      data-aos="fade-right"
      data-aos-easing="ease-out-cubic"
      data-aos-offset="0"
      data-aos-delay="100"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <h1 class="bg-text">ROBOVERSE</h1>
      <h2 class="title">{{ $t("verse.section10.title") }}</h2>
    </div>

    <div :class="'planning-pic ' + locale"></div>
  </section>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "RoboverseTenthSection",

  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },

  methods: {
    moreClick() {
      this.$router.push({ name: "RoboGalaxy" });
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/font.css";
@import "~@/assets/css/image.less";
.roboverse-tenth-section {
  position: relative;
  padding-bottom: 1.5rem;

  .headline {
    padding-top: 1.1rem;
    margin-left: 1.5rem;
    h1,
    h2 {
      margin: 0;
      padding: 0;
    }
    .bg-text {
      font-family: "HarmonyOS Sans SC Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 0.32rem;
      line-height: 0.45rem;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      background: linear-gradient(
        98.62deg,
        #364242 3.92%,
        #2b2f37 48.34%,
        #21262b 96.58%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    .title {
      font-family: "HarmonyOS Sans SC Bold";
      font-style: normal;
      font-weight: 900;
      font-size: 0.28rem;
      line-height: 0.33rem;
      letter-spacing: 0.065em;
      color: #00ff00;
    }
  }

  .planning-pic {
    margin: 0 auto;
    width: 10.46rem;
    height: 6.18rem;
    margin-top: 0.86rem;
    background-repeat: no-repeat;
    background-size: cover;
    &.cn {
      background-image: url("@{planCnImg}");
    }
    &.en {
      background-image: url("@{planEnImg}");
    }
  }
}

@media only screen and (max-width: 750px) {
  .roboverse-tenth-section {
    padding-bottom: 0.9rem;
    .headline {
      margin-left: 1rem;
    }

    .planning-pic {
      width: 6.7rem;
      height: 7.8rem;
      background-size: contain;
      &.cn {
        background-image: url("@{mbPlanCnImg}");
      }
      &.en {
        background-image: url("@{mbPlanEnImg}");
      }
    }
  }
}
</style>
