export default {
  section1: {
    subtitle: "智能驾驶运营解决方案",
  },
  section2: {
    title: "技术价值",

    feature1Title: "赛道飞驰教练",
    feature1Content: "为用户提供远程赛道培训课程，通过5G实时在线指导教学",
    feature2Title: "低速代客泊车",
    feature2Content: "低速场景无人代客泊车",
    feature3Title: "平行世界",
    feature3Content: "在隐私保护的前提下为用户提供车辆实景视图",
    feature4Title: "补能传送门",
    feature4Content: "将车辆停放在指定地点，由RAS配合自动插枪机器人进行充电",
    feature5Title: "特殊作业",
    feature5Content:
      "在港口，矿山等危险场景可实现远程作业，大幅提升作业效率及安全性",
    feature6Title: "情境回溯",
    feature6Content:
      "结合ROBO Galaxy能力，实现远程记录接管情境，并将数据回灌到模拟仿真云平台进行算法优化",
    feature7Title: "远程诊断",
    feature7Content: "对行驶车辆进行故障预期检测，发现车辆问题并提示用户",
    feature8Title: "无忧出行",
    feature8Content: "打造专属的个人出行服务",
  },

  section3: {
    title: "座舱展示",
  },
  section4: {
    title: "能力介绍",
    feature1:
      "云舱驾驶员在收到用户请求后，可短暂获得车辆驾驶权限，通过云舱控制车辆完成低速场景下的无人泊车/充电/挪车等服务。",
    feature2:
      "作为云代驾的进阶功能，云舱驾驶员可代替用户监管自动驾驶系统运行，在危险预判/复杂路况/风险控制等场景可与自动驾驶形成双重保障，解放用户。",
    feature3:
      "高阶的云舱控车方案，融合自动驾驶系统规控能力，完成车辆半自动化避障，脱困，路线更改等决策。实现可多路并发更高效安全的远程控车操作。",
  },

  section5: {
    title: "技术优势",
    avtTitle1: "高安全",
    avtContent1:
      "加密车控、纵深防御的安全设计、整套阿里云安全产品防护。「智能驾驶系统」系统级等级保护三级认证。车云共同监测链路稳定性，车端配置MRM「最小风险策略」冗余，不依赖网络，也能安全停车。",
    avtTitle2: "数据合规",
    avtContent2:
      "系统级脱敏，实时上传合规画面「从车端传输上来的视频及图像都将人脸和车辆牌照进行模糊处理」",
    avtTitle3: "低延迟",
    avtContent3: "5G网络保障车控及图像毫秒级响应",
  },
};
