<template>
  <section class="robo-galaxy-cooperation-page">
    <div class="headline">
      <h1
        data-aos="fade-up"
        data-aos-offset="120"
        data-aos-delay="100"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        ECO. <br />
        COOPERATION
      </h1>
    </div>

    <div class="cooperation-picture" v-if="!isMobile">
      <img
        data-aos="fade-left"
        data-aos-offset="120"
        data-aos-delay="150"
        data-aos-duration="1000"
        data-aos-once="true"
        class="first"
        src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/galaxy/cooperation_1.png"
      />
      <img
        data-aos="fade-left"
        data-aos-offset="120"
        data-aos-delay="250"
        data-aos-duration="1200"
        data-aos-once="true"
        src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/galaxy/cooperation_2.jpg"
      />
    </div>
    <div
      v-else
      data-aos="fade-left"
      data-aos-easing="ease-out-cubic"
      data-aos-offset="0"
      data-aos-delay="100"
      data-aos-duration="1000"
      data-aos-once="true"
      class="cooper-swiper"
    >
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img
            class="first"
            src="http://ad4-static.lotuscars.com.cn/webapp/mobile/image/galaxy/cooperation_1.png"
          />
        </div>
        <div class="swiper-slide">
          <img
            class="first"
            src="http://ad4-static.lotuscars.com.cn/webapp/mobile/image/galaxy/cooperation_2.png"
          />
        </div>
      </div>
    </div>
    <p
      class="desc1"
      data-aos="fade-right"
      data-aos-offset="120"
      data-aos-delay="250"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      {{ $t("galaxy.section9.title") }}
    </p>
    <p
      class="desc2"
      data-aos="fade-right"
      data-aos-offset="120"
      data-aos-delay="250"
      data-aos-duration="1200"
      data-aos-once="true"
    >
      {{ $t("galaxy.section9.content") }}
    </p>
  </section>
</template>

<script>
import { getRootFontSize } from "@/utils/rem";
import Swiper from "swiper";
import "swiper/css/swiper.css";

export default {
  name: "CooperationSection",
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    this.isMobile = this.inMobile();
    const rootFontSize = getRootFontSize(1260, 100);
    if (this.isMobile) {
      this.$nextTick(() => {
        new Swiper(".cooper-swiper", {
          observer: true,
          observeParents: true,
          slidesPerView: "auto",
          centeredSlides: true,
          spaceBetween: rootFontSize * 0.87,
        });
      });
    }
  },
  methods: {
    inMobile() {
      var userAgentInfo = navigator.userAgent;
      var Agents = new Array(
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPod"
      );
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return !flag;
    },
  },
};
</script>

<style lang="less" scoped>
.robo-galaxy-cooperation-page {
  background: #18171d;
  height: calc(100vh - 0.5rem);
  min-height: 7.2rem;
  .headline {
    padding-top: 0.9rem;
    margin-left: 2.23rem;
    h1 {
      margin: 0;
      padding: 0;
      font-family: "HarmonyOS Sans SC Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 0.5rem;
      line-height: 0.48rem;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      background: linear-gradient(269.44deg, #00ff00 -4%, #00ffc2 102.4%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  .cooperation-picture {
    margin-top: 0.24rem;
    img {
      width: 4.52rem;
      height: 3.04rem;
    }

    .first {
      margin: 0 0.9rem 0 2.01rem;
    }
  }

  .desc1 {
    margin: 0.5rem 0 0.2rem 2.14rem;
    padding: 0;
    font-family: "HarmonyOS Sans SC";
    font-style: normal;
    font-weight: 400;
    font-size: 0.22rem;
    line-height: 0.28rem;
    letter-spacing: 0.01em;
    color: #ffffff;
  }

  .desc2 {
    margin: 0 0 0 2.14rem;
    padding: 0 0.5rem 0 0;
    font-family: "HarmonyOS Sans SC";
    font-style: normal;
    font-weight: 400;
    font-size: 0.16rem;
    line-height: 0.22rem;
    letter-spacing: 0.03em;
    color: #646368;
  }
}

@media only screen and (max-width: 750px) {
  .robo-galaxy-cooperation-page {
    background: #18171d;
    height: 10.8rem;
    .headline {
      padding-top: 1.17rem;
      margin-left: 0.68rem;
    }

    .cooperation-picture {
      margin-top: 0.24rem;
      img {
        width: 4.52rem;
        height: 3.04rem;
      }

      .first {
        margin: 0 0.9rem 0 2.01rem;
      }
    }

    .cooper-swiper {
      overflow: hidden;
      margin-top: 0.69rem;
      .swiper-slide {
        width: fit-content;
      }

      img {
        width: 4.79rem;
        height: 3.22rem;
      }
    }

    .desc1 {
      margin: 0.81rem 0 0.24rem 0.72rem;
      padding: 0;
      font-size: 0.24rem;
    }

    .desc2 {
      margin: 0 0 0 0.72rem;
      width: 5.96rem;
      padding: 0;
      font-size: 0.2rem;
      line-height: 0.26rem;
    }
  }
}
</style>
