<template>
  <section class="roboverse-twelveth-section">
    <div
      class="headline"
      data-aos="fade-left"
      data-aos-easing="ease-out-cubic"
      data-aos-offset="0"
      data-aos-delay="100"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <h1 class="bg-text">VALUE</h1>
      <h2 class="title">
        <span class="text1">ROBOVERSE</span>
        <span class="text2">{{ $t("verse.section12.title") }}</span>
      </h2>
    </div>

    <div
      class="container"
      data-aos="zoom-in"
      data-aos-easing="ease-out-cubic"
      data-aos-offset="0"
      data-aos-delay="100"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <div
        class="card"
        v-for="(item, index) in $t('verse.section12.list')"
        :key="index"
      >
        <div class="bg">
          <div class="top-line"></div>
          <div class="num">
            <span class="value">{{ item.no }}</span>
            <span class="dot"></span>
          </div>
        </div>
        <div class="feature">{{ item.feature }}</div>
        <div :class="'desc ' + locale">{{ item.desc }}</div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "RoboverseTwelvethSection",

  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/font.css";
@import "~@/assets/css/image.less";
.roboverse-twelveth-section {
  position: relative;
  height: calc(100vw / 1280 * 720);
  background-image: url("@{twelvethBg}");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .headline {
    padding-top: 1.31rem;
    margin-left: 1.49rem;
    .bg-text {
      padding: 0;
      margin: 0;
      font-family: "HarmonyOS Sans SC Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 0.5rem;
      line-height: 0.48rem;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.15);
    }

    .title {
      padding: 0;
      margin: 0;
      font-family: "HarmonyOS Sans SC";
      font-style: normal;
      font-weight: 900;
      font-size: 0.28rem;
      line-height: 0.33rem;
      letter-spacing: 0.065em;
      margin: -0.23rem 0 0 0.45rem;
      .text1 {
        color: #00ff00;
        margin-right: 0.2rem;
      }
      .text2 {
        color: #fff;
      }
    }
  }

  .container {
    display: flex;
    width: fit-content;
    margin: 1.3rem auto 0 auto;
    .card {
      margin-right: 0.4rem;
      cursor: pointer;
      transition: transform 300ms ease;
      &:hover {
        transform: scale(1.08);
      }
      .bg {
        position: relative;
        width: 2.1rem;
        height: 1.14rem;
        // background: linear-gradient(
        //   -9.21deg,
        //   rgba(0, 255, 0, 0.18) 8.53%,
        //   rgba(217, 217, 217, 0) 67.58%
        // );
        background: linear-gradient(
          170.79deg,
          rgba(0, 255, 0, 0.18) 8.53%,
          rgba(217, 217, 217, 0) 67.58%
        );

        .top-line {
          position: absolute;
          top: 0;
          width: 100%;
          height: 0.03rem;
          background: linear-gradient(269.44deg, #00ff00 -4%, #00ffc2 102.4%);
        }

        .num {
          position: absolute;
          left: 0.1rem;
          bottom: -0.1rem;
          .value {
            font-family: "HarmonyOS Sans SC";
            font-style: normal;
            font-weight: 700;
            font-size: 0.8rem;
            line-height: 0.32rem;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            color: #ffffff;
          }

          .dot {
            display: inline-block;
            width: 0.07rem;
            height: 0.07rem;
            background: #00ff00;
            margin-left: 0.05rem;
          }
        }
      }

      .feature {
        font-family: "HarmonyOS Sans SC";
        font-style: normal;
        font-weight: 700;
        font-size: 0.18rem;
        line-height: 0.21rem;
        letter-spacing: 0.065em;
        color: #ffffff;
        margin: 0.35rem 0 0.25rem 0.1rem;
      }

      .desc {
        width: 2.1rem;
        font-family: "HarmonyOS Sans SC Thin";
        font-style: normal;
        font-weight: 400;
        font-size: 0.12rem;
        line-height: 0.18rem;
        letter-spacing: 0.065em;
        color: #cbcbcb;
        margin-left: 0.1rem;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .roboverse-twelveth-section {
    height: 12.89rem;
    background-image: url("@{mbTwelvethBg}");

    .headline {
      padding-top: 0.9rem;
      margin-left: 1.1rem;
    }

    .container {
      padding: 0 0.7rem;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 0.7rem;

      .card {
        margin: 0 0 0.7rem 0;
        .bg {
          width: 2.6rem;
          height: 1.4rem;
        }
        .desc {
          width: 2.5rem;
          line-height: 0.22rem;

          &.cn {
            line-height: 0.28rem;
          }
        }
      }
    }
  }
}
</style>
