<template>
  <section class="roboverse-fifth-section">
    <div
      class="headline"
      data-aos="fade-up"
      data-aos-easing="ease-out-cubic"
      data-aos-offset="0"
      data-aos-delay="100"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <h1 class="bg-text" v-if="locale === 'cn'">DATA COLLECTION</h1>
      <h2 :class="'title ' + locale">{{ $t("verse.section5.title") }}</h2>
    </div>
    <div class="my-swiper">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, index) in $t('verse.section5.list')"
          :key="index"
        >
          <div class="card">
            <div :class="'title-wrapper ' + locale">
              <img class="icon" :src="item.icon" />
              <span :class="'title ' + locale">{{ item.title }}</span>
            </div>
            <div :class="'content ' + locale" v-html="item.content"></div>
            <div class="bottom">
              <div class="no">{{ item.no }}</div>
              <div class="en-b">
                <div class="title1">{{ item.enTitle1 }}</div>
                <div class="title2">{{ item.enTitle2 }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import Swiper from "swiper";
import "swiper/css/swiper.css";
export default {
  name: "RoboverseFifthMobileSection",

  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },

  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        new Swiper(".my-swiper", {
          initialSlide: 1,
          slidesPerView: 2,
          centeredSlides: true,
          spaceBetween: 40,
          effect: "coverflow",
          coverflowEffect: {
            rotate: 0,
            stretch: 10,
            depth: 50,
            modifier: 2,
            slideShadows: true,
          },
          // slidesOffsetBefore: -50,
          // autoplay: {
          //   delay: 5000,
          //   stopOnLastSlide: false,
          //   disableOnInteraction: true,
          // },
        });
      }, 300);
    });
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/font.css";
@import "~@/assets/css/image.less";
.roboverse-fifth-section {
  position: relative;
  height: 9rem;
  font-size: 0;

  .headline {
    padding-top: 1.1rem;
    margin-left: 1.5rem;
    h1,
    h2 {
      margin: 0;
      padding: 0;
    }
    .bg-text {
      font-family: "HarmonyOS Sans SC Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 0.32rem;
      line-height: 0.45rem;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      background: linear-gradient(
        98.62deg,
        #364242 3.92%,
        #2b2f37 48.34%,
        #21262b 96.58%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    .title {
      font-family: "HarmonyOS Sans SC Bold";
      font-style: normal;
      font-weight: 900;
      font-size: 0.28rem;
      line-height: 0.33rem;
      letter-spacing: 0.065em;
      color: #00ff00;

      &.en {
        background: linear-gradient(269.44deg, #00ff00 -4%, #00ffc2 102.4%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }

  .my-swiper {
    width: 100%;
    overflow: hidden;
    margin-top: 1.06rem;
    .swiper-slide {
      width: 3.16rem;
      height: 4.61rem;
    }
    .card {
      position: relative;
      box-sizing: border-box;
      width: 3.16rem;
      height: 4.61rem;
      padding: 0.7rem 0.15rem 0.3rem 0.3rem;
      background-image: url("@{rvDcCardBg}");
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 0.55rem;
      cursor: pointer;

      .title-wrapper {
        display: flex;
        align-items: center;
        &.en {
          align-items: flex-start;
        }
        .icon {
          width: 0.28rem;
          height: 0.28rem;
          margin-right: 0.1rem;
        }

        .title {
          font-family: "HarmonyOS Sans SC";
          font-style: normal;
          font-weight: 700;
          font-size: 0.24rem;
          line-height: 0.19rem;
          letter-spacing: 0.01em;
          color: #ffffff;
          &.en {
            line-height: 0.22rem;
          }
        }
      }

      .content {
        font-family: "HarmonyOS Sans SC Thin";
        font-style: normal;
        font-weight: 400;
        font-size: 0.14rem;
        line-height: 0.26rem;
        letter-spacing: 0.065em;
        color: #ffffff;
        margin-top: 0.45rem;
        &.en {
          font-size: 0.12rem;
          line-height: 0.22rem;
          margin-top: 0.22rem;
        }
      }

      .bottom {
        position: absolute;
        bottom: 0.3rem;
        .no {
          font-family: "HarmonyOS Sans SC Thin";
          font-style: normal;
          font-weight: 400;
          font-size: 0.2rem;
          line-height: 0.35rem;
          letter-spacing: 0.01em;
          text-transform: uppercase;
          color: #ffffff;
        }

        .en-b {
          font-family: "HarmonyOS Sans SC Bold";
          font-style: normal;
          font-weight: 900;
          font-size: 0.25rem;
          line-height: 0.32rem;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          color: #ffffff;
          margin-top: 0.05rem;
        }
      }

      &:hover {
        .en-b {
          background: linear-gradient(269.44deg, #00ff00 -4%, #00ffc2 102.4%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
      }
    }
  }
}
</style>
