<template>
  <div class="data-manage-section">
    <div class="headline">
      <h1
        class="title"
        data-aos="fade-up"
        data-aos-offset="0"
        data-aos-delay="100"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        {{ $t("galaxy.section4.title") }}
      </h1>
      <h2
        class="sub-title"
        data-aos="fade-up"
        data-aos-offset="0"
        data-aos-delay="200"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        {{ $t("galaxy.section4.subtitle") }}
      </h2>
    </div>

    <div
      class="data-manage-swiper"
      data-aos="fade-left"
      data-aos-offset="0"
      data-aos-delay="300"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <div class="swiper-wrapper">
        <div class="block swiper-slide">
          <div class="pic1"></div>
          <div class="content">
            <div class="mark"></div>
            <div>
              <div class="title">{{ $t("galaxy.section4.title1") }}</div>
              <div :class="'text ' + locale">
                {{ $t("galaxy.section4.content1") }}
              </div>
            </div>
          </div>
        </div>
        <div class="block swiper-slide">
          <div class="pic2"></div>
          <div class="content">
            <div class="mark"></div>
            <div>
              <div class="title">{{ $t("galaxy.section4.title2") }}</div>
              <div :class="'text ' + locale">
                {{ $t("galaxy.section4.content2") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.css";
import { mapState } from "vuex";
export default {
  name: "DataManageSection",

  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },

  mounted() {
    new Swiper(".data-manage-swiper", {
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
      },
    });
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/galaxy.less";
@import "~@/assets/css/common.less";
@import "~@/assets/css/image.less";
.data-manage-section {
  height: 7.2rem;
  background-color: #000;
  background-image: url("@{pcDatamanageBgImg}");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;

  .block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .pic1 {
      width: 4.68rem;
      height: 4.68rem;
      background-image: url("@{dataManageImg1}");
      background-repeat: no-repeat;
      background-size: contain;
    }

    .pic2 {
      width: 4.66rem;
      height: 2.91rem;
      margin-top: 0.8rem;
      background-image: url("@{dataManageImg2}");
      background-repeat: no-repeat;
      background-size: contain;
    }

    .content {
      display: flex;
      font-size: 0;
      .mark {
        width: 0.08rem;
        height: 0.26rem;
        background: linear-gradient(
          269.44deg,
          @primary-color -4%,
          #00ffc2 102.4%
        );
        border-radius: 0.2rem;
        margin-right: 0.18rem;
      }

      .title {
        width: 4.22rem;
        font-family: "HarmonyOS Sans SC";
        font-style: normal;
        font-weight: 400;
        font-size: 0.28rem;
        line-height: 0.28rem;
        letter-spacing: 0.01em;
        color: #ffffff;
      }
      .text {
        width: 4.22rem;
        font-family: "HarmonyOS Sans SC";
        font-style: normal;
        font-weight: 400;
        font-size: 0.18rem;
        line-height: 0.24rem;
        color: #646368;
        margin-top: 0.3rem;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .data-manage-section {
    height: 10.8rem;

    .block {
      display: block;
      height: 6.9rem;
      margin-top: 1.62rem;
      position: relative;

      .content {
        position: absolute;
        left: 1.46rem;

        .title {
          font-size: 0.24rem;
          line-height: 0.28rem;
        }

        .text {
          width: 5rem;
          font-size: 0.2rem;
          line-height: 0.24rem;

          &.cn {
            line-height: 0.28rem;
          }
        }
      }

      .pic1 {
        position: absolute;
        left: 0.38rem;
        bottom: 0.3rem;
        width: 4.68rem;
        height: 4.68rem;
        background-image: url("@{dataManageImg1}");
        background-repeat: no-repeat;
        background-size: contain;
      }

      .pic2 {
        position: absolute;
        left: 0.3rem;
        bottom: 0.8rem;
        width: 5.3rem;
        height: 3.31rem;
        background-image: url("@{dataManageImg2}");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
}
</style>
