<template>
  <section class="roboverse-seventh-section">
    <div
      class="headline"
      data-aos="fade-right"
      data-aos-easing="ease-out-cubic"
      data-aos-offset="0"
      data-aos-delay="100"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <h1 class="bg-text">CONDITIONAL DIVERSITY</h1>
      <h2 class="title" v-if="locale === 'cn'">条件多样性</h2>
    </div>

    <div class="ipad">
      <video
        :src="playSrc"
        type="video/mp4"
        class="video"
        autoplay
        loop
        muted
      ></video>
    </div>

    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide slide1">
          <div class="card">
            <div class="weather">Sunny</div>
            <div class="pic">
              <div class="icon sunny"></div>
              <div class="cloud"></div>
            </div>
            <div class="time">11:30·11/30</div>
            <div class="temperature">15℃</div>
          </div>
        </div>
        <div class="swiper-slide slide2">
          <div class="card">
            <div class="weather">Night</div>
            <div class="pic">
              <div class="icon moon"></div>
              <div class="cloud moon"></div>
            </div>
            <div class="time">19:30·11/30</div>
            <div class="temperature">5℃</div>
          </div>
        </div>
        <div class="swiper-slide slide3">
          <div class="card">
            <div class="weather">Rain</div>
            <div class="pic">
              <div class="icon rain"></div>
              <div class="cloud rain"></div>
              <div class="raindrop">
                <img
                  src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/verse/rbvs_raindrop.png"
                />
                <img
                  src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/verse/rbvs_raindrop.png"
                />
                <img
                  src="http://ad4-static.lotuscars.com.cn/webapp/pc/image/verse/rbvs_raindrop.png"
                />
              </div>
            </div>
            <div class="time">11:30·11/30</div>
            <div class="temperature">5℃</div>
          </div>
        </div>
        <div class="swiper-slide slide4">
          <div class="card">
            <div class="weather">Sunny</div>
            <div class="pic">
              <div class="icon sunny"></div>
              <div class="cloud"></div>
            </div>
            <div class="time">11:30·11/30</div>
            <div class="temperature">15℃</div>
          </div>
        </div>
        <div class="swiper-slide slide5">
          <div class="card">
            <div class="weather">Cloudy</div>
            <div class="pic">
              <div class="icon flash"></div>
              <div class="cloud"></div>
              <!-- <div class="cloud1"></div>
              <div class="cloud2"></div> -->
            </div>
            <div class="time">11:30·11/30</div>
            <div class="temperature">15℃</div>
          </div>
        </div>
        <div class="swiper-slide slide6">
          <div class="card">
            <div class="weather">Night</div>
            <div class="pic">
              <div class="icon moon"></div>
              <div class="cloud moon"></div>
            </div>
            <div class="time">19:30·11/30</div>
            <div class="temperature">5℃</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.css";
import { mapState } from "vuex";

export default {
  name: "SeventhSection",

  data() {
    const cdnUrl = "http://ad4-static.lotuscars.com.cn/webapp";
    const diversity1Video = `${cdnUrl}/pc/video/verse/diversity1.mp4`;
    const diversity2Video = `${cdnUrl}/pc/video/verse/diversity2.mp4`;
    const diversity3Video = `${cdnUrl}/pc/video/verse/diversity3.mp4`;
    const diversity4Video = `${cdnUrl}/pc/video/verse/diversity4.mp4`;
    const diversity5Video = `${cdnUrl}/pc/video/verse/diversity5.mp4`;
    const diversity6Video = `${cdnUrl}/pc/video/verse/diversity6.mp4`;
    return {
      playSrc: diversity1Video,
      videoList: [
        diversity1Video,
        diversity2Video,
        diversity3Video,
        diversity4Video,
        diversity5Video,
        diversity6Video,
      ],
    };
  },

  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },

  mounted() {
    this.$nextTick(() => {
      const _this = this;
      new Swiper(".swiper-container", {
        autoplay: {
          delay: 10000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        on: {
          slideChange: function () {
            _this.playSrc = _this.videoList[this.activeIndex];
          },
        },
      });
    });
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/font.css";
@import "~@/assets/css/image.less";
.roboverse-seventh-section {
  position: relative;
  height: calc(100vw / 1280 * 720);

  .headline {
    position: absolute;
    top: 0.74rem;
    left: 1.5rem;
    &.en {
      top: 1rem;
    }
    h1,
    h2 {
      margin: 0;
      padding: 0;
    }
    .bg-text {
      font-family: "HarmonyOS Sans SC Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 0.32rem;
      line-height: 0.48rem;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.31);
    }

    .title {
      font-family: "HarmonyOS Sans SC Bold";
      font-style: normal;
      font-weight: 900;
      font-size: 0.28rem;
      line-height: 0.33rem;
      letter-spacing: 0.065em;
      color: #00ff00;
    }
  }

  .ipad {
    position: absolute;
    top: 1.86rem;
    left: 5.17rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5.83rem;
    height: 4.52rem;
    background-image: url("@{rbvsIpad}");
    background-size: contain;
    margin-left: 0.48rem;
    z-index: 9999;

    .video-swiper {
      width: 4.97rem;
      height: 3.73rem;
      overflow: hidden;
    }

    .swiper-slide,
    .video {
      width: 4.97rem;
      height: 3.73rem;
    }
  }

  .swiper-container {
    width: 100%;
    height: 100%;
    .swiper-wrapper {
      width: 100%;
      height: 100%;

      .swiper-slide {
        position: relative;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;

        &.slide1 {
          background-image: url("@{diversitySlide1Bg}");
        }
        &.slide2 {
          background-image: url("@{diversitySlide2Bg}");
        }
        &.slide3 {
          background-image: url("@{diversitySlide3Bg}");
        }
        &.slide4 {
          background-image: url("@{diversitySlide4Bg}");
        }
        &.slide5 {
          background-image: url("@{diversitySlide5Bg}");
        }
        &.slide6 {
          background-image: url("@{diversitySlide6Bg}");
        }
      }
    }
    .card {
      position: absolute;
      display: flex;
      top: 2.15rem;
      left: 1.98rem;
      flex-direction: column;
      align-items: center;
      width: 2.71rem;
      height: 3.95rem;
      background: linear-gradient(
        197.04deg,
        rgba(233, 247, 220, 0.0928) 2.67%,
        rgba(255, 255, 255, 0.1408) 36.31%,
        rgba(255, 255, 255, 0.0928) 95.04%
      );
      backdrop-filter: blur(10px);
      /* Note: backdrop-filter has minimal browser support */
      border-radius: 0.16rem;
      cursor: pointer;

      .pic {
        position: relative;
        .cloud {
          width: 1.73rem;
          height: 0.93rem;
          background-image: url("@{rbvsCloudIcon}");
          background-size: contain;
          background-repeat: no-repeat;

          &.moon {
            background-image: url("@{rbvsCloudMoonIcon}");
          }

          &.rain {
            background-image: url("@{rbvsCloudIcon}");
          }
        }

        // .cloud1 {
        //   position: relative;
        //   left: -0.1rem;
        //   width: 1.41rem;
        //   height: 0.76rem;
        //   background-image: url("@{rbvsCloudIcon}");
        //   background-size: contain;
        //   background-repeat: no-repeat;
        // }

        // .cloud2 {
        //   position: relative;
        //   top: -0.93rem;
        //   left: 0.1rem;
        //   width: 1.73rem;
        //   height: 0.93rem;
        //   background-image: url("@{rbvsCloudIcon}");
        //   background-size: contain;
        //   background-repeat: no-repeat;
        // }

        .icon {
          position: absolute;
          z-index: -1;
          top: 0rem;
          right: 0.1rem;
          width: 0.58rem;
          height: 0.58rem;
          border-radius: 50%;

          &.sunny {
            background: linear-gradient(180deg, #fff200 0%, #ff9900 100%);
            border: 1px solid #ffe817;
            box-shadow: 1px -1px 8px 2px rgba(255, 242, 0, 0.35);
          }

          &.moon {
            top: -0.2rem;
            background-image: url("@{moonIcon}");
            background-repeat: no-repeat;
            background-size: contain;
          }

          &.flash {
            width: 0.35rem;
            height: 0.65rem;
            top: -0.1rem;
            right: 0.26rem;
            background-image: url("@{flashIcon}");
            background-repeat: no-repeat;
            background-size: contain;
          }

          &.rain {
            background: linear-gradient(180deg, #fff200 0%, #ff9900 100%);
            border: 1px solid #ffe817;
            box-shadow: 1px -1px 8px 2px rgba(255, 242, 0, 0.35);
          }
        }

        .raindrop {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 0.7rem;
          margin: 0 auto;
          img {
            width: 0.14rem;
            height: 0.19rem;
          }
        }
      }

      .weather {
        font-family: "HarmonyOS Sans SC";
        font-style: normal;
        font-weight: 900;
        font-size: 0.28rem;
        line-height: 0.33rem;
        letter-spacing: 0.065em;
        color: #ffffff;
        margin: 0.73rem 0 0.29rem 0;
      }

      .time {
        font-family: "HarmonyOS Sans SC";
        font-style: normal;
        font-weight: 400;
        font-size: 0.14rem;
        line-height: 0.16rem;
        letter-spacing: 0.065em;
        color: #ffffff;
        margin: 0.23rem 0 0.3rem 0;
      }

      .temperature {
        font-family: "GT Noto Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 0.35rem;
        line-height: 0.48rem;
        color: #ffffff;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .roboverse-seventh-section {
    height: 8.34rem;

    .headline {
      top: 1.3rem;
    }

    .swiper-container {
      .swiper-wrapper {
        .swiper-slide {
          &.slide1 {
            background-image: url("@{mbDiversitySlide1Bg}");
          }
          &.slide2 {
            background-image: url("@{mbDiversitySlide2Bg}");
          }
          &.slide3 {
            background-image: url("@{mbDiversitySlide3Bg}");
          }
          &.slide4 {
            background-image: url("@{mbDiversitySlide4Bg}");
          }
          &.slide5 {
            background-image: url("@{mbDiversitySlide5Bg}");
          }
          &.slide6 {
            background-image: url("@{mbDiversitySlide6Bg}");
          }
        }
      }
      .card {
        top: 3.07rem;
        left: 0.6rem;
        width: 2.11rem;
        height: 3.04rem;

        .weather {
          margin: 0.4rem 0 0.26rem 0;
        }

        .pic {
          .cloud {
            width: 1.35rem;
            height: 0.72rem;
          }
          .icon {
            width: 0.45rem;
            height: 0.45rem;
            &.flash {
              top: -0.15rem;
              right: 0.22rem;
            }
          }
        }

        .time {
          margin: 0.19rem 0 0.23rem 0;
        }
      }
    }

    .ipad {
      top: 2.92rem;
      left: 2.7rem;
      width: 4.37rem;
      height: 3.39rem;
      margin-left: 0.15rem;

      .video {
        width: 3.73rem;
        height: 2.79rem;
      }
    }
  }
}
</style>
