<template>
  <section class="roboverse-third-section">
    <div
      class="headline"
      data-aos="fade-right"
      data-aos-easing="ease-out-cubic"
      data-aos-offset="0"
      data-aos-delay="100"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <div class="title-line1">
        <span>RELE</span>
        <div class="circle"></div>
      </div>
      <div class="title-line2">
        <span>ASE</span>
        <span class="subtitle" v-show="locale === 'cn'">释放计划</span>
      </div>
    </div>
    <div :class="'plan-img ' + locale"></div>
  </section>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "RoboverseThirdSection",
  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/font.css";
@import "~@/assets/css/image.less";
.roboverse-third-section {
  position: relative;
  // height: calc(100vw / 1280 * 720);
  height: 7.2rem;
  background-color: #000;

  .headline {
    position: absolute;
    top: 2.4rem;
    left: 1.5rem;

    div[class^="title-line"] {
      font-family: "HarmonyOS Sans SC Bold";
      font-style: normal;
      font-weight: 900;
      font-size: 1.1rem;
      line-height: 1.05rem;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      background: linear-gradient(269.44deg, #00ff00 -4%, #00ffc2 102.4%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    .title-line1 {
      position: relative;

      .circle {
        position: absolute;
        left: -0.5rem;
        top: -0.3rem;
        width: 1.4rem;
        height: 1.4rem;
        background: rgba(255, 255, 255, 0.16);
        backdrop-filter: blur(5px);
        /* Note: backdrop-filter has minimal browser support */
        border-radius: 50%;
        animation: blur-anim 2.5s ease-in-out 500ms infinite alternate;
      }
    }
    .title-line2 {
      .subtitle {
        margin-left: 0.1rem;
        display: inline-block;
        width: 0.64rem;
        font-family: "HarmonyOS Sans SC";
        font-style: normal;
        font-weight: 700;
        font-size: 0.28rem;
        line-height: 0.3rem;
        letter-spacing: 0.01em;
        color: #00ff00;
      }
    }
  }
  .plan-img {
    position: absolute;
    top: 2.1rem;
    left: 5.45rem;
    width: 6.98rem;
    height: 2.83rem;
    background-image: url("@{thirdPlanImg}");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    &.en {
      background-image: url("@{thirdPlanEnImg}");
    }
  }

  @keyframes blur-anim {
    from {
      left: -0.5rem;
    }

    to {
      left: 2rem;
    }
  }
}
</style>
