<template>
  <div class="news-card" @click="open(url)">
    <section class="left">
      <div class="title">{{ locale === "en" ? title[1] : title[0] }}</div>
      <div :class="['content', { en: locale === 'en' }]">
        {{ locale === "en" ? content[1] : content[0] }}
      </div>
      <div class="world linear-text">world</div>
    </section>
    <section class="right">
      <img :src="img" alt="" />
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "NewsCard",
  props: {
    title: Array,
    content: Array,
    img: String,
    url: String,
  },
  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },
  methods: {
    open(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="less" scoped>
.news-card {
  height: 3.4rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  .linear-text {
    background: linear-gradient(269.44deg, #00ff00 -4%, #00ffc2 102.4%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: 700;
  }
  .left {
    flex-basis: 3.88rem;
    position: relative;
    .title {
      font-family: "HarmonyOS Sans SC";
      font-size: 0.18rem;
      line-height: 0.22rem;
      letter-spacing: 0.01rem;
      margin: 0.76rem 0 0.33rem;
      font-weight: 700;
    }
    .content {
      font-size: 0.14rem;
      line-height: 0.22rem;
      letter-spacing: 0.03rem;
      color: #646368;
      &.en {
        letter-spacing: 0.01rem;
        font-size: 0.13rem;
      }
    }
    .world {
      position: absolute;
      font-family: "HarmonyOS Sans SC Black";
      font-size: 0.15rem;
      line-height: 0.22rem;
      letter-spacing: 0.01rem;
      text-transform: uppercase;
      top: 2.64rem;
      width: fit-content;
    }
  }
  .right {
    flex-basis: 2.55rem;
    height: 2.1rem;
    margin-top: 0.58rem;
    background: linear-gradient(#646464 0%, #000000 100%);
    img {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 750px) {
  .news-card {
    .left {
      flex-basis: 3.58rem;
      .title {
        margin-top: 0.5rem;
        line-height: 1.2;
        font-size: 0.2rem;
      }
      .content {
        display: -webkit-box;
        font-size: 0.2rem;
        line-height: 1.3;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
      }
      .world {
        top: 2.8rem;
        font-size: 0.2rem;
      }
    }
    .right {
      flex-basis: 2.22rem;
      margin-top: 0.5rem;
    }
  }
}
</style>
