<template>
  <div class="data-simulation-section">
    <div class="headline">
      <!-- <h1 class="title">{{ $t("galaxy.section8.title") }}</h1>
      <h2 class="sub-title">{{ $t("galaxy.section8.subtitle") }}</h2> -->
      <h1
        class="title"
        data-aos="fade-up"
        data-aos-offset="0"
        data-aos-delay="100"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        {{ $t("galaxy.section8.title") }}
      </h1>
      <h2
        class="sub-title"
        data-aos="fade-up"
        data-aos-offset="0"
        data-aos-delay="200"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        {{ $t("galaxy.section8.subtitle") }}
      </h2>
    </div>

    <div
      class="data-simulation-swiper"
      data-aos="fade-left"
      data-aos-offset="0"
      data-aos-delay="300"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <div class="swiper-wrapper">
        <div class="block swiper-slide">
          <div class="pic1"></div>
          <div class="content">
            <div class="mark"></div>
            <div>
              <div class="title">{{ $t("galaxy.section8.title1") }}</div>
              <div :class="'text ' + locale">
                {{ $t("galaxy.section8.content1") }}
              </div>
            </div>
          </div>
        </div>
        <div class="block swiper-slide">
          <div class="pic2"></div>
          <div class="content">
            <div class="mark"></div>
            <div>
              <div class="title" style="width: 4.8rem">
                {{ $t("galaxy.section8.title2") }}
              </div>
              <div :class="'text ' + locale">
                {{ $t("galaxy.section8.content2") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.css";
import { mapState } from "vuex";
export default {
  name: "DataSimulationSection",
  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },

  mounted() {
    new Swiper(".data-simulation-swiper", {
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
      },
    });
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/galaxy.less";
@import "~@/assets/css/common.less";
@import "~@/assets/css/image.less";
.data-simulation-section {
  height: 7.2rem;
  background-color: #000;
  background-image: url("@{dataSimulationBgImg}");
  background-repeat: no-repeat;
  background-size: 12.39rem 4.71rem;
  background-position: -0.17rem 2.47rem;

  .block {
    display: flex;
    align-items: center;
    padding-left: 1.74rem;
    width: 100%;
    box-sizing: border-box;
    .pic1 {
      width: 4.68rem;
      height: 4.68rem;
      background-image: url("@{dataSimulationImg1}");
      background-repeat: no-repeat;
      background-size: contain;
    }

    .pic2 {
      width: 4.96rem;
      height: 3.96rem;
      margin: 0.6rem 0.38rem 0 0;
      background-image: url("@{dataSimulationImg2}");
      background-repeat: no-repeat;
      background-size: contain;
    }

    .content {
      display: flex;
      font-size: 0;
      .mark {
        width: 0.08rem;
        height: 0.26rem;
        background: linear-gradient(
          269.44deg,
          @primary-color -4%,
          #00ffc2 102.4%
        );
        border-radius: 0.2rem;
        margin-right: 0.18rem;
      }

      .title {
        width: 4.22rem;
        font-family: "HarmonyOS Sans SC";
        font-style: normal;
        font-weight: 400;
        font-size: 0.28rem;
        line-height: 0.28rem;
        letter-spacing: 0.01em;
        color: #ffffff;
      }
      .text {
        width: 4.22rem;
        font-family: "HarmonyOS Sans SC";
        font-style: normal;
        font-weight: 400;
        font-size: 0.18rem;
        line-height: 0.24rem;
        color: #646368;
        margin-top: 0.3rem;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .data-simulation-section {
    height: 10.8rem;
    background-image: url("@{mbDataSimulationBgImg}");
    background-repeat: no-repeat;
    background-size: 100vw 100vw * 568 / 750;
    background-position: 0 4rem;
    .headline .sub-title {
      width: 5.66rem;
      margin: 0.18rem auto auto auto;
    }
    .block {
      display: block;
      margin-top: 0.85rem;
      padding: 0;
      height: 7.5rem;
      position: relative;

      .content {
        margin-left: 0.95rem;
        .title {
          width: 4.4rem;
        }
        .text {
          width: 5.18rem;
          &.cn {
            line-height: 0.28rem;
          }
        }
      }

      .pic1 {
        position: absolute;
        right: 0rem;
        bottom: 0.15rem;
      }

      .pic2 {
        position: absolute;
        right: -0.3rem;
        bottom: 0.45rem;
        margin: 0;
      }
    }
  }
}
</style>
