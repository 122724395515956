export default {
  subscribe: "订阅我们",
  solution: "技术方案",
  about1: "关于LOTUS ROBOTICS",
  about2: "",
  team: "团队介绍",
  joinUs: "加入我们",
  contactUs: "联系我们",
  newCreature: "ROBO Cube",
  copyRight: "版权所有（All rights reserved）",
  filling: "浙ICP备2022012519号",
  contact: "联系我们",
};
