<template>
  <div class="robo-soul-page">
    <overview-section />
    <schema-section />
    <algorithmic-section />
    <cmi-mpi-section />
    <development-process-section />
    <!-- <cooperation-section /> -->
  </div>
</template>

<script>
import OverviewSection from "./components/OverviewSection.vue";
import SchemaSection from "./components/SchemaModule.vue";
import AlgorithmicSection from "./components/AlgorithmicSection.vue";
import CmiMpiSection from "./components/CmiMpiSection.vue";
import DevelopmentProcessSection from "./components/DevelopmentProcessSection.vue";
// import CooperationSection from "./components/CooperationSection.vue";
export default {
  name: "RoboSoul",
  components: {
    OverviewSection,
    SchemaSection,
    AlgorithmicSection,
    CmiMpiSection,
    DevelopmentProcessSection,
    // CooperationSection,
  },
};
</script>

<style lang="less" scoped>
.robo-soul-page {
  color: #fff;
}
</style>
