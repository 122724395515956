<template>
  <div class="join-content">
    <div
      class="join-title"
      data-aos="fade-in"
      data-aos-offset="0"
      data-aos-delay="100"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      {{ $t("home.joinTitle") }}
    </div>
    <div
      data-aos="fade-in"
      data-aos-offset="0"
      data-aos-delay="100"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <div :class="{ 'join-text': true, en: locale === 'en' }">
        {{ $t("home.joinContent") }}
      </div>
    </div>

    <div
      :style="{ width: locale === 'cn' ? '' : '2.22rem' }"
      class="join-add"
      @click="toMok()"
    >
      {{ $t("home.joinButton") }} >
    </div>
  </div>
</template>

<script>
import "animate.css";
import { mapState } from "vuex";
export default {
  name: "JoinUs",
  computed: {
    ...mapState("app", {
      locale: (state) => state.locale,
    }),
  },
  methods: {
    toMok() {
      window.open(
        "https://app.mokahr.com/social-recruitment/lotus/41375#/jobs?department%5B0%5D=964243",
        "_self"
      );
    },
  },
};
</script>

<style lang="less" scoped>
.join-content {
  display: flex;
  height: 720px;
  justify-content: center;
  align-items: center;

  flex-direction: column;
}

.join-title {
  font-size: 32px;
  line-height: 48px;
  background: linear-gradient(269.44deg, #00ff00 -4%, #00ffc2 102.4%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "HarmonyOS Sans SC Thin";
}

.join-text {
  margin-top: 10px;
  font-size: 45px;
  line-height: 48px;
  color: #fff;
  font-family: "HarmonyOS Sans SC Black";
  text-align: center;
}

.join-add {
  margin-top: 40px;
  padding: 0 0.37rem;
  width: 1.77rem;
  height: 39px;
  line-height: 39px;
  text-align: center;
  border: 1px #0f0 solid;
  border-radius: 30px;
  font-size: 20px;
  color: #0f0;
  font-family: "HarmonyOS Sans SC Light";
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    animation: heartBeat;
    animation-duration: 2s;
  }
}

@media only screen and (max-width: 750px) {
  .join-content {
    display: flex;
    height: 8.04rem;
    justify-content: center;
    align-items: center;

    flex-direction: column;
  }

  .join-title {
    font-size: 0.32rem;
    line-height: 0.32rem;
    color: #0f0;
    font-family: "HarmonyOS Sans SC Thin";

    background: linear-gradient(269.44deg, #00ff00 -4%, #00ffc2 102.4%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  .join-text {
    margin-top: 10px;
    font-size: 0.45rem;
    line-height: 0.45rem;
    color: #fff;
    font-family: "HarmonyOS Sans SC Black";
    &.en {
      font-size: 0.32rem;
      width: 5rem;
    }
  }

  .join-add {
    margin-top: 0.38rem;
    width: 1.91rem;
    height: 0.46rem;
    line-height: 0.46rem;
    text-align: center;
    border: 1px #0f0 solid;
    border-radius: 30px;
    font-size: 0.22rem;
    color: #0f0;
    font-family: "HarmonyOS Sans SC Light";
    cursor: pointer;

    &:hover {
      animation: heartBeat;
      animation-duration: 2s;
    }
  }
}
</style>
